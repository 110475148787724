import React from "react";

function SearchIcon() {
  return (
    <svg
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
    >
      <path
        stroke="#D6D7E3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16.5 28.5c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12zM31.5 31.5l-6.525-6.525"
      ></path>
    </svg>
  );
}

export default SearchIcon;
