import {makeStyles, Typography} from "@material-ui/core";
import ActionButton from "../button/actionButton";

const useStyles = makeStyles(() => ({
    root: {
        border: 'none',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: '18px',
        marginTop: '4px'
    },
    currentPage: {
        paddingTop: '2px',
        maxWidth: '24px',
        minWidth: '24px',
        textAlign: 'center',
        opacity: 0.5
    },
    pageButton: {
        maxWidth: '24px',
        minWidth: '24px',
    }
}));

// when next or page num is clicked, setCurrentPage to it and call updatePages()

const Pagination = ({currentPage, setCurrentPage, perPage, updatePages, total, setVisitedPages, visitedPages}) => {
    const classes = useStyles();
    const loadPrevPage = (offset) => {
        setCurrentPage(currentPage - offset);
    }
    const loadNextPage = (offset) => {
        const currentTotal = (currentPage + 2 + offset) * perPage
        const pageHasBeenVisited = visitedPages.find(el => el === currentPage) !== undefined
        const shouldFetch = currentTotal > total && !pageHasBeenVisited;
        shouldFetch && updatePages && updatePages(shouldFetch);
        setCurrentPage(currentPage + offset);
        if (!pageHasBeenVisited) {
            setVisitedPages([...visitedPages, currentPage])
        }
    }
    return (
        <div className={classes.root}>
            <ActionButton variant="inline" onClick={() => loadPrevPage(1)} disabled={(() => (currentPage - 1 < 0))()}>Prev</ActionButton>
            {/* { currentPage - 2 >= 0 &&
                 <ActionButton className={classes.pageButton} variant="inline" onClick={() => loadPrevPage(2)}>{currentPage - 1}</ActionButton>
            }
            { currentPage - 1 >= 0 &&
                 <ActionButton className={classes.pageButton} variant="inline" onClick={() => loadPrevPage(1)}>{currentPage}</ActionButton>
            } */}
            <Typography variant="body2" className={classes.currentPage}>{currentPage + 1}</Typography>
            { (currentPage + 1) * perPage <= total &&
                 <ActionButton className={classes.pageButton} variant="inline" onClick={() => loadNextPage(1)}>{currentPage + 2}</ActionButton>
            }
            {/*{ (currentPage + 2) * perPage <= total &&*/}
            {/*     <ActionButton className={classes.pageButton} variant="inline" onClick={() => loadNextPage(2)}>{currentPage + 3}</ActionButton>*/}
            {/*}*/}
            <ActionButton variant="inline" onClick={() => loadNextPage(1)} disabled={(() => ((currentPage + 1) * perPage > total))()}>Next</ActionButton>
        </div>
    )
}

export default Pagination;