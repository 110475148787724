import React, { useState, useEffect } from "react";
import { Admin, Resource } from "react-admin";
import {
  withAuthenticationRequired,
  withAuth0,
  useAuth0,
} from "@auth0/auth0-react";

import LoadingView from "./views/transition/loadingView";
import CustomerShow from "./views/show/customerShow";
import CustomerList from "./components/list/customerList";
import dataProvider from "./utils/data/dataProvider";
import { AuthProvider } from "./utils/data/authProvider";
import DashboardLayout from "./components/layout/dashboardLayout";
import { AuthorizationRole } from "./utils/role";
import { i18nProvider } from "./i18n/i18nProvider";
import ModalConsumer from "./components/consumer/modalConsumer";
import HomeIcon from "./components/icons/homeIcon";
import GenericError from "./components/error/genericError";
import GlobalActivityList from "./components/list/globalActivityList";
import CustomersIcon from "./components/icons/customersIcon";
import TransactionsIcon from "./components/icons/transactionsIcon";

const App = () => {
  const { getAccessTokenSilently, isAuthenticated, getIdTokenClaims } =
    useAuth0();


  const [hasAccessToken, setHasAccessToken ] = useState(false);

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        const authOptions = {
          audience: process.env.REACT_APP_API_HOST,
        };

        const idToken = await getIdTokenClaims(authOptions);
        sessionStorage.setItem("idToken", JSON.stringify(idToken));

        const accessToken = await getAccessTokenSilently(authOptions);
        sessionStorage.setItem("accessToken", accessToken);

        setHasAccessToken(true);  
      }
    })();
  }, [isAuthenticated, getAccessTokenSilently, getIdTokenClaims]);
  
  if (!hasAccessToken) {
    return <LoadingView/>
  }

  return (
    <AuthorizationRole>
      <Admin
          dataProvider={dataProvider}
          authProvider={AuthProvider()}
          i18nProvider={i18nProvider}
          layout={DashboardLayout}
          catchAll={GenericError}
          >
              <Resource
                  options={{ label: "Customers" }}
                  icon={CustomersIcon}
                  name={"customers"}
                  list={CustomerList}
                  show={CustomerShow}
              />
              <Resource
                  options={{ label: "Transactions" }}
                  icon={TransactionsIcon}
                  name={"globalActivities"}
                  list={GlobalActivityList}
              />
              <ModalConsumer>
                  {({ component: Component, props, hideModal }) =>
                      Component ? <Component {...props} onClose={hideModal} /> : null}
              </ModalConsumer>
      </Admin>
    </AuthorizationRole>
  );
}

export default withAuthenticationRequired(withAuth0(App));
