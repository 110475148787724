import React from "react";

function CloseActionIcon() {
  return (
      <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="18" cy="18" r="18" fill="#8482FF" fillOpacity="0.2"></circle>
        <path
            stroke="#A3A2FF"
            strokeLinecap="round"
            strokeWidth="1.5"
            d="M24.9266 14.7195C25.3396 14.7535 25.6476 15.1145 25.6146 15.5275C25.6086 15.5955 25.0666 22.3065 24.7546 25.1215C24.5606 26.8685 23.4066 27.9315 21.6646 27.9635C20.3316 27.9865 19.5036 27.9995 18.2466 27.9995C16.8916 27.9995 15.5706 27.9845 14.2636 27.9575C12.5916 27.9245 11.4346 26.8405 11.2456 25.1285C10.9306 22.2885 10.3916 15.5945 10.3866 15.5275C10.3526 15.1145 10.6606 14.7525 11.0736 14.7195C11.4806 14.7085 11.8486 14.9945 11.8816 15.4065C11.8848 15.4499 12.1051 18.1835 12.3453 20.8882L12.3935 21.4279C12.5144 22.7723 12.637 24.0641 12.7366 24.9635C12.8436 25.9365 13.3686 26.4385 14.2946 26.4575C16.7946 26.5105 18.8876 26.5135 21.6376 26.4635C22.6216 26.4445 23.1536 25.9525 23.2636 24.9565C23.5736 22.1625 24.1136 15.4745 24.1196 15.4065C24.1526 14.9945 24.5176 14.7065 24.9266 14.7195ZM19.8874 7.99976C20.8054 7.99976 21.6124 8.61876 21.8494 9.50576L22.1034 10.7668C22.1855 11.1801 22.5482 11.482 22.9683 11.4886L26.25 11.4888C26.664 11.4888 27 11.8248 27 12.2388C27 12.6528 26.664 12.9888 26.25 12.9888L22.9976 12.9886C22.9925 12.9887 22.9875 12.9888 22.9824 12.9888L22.958 12.9878L13.0416 12.9886C13.0336 12.9887 13.0255 12.9888 13.0174 12.9888L13.002 12.9878L9.75 12.9888C9.336 12.9888 9 12.6528 9 12.2388C9 11.8248 9.336 11.4888 9.75 11.4888L13.031 11.4878L13.132 11.4814C13.5083 11.4325 13.821 11.1468 13.8974 10.7668L14.1404 9.55076C14.3874 8.61876 15.1944 7.99976 16.1124 7.99976H19.8874ZM19.8874 9.49976H16.1124C15.8724 9.49976 15.6614 9.66076 15.6004 9.89176L15.3674 11.0618C15.3378 11.21 15.2947 11.3528 15.2395 11.489H20.7606C20.7054 11.3528 20.6621 11.21 20.6324 11.0618L20.3894 9.84576C20.3384 9.66076 20.1274 9.49976 19.8874 9.49976Z"
        ></path>
      </svg>
  );
}

export default CloseActionIcon;