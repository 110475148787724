import {ACTIVITY_TYPES, TRANSACTION_CATEGORIES} from "./types";

export const filterTransactions = (transactions) => {
    return transactions?.filter((transaction) => {
        switch (transaction.category) {
            case TRANSACTION_CATEGORIES.WITHDRAWAL:
            case TRANSACTION_CATEGORIES.DEPOSIT:
            case TRANSACTION_CATEGORIES.REFUND:
            case TRANSACTION_CATEGORIES.CANCEL:
            case TRANSACTION_CATEGORIES.INTEREST:
                return true;
            case TRANSACTION_CATEGORIES.AUTH:
            case TRANSACTION_CATEGORIES.CAPTURE:
            default:
                return false;
        }
    })
}

export const filterActivities = (activities) => {
    return activities?.filter((activity) => {
        switch (activity.type) {
            case ACTIVITY_TYPES.WITHDRAWAL:
            case ACTIVITY_TYPES.DEPOSIT:
            case ACTIVITY_TYPES.REFUND:
            case ACTIVITY_TYPES.PAYMENT:
            case ACTIVITY_TYPES.SENT:
            case ACTIVITY_TYPES.RECEIVED:
                return true;
            default:
                return false;
        }
    })
}

export const filterByActivityType = (activities, selectedTypes, allTypes) => {
    const typeFilter = selectedTypes.length === 0 ? allTypes : selectedTypes
    return activities?.filter((activity) => {
        const matchedType = typeFilter.find(type => {
            return type === activity.type
        })
        return matchedType !== undefined
    })
}

export const sortActivitiesByDate = (activities) => {
    return [...activities]?.sort((a, b) => b.timestamp - a.timestamp)
}