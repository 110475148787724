import { makeStyles, TextField as MuiTextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '54px',
        padding: 0,
        '&:not(:first-child)': {
            margin: '32px 0px 0px',
        },
        '& label.Mui-focused': {
            color: theme.text.subtitle,
        },
        '& ::-webkit-calendar-picker-indicator': {
            filter: 'invert(1)'
        }        
    }
}));

const TextField = (props) => {
    const classes = useStyles();

    return (
        <MuiTextField classes={classes} {...props}/>
    );
}

export default TextField;