import { gql } from "@apollo/client";

export const getKycQuery = gql`
query GetKycApplication($customerId: String!) {
  getKycApplication: getKycApplication_Admin(customerId: $customerId) {
      id
      type
      status
      details {
        company {
          companyId
          country
          companyExternalId
          status
          stakeholders {
            id
            name
            type
            applicantId
          }
        }
        individual {
          firstName
          lastName
          applicantId
        }
      }
    }
  }
`