import React from "react";

function BackIcon({className, onClick}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="21"
      fill="none"
      viewBox="0 0 28 21"
      className={className}
      onClick={onClick}
    >
      <path
        stroke="#FAFAFA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M10 2l-8 8.5 8 8.5M3 10h23"
      ></path>
    </svg>
  );
}

export default BackIcon;
