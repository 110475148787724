import { client } from "./graphql/client";
import { formatApolloError } from "./graphql/error";
import { createCustomerNoteMutation, deleteCustomerNoteMutation, updateCustomerNoteMutation } from "./graphql/notes/mutations";

export const notesDataProvider = {
    update: async(resource, params) => {
        try {
            const result = await client.mutate({
                mutation: updateCustomerNoteMutation,
                variables: { 
                    input: params
                }
            });
            await client.clearStore(); // forces cache reset for new queries

            const response = result.data

            return {
                data: {
                    id: params.customerId,
                    result: response
                }
            }
        } catch (e) {
            return Promise.resolve({
                data: {
                    id: params.customerId,
                    error: formatApolloError(e)
                }
            });
        }
    },
    create: async(resource, params) => {
        try {
            const result = await client.mutate({
                mutation: createCustomerNoteMutation,
                variables: {
                    input: params
                }
            });
            await client.clearStore(); // forces cache reset for new queries
            const response = result.data

            return {
                data: {
                    id: params.customerId,
                    result: response
                }
            }
        } catch (e) {
            return Promise.resolve({
                data: {
                    id: -1,
                    error: formatApolloError(e)
                }
            });
        }
    },
    delete: async (resource, params) => {
        try {
            const result = await client.mutate({
                mutation: deleteCustomerNoteMutation,
                variables: { 
                    input: {
                        customerId: params.customerId,
                        id: params.id
                    }
                }
            });
            await client.clearStore();

            const response = result.data

            const data = {
                id: params.id,
                result: response
            }
    
            return {
                data: data
            }
        } catch (e) {
            return Promise.resolve({
                data: {
                    id: -1,
                    error: formatApolloError(e)
                }
            });
        }
    }
}