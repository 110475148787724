import { client } from "./graphql/client"
import { gql } from "@apollo/client";
import { formatApolloError } from "./graphql/error";

export const getAddressAutoComplete = gql`
query AutoCompleteAddress ($address: String!) {
    autoCompleteAddress: autoCompleteAddress_Admin(address: $address) {
        id
        address
  }
}
`

export const addressDataProvider = {

    getList: async (resource, params) => {
        try {
            const { address } = params
            const result = await client.query({
                query: getAddressAutoComplete,
                variables: {
                    address
                }
            })
            const results = result.data.autoCompleteAddress

            return {
                data: results,
                total: results.length
            }
        } catch (e) {
            return Promise.reject({
                error: formatApolloError(e),
                data: [],
                total: 0
            });
        }
    }
}