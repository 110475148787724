/* eslint-disable no-useless-computed-key */
import { createTheme } from '@material-ui/core/styles';

export const dashboardTheme = () => {
  const currentTheme = createTheme(baseTheme) 
  return createTheme(currentTheme, {
    overrides: {
      MuiDialog: {
        paper: {
          backgroundColor: currentTheme.background.dialog,
          borderRadius: '10px',
        }
      },
      MuiListItem: {
        divider: {
          borderBottom: '1px solid #33334B',
        },
      },
      MuiSkeleton: {
        text: {
          height: currentTheme.spacing(4),
          width: currentTheme.spacing(40)
        }
      },
      MuiCircularProgress: {
        colorPrimary: {
          color: currentTheme.text.primary
        },
        colorSecondary: {
          color: currentTheme.text.positive
        }
      },
      RaButton: {
        button: {
          color: currentTheme.text.primary
        }
      },
      RaSaveButton: {
        button: {
          color: currentTheme.text.primary,
        },
      },
      MuiButton: {
        text: {
          padding: '6px 2px'
        },
        outlinedPrimary: {
          border: `1px solid ${currentTheme.text.primary}`
        },
        root: {
          '&:hover': {
            backgroundColor: 'transparent',
            opacity: 0.75,
            transition: '0.3s'
          },
          '&:focus': {
            backgroundColor: 'transparent',
            opacity: 0.75,
            transition: '0.3s'
          }
        },
        contained: {
          backgroundColor: 'inherit',
          transition: 'inherit',
          '&:hover': {
            backgroundColor: '#524cedbf !important'
          },
          '&:focus': {
            backgroundColor: '#524cedbf !important'
          }
        }
      },
      RaToolbar: {
        toolbar: {
          backgroundColor: currentTheme.background.secondary,
        }
      },
      RaFilterForm: {
        form: {
          backgroundColor: currentTheme.background.secondary,
          borderRadius: '10px',
          marginBottom: currentTheme.spacing(3),
          width: '450px',
          minHeight: '32px',
          display: 'flex',
          alignItems: 'center',
          [currentTheme.breakpoints.down('sm')]: {
            minWidth: '75%',
            maxWidth: '75%',
          },
          [currentTheme.breakpoints.between('sm','md')]: {
            minWidth: '50%',
            maxWidth: '50%',
          },
        }
      },
      RaResettableTextField: {
        clearButton: {
          color: currentTheme.text.disabled,
        }
      },
      RaSearchInput: {
        input: {
          width:  '100%',
          marginTop: currentTheme.spacing(1),
          marginBottom: currentTheme.spacing(1),
          marginLeft: currentTheme.spacing(1),
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
        }
      },
      MuiIconButton: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: 'transparent',
            opacity: '0.6'
          }
        }
      },
      MuiSvgIcon: {
        colorDisabled: {
          color: currentTheme.text.disabled,
        }
      },
      MuiInputBase: {
        input: {
          '&:-webkit-autofill': {
            transitionDelay: '999999s',
          },
          padding: '8px 16px 12px 16px'
        },
        root: {
          color: currentTheme.text.primary,
          fontFamily: 'DM Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '16px',
          lineHeight: '24px',
          letterSpacing: '0.15px',
        }
      },
      MuiFilledInput: {
        adornedEnd: {
          flexDirection: 'row-reverse',
          '&.Mui-focused': {
            backgroundColor: 'transparent',
            '&:before': {
              borderBottom: 'none',
            },
          },
          '&:before': {
            borderBottom: 'none',
          },
          '&:hover:before': {
            borderBottom: 'none'
          },
          '&:hover': {
            backgroundColor: 'transparent',
          },
          backgroundColor: 'transparent',
          '&:before, &:after': {
            borderBottom: 'none',
          },
        }
      },
      RaFilterFormInput: {
        body: {
          width: '100%',
        }
      },
      RaPlaceholder: {
        root: {
          backgroundColor: 'transparent'
        }
      },
      RaDatagrid: {
        row: {
          backgroundColor: 'transparent',
          height: currentTheme.spacing(4),
        },
        clickableRow: {
          height: currentTheme.spacing(8),
        },
        tbody: {
          height: currentTheme.spacing(8)
        },
        headerCell: {
          padding: 0,
          backgroundColor: 'transparent',
          textTransform: 'none',
          fontFamily: 'DM Sans',
          fontSize: '13px',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: '17px',
          letterSpacing: '0px',
          textAlign: 'left',
          opacity: .5,
        },
        headerRow: {
          borderStyle: 'hidden'
        },
        table: {
          tableLayout: 'fixed'
        }
      },
      RaShow: {
        card: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
        noActions: {
          marginTop: 0,
          [currentTheme.breakpoints.down('lg')]: {
            marginTop: 0
          }
        }
      },
      RaCreate: {
        noActions: {
          [currentTheme.breakpoints.down('lg')]: {
            marginTop: '2rem',
          }
        }
      },
      MuiTableCell: {
        root: {
          borderBottom: currentTheme.table.borderColor
        },
        sizeSmall: {
          padding: 0,
          fontFamily: 'DM Sans',
          fontWeight: 700,
          color: currentTheme.text.primary
        },
        body: {
          borderBottom: '1px solid rgb(214 214 223 / 32%)',
        },
        head: {
          borderBottom: '1px solid rgb(214 214 223 / 32%)',
        }
      },
      MuiTableRow: {
        head: {
          height: currentTheme.spacing(6)
        },
        root: {
          '&.MuiTableRow-hover:hover': {
            backgroundColor: 'rgb(0 0 0 / 15%)',
            transition: '0.3s'
          }
        }
      },
      RaMenu: {
        main: {
          marginTop: 0,
          [currentTheme.breakpoints.down('sm')]: {
            marginBottom: 0,
            flexDirection: 'row',
          }
        },
        open: {
          width: 'inherit',
          [currentTheme.breakpoints.down('sm')]: {
            width: '24px',
            margin: 'auto',
            display: 'contents',
          }
        },
        closed: {
          [currentTheme.breakpoints.down('sm')]: {
            width: '100%'
          }
        }
      },
      RaMenuItemLink: {
        active: {
          opacity: 1,
          borderRadius: '8px',
        },
        root: {
          padding: '0px 24px 0px 24px',
          alignItems: 'center !important',
        },
        icon: {
          [currentTheme.breakpoints.up('xs')]: {
            minWidth: '20px',
            paddingRight: 0,
            paddingBottom: currentTheme.spacing(0.5),
            margin: 'auto',
          },
          [currentTheme.breakpoints.up('md')]: {
            paddingLeft: '16px',
            paddingRight: '16px',
            minWidth: '24px',
            minHeight: '24px',
            margin: 0,
            padding: 0,
          }
        }
      },
      RaListToolbar: {
        toolbar: {
          backgroundColor: currentTheme.background.secondary,
          [currentTheme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            marginTop: currentTheme.spacing(5),
          },
          [currentTheme.breakpoints.up('sm')]: {
            marginTop: currentTheme.spacing(7),
          },
          [currentTheme.breakpoints.up('md')]: {
            marginTop: currentTheme.spacing(2),
          },
        },
      },
      MuiPagination: {
        ul: {
          flexWrap: 'nowrap'
        }
      },
      MuiAppBar: {
        colorPrimary: {
          display: 'flex',
          justifyContent: 'flex-start',
          height: currentTheme.spacing(8),
          backgroundColor: currentTheme.background.secondary,
        },
      },
      MuiMenuItem: {
        root: {
          color: currentTheme.text.primary,
          fontFamily: 'DM Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '16px',
          lineHeight: '24px',
          letterSpacing: '0.15px',
          textAlign: 'left',
          padding: '12px 24px 12px 24px',
          paddingTop: '16px',
          paddingBottom: '16px',
        }
      },
      RaSidebar: {
        root: {
          height: 'auto',
        },
        drawerPaper: {
          boxShadow: '1px 0px 0px rgba(31, 31, 34, 1)',
          backgroundColor: currentTheme.background.drawer,
          position: 'relative',
          width: '281px',
        },
        fixed: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          position: 'fixed',
          width: 'inherit',
          height: '100vh',
        }
      },
      MuiCard: {
        root: {
          backgroundColor: currentTheme.surface.primary,
          borderRadius: '10px',
        },
      },
      MuiChip: {
        label: {
          padding: '8px',
          paddingLeft: '16px',
          paddingRight: '16px',
        },
        clickable: {
          '&:hover': {
            backgroundColor: 'rgb(206 206 206 / 10%)'
          },
          '&:focus': {
            backgroundColor: 'rgb(206 206 206 / 10%)'
          }
        }
      },
      MuiDrawer: {
        paper: {
          backgroundColor: 'transparent'
        },
        paperAnchorRight: {
          backgroundColor: currentTheme.background.drawer,
          overflowY: 'auto'
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: '#FFFFFF',
          opacity: 0.1,
        },
      },
      RaCreateButton: {
        floating: {
          backgroundColor: currentTheme.background.dialog,
        }
      },
      MuiFormControl: {
        root: {
          width: '-webkit-fill-available',
        }
      },
      MuiBackdrop: {
        root: {
          backdrop: {
            ['@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))']: {
                '-webkit-backdrop-filter': 'blur(2em)',
                backdropFilter: 'blur(2em)',
                backgroundColor: 'rgb(0 0 0 / 33%) !important',
            }
          },
        }
      },
      MuiList: {
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        }
      },
      MuiPopover: {
        paper: {
          backgroundColor: currentTheme.input.background,
          boxSizing: 'border-box',
          boxShadow: '0px 14px 24px rgba(0, 0, 0, 0.25)',
          transform: 'rotate(-180deg)',
        }
      },
      MuiTextField: {
        root: {
          padding: '0px',
          width: '100%',
          borderRadius: '4px 4px 0px 0px',
        },
      },
      MuiTablePagination: {
        select: {
          background: 'transparent',
        }
      },
      MuiSelect: {
        root: {
          width: '100%',
          height: '48px',
          background: 'transparent',
          padding: 0
        },
        select: {
          paddingLeft: '16px',
          paddingRight: '24px',
          borderRadius: '4px 4px 0px 0px',
          '&:focus': {
            backgroundColor: currentTheme.input.background,
          },
        },
        selectMenu: {
          height: '48px',
          boxSizing: 'border-box',
          padding: '12px',
          background: currentTheme.input.background,
        },
        icon: {
          color: currentTheme.text.primary,
          marginRight: '12px'
        },
      },
      MuiInput: {
        root: {
          width: '100%'
        },
        underline: {
          '&:before': {
            borderBottom: '1px solid #ffffff80',
          },
          '&:after': {
            borderBottom: '1px solid #ffffff80',
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: '1px solid #FAFAFA',
          }
        },
      },
      MuiInputLabel: {
        formControl: {
          position: 'absolute',
          top: '6px',
          left: '16px',
          transform: 'unset',
        },
        root: {
          color: currentTheme.text.subtitle,
          fontFamily: 'DM Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '12px',
          lineHeight: '16px',
          letterSpacing: '0.4px',
        }
      },
    },
    typography: {
      fontFamily: 'DM Sans',
      h1: {
        color: currentTheme.text.primary,
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '50px',
        lineHeight: '65px',
        letterSpacing: 'unset'
      },
      h2: {
        color: currentTheme.text.primary,
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '40px',
        lineHeight: '52px',
        letterSpacing: '-1px',
      },
      h3: {
        color: currentTheme.text.primary,
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '32px',
        lineHeight: '42px',
        letterSpacing: '-0.5px',
      },
      h4: {
        color: currentTheme.text.primary,
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '24px',
        lineHeight: '31px',
      },
      h5: {
        color: currentTheme.text.primary,
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '22px',
        lineHeight: '28px',
      },
      h6: {
        color: currentTheme.text.primary,
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '18px',
      },
      subtitle1: {
        color: currentTheme.text.subtitle,
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '140%',
        letterSpacing: '0.4px',
      },
      subtitle2: {
        color: currentTheme.text.body,
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px',
        opacity: .5
      },
      subtitle4: {
        color: currentTheme.text.body,
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '21px',
        letterSpacing: '-0.31px',
        fontWeight: 400,
      },
      link: {
        textDecoration: 'none',
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '18px',
      },
      body1:{
        color: currentTheme.text.primary,
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '18px',
      },
      body2: {
        color: currentTheme.text.inputLabel,
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
      },
      button: {
        color: currentTheme.text.primary,
        textTransform: 'none',
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '21px',
        letterSpacing: '-0.5px',
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1440,
        xl: 1920,
      },
    },
    palette: {
        ...(currentTheme)
    },
  });
}

const baseTheme = {
  primary: {
    main: '#10101C',
  },
  customTypography: {
    currency: {
      color: '#FAFAFA',
      fontFamily: 'DM Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '24px',
      lineHeight: '31px',
      textTransform: 'uppercase',
    }
  },
  secondary: {
    main: '#161623',
  },
  surface: {
    primary: 'rgba(36, 36, 42, 0.8)',
    secondary: 'rgba(36, 36, 42, 0.8)'
  },
  background: {
    primary: 'rgba(20, 20, 23, 1)',
    secondary: '#161623',
    drawer: '#141417',
    dialog: 'rgba(36, 36, 42, 0.8)',
  },
  lozenge: {
    borderRadius: '100px',
    width: '79px',
    height: '22px',
    trust: {
      backgroundColor: '#1D213A',
      color: '#517AE6'
    }
  },
  button: {
    backgroundColor: '#A3A2FF',
  },
  input: {
    background: '#141417'
  },
  table: {
    borderColor: '1px solid #EEEEEE',
  },
  text: {
    secondary: '#99999e',
    primary: '#FDFDFD',
    body: '#FFFFFF',
    button: {
      primary: '#141417',
      secondary: '#A3A2FF',
      inline: '#FFFFFF',
      destructive: '#FF768D',
    },
    infoLabel: {
      primary: '#9892FF',
      secondary: 'rgba(250, 250, 250, 0.6)',
      completed: '#FAFAFA',
      pending: '#FFBC3A',
      failed: '#FF768D'
    },
    subtitle: '#fafafa66',
    inputLabel: '#ffffffde',
    disabled: '#ffffff4d',
    tertiary: '#B9B9B9',
    positive: '#66BA00',
    negative: '#FF768D',
  },
};