import {useState} from "react";
import {useCreate} from "react-admin";
import { CUSTOMER_NOTE_TYPES } from "../types";

export const useCompleteKycFormControls = (initValues) => {
 
    const [values, setValues] = useState({
        additionalContext: initValues.additionalContext || '',
        customerId: initValues.customerId,
        actorEmail: initValues.actorEmail,
    });

    const [errors, setErrors] = useState({});
    const [createNote] = useCreate('notes');
    const [createKyc] = useCreate('origination');

    const getValues = () => (values);

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        const { additionalContext } = fieldValues;

        temp.additionalContext = additionalContext ? '' : "This field is required."

        setErrors({
          ...temp
        });
    }

    const handleTextInputValue = (e) => {
        const { name, value } = e.target;
        setValues({
          ...values,
          [name]: value,
          isUpdated: true
        });
        validate({ [name]: value });
    }

    const handleCompleteKycFormSubmit = async (onComplete) => {
        createKyc({
            payload: {
                customerId: values.customerId
            }
        }, {
            onSuccess: ({data}) => {
                const isSuccessful = data.error === undefined
                if (isSuccessful) postCreateNote(onComplete)
                else onComplete(false)
            },
            onFailure: ((e) => console.error(e))
        });
    }

    const postCreateNote = async (onComplete) => {
        const { additionalContext, customerId, actorEmail } = values;
        createNote({
            payload: {
              customerId,
              actor: actorEmail,
              note: "KYC manually confirmed",
              additionalContext,
              noteType: CUSTOMER_NOTE_TYPES.ACTIVITY
            }
        }, {
          onSuccess: ({data}) => {
            setTimeout(() => {
                onComplete(data.error === undefined)
          }, 1000);
          },
          onFailure: ((e) => console.error(e))
        });
      }  

    const formIsValid = (fieldValues = values) => {
        const isValid =
            fieldValues.additionalContext &&
            Object.values(errors).every((x) => x === '');
        return isValid;
    }

    return {
        getValues,
        handleTextInputValue,
        handleCompleteKycFormSubmit,
        formIsValid,
        inputErrors: errors
    };
}