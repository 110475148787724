import {client} from "./graphql/client";
import {completeKycOriginationMutation} from "./graphql/origination/mutations";
import {formatApolloError} from "./graphql/error";

export const originationDataProvider = {
    create: async(resource, params) => {
        try {
            const result = await client.mutate({
                mutation: completeKycOriginationMutation,
                variables: params
            });
            await client.clearStore(); // forces cache reset for new queries
            return {
                data: {
                    id: 200
                }
            }
        } catch (e) {
            return Promise.resolve({
                data: {
                    id: -1,
                    error: formatApolloError(e)
                }
            });
        }
    }
}