import { client } from "./graphql/client";
import { listTransactionsQuery } from "./graphql/transaction/queries";
import { mapTransactionMutation } from "./graphql/mapper";
import { formatApolloError } from "./graphql/error";

export const transactionDataProvider = {

    getList: async(resource, params) => {
        try {
            const { accountId } = params;
            if (!accountId) {
                return {
                    data: [],
                    total: 0
                }
            }
            const result = await client.query({
                query: listTransactionsQuery,
                variables: {
                    accountId
                }
            });
            const transactions = result.data["allTransactions_Admin"]
            return {
                data: transactions,
                total: transactions.length
            }
        } catch (e) {
            return Promise.reject({
                error: formatApolloError(e),
                data: [],
                total: 0
            });
        }
        
    },

    // TODO: implement when/if BFF has query to retrieve transaction by id
    // getOne: async(resource, params) => {
    //     try {
    //         return {
    //             data: {
    //                 result: {}
    //             }
    //         }
    //     } catch (error) {
    //         return Promise.reject(error);
    //     }
    // },

    create: async(resource, params) => {
        const {
            transactionType, // this is added to determine what type of transaction is being created
            ...args
        } = params;

        try {
            const result = await client.mutate({
                mutation: mapTransactionMutation(transactionType),
                variables: { 
                    input: {
                        ...args,
                    }
                }
            });

            await client.clearStore(); // forces cache reset for new queries (allows for refetch of updated transactions)
            const resultKey = Object.keys(result.data)[0];

            return {
                data: result.data[resultKey]
            }
        } catch (e) {
            return Promise.resolve({
                data: {
                    id: -1,
                    error: formatApolloError(e)
                }
            });
        }
    },

}