import React from "react";

function TickIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="12"
            fill="none"
            viewBox="0 0 17 12"
        >
            <path
                fill="#A3A2FF"
                fillRule="evenodd"
                d="M.47 5.825a.75.75 0 011.06 0l4.22 4.22L15.575.22a.75.75 0 111.06 1.06L6.28 11.636a.75.75 0 01-1.06 0L.47 6.886a.75.75 0 010-1.06z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default TickIcon;
