
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";

const FilterChip = ({label, index, onSelect, isDefaultSelected=false}) => {
    const [isSelected, setIsSelected] = useState(isDefaultSelected);
    const useStyles = makeStyles((theme) => ({
        root: {
            paddingRight: '8px',
            height: '40px',
            background: 'transparent',
            color: isSelected ? '#A3A2FF' : '#FAFAFA',
            '& > span': {
                borderRadius: '54px',
                display: 'inline-flex',
                justifyContent: 'center',
                flexDirection: 'column',
                height: '22px',
                border: isSelected ? '1px solid #A3A2FF' : '1px solid rgba(250, 250, 250, 0.5)',
                '&:hover': {
                    cursor: 'pointer',
                }
            },
            fontFamily: 'DM Sans',
            fontStyle: 'normal',
            lineHeight: '16px',
            fontWeight: 500,
            fontSize: '14px',
        },
      }));
    const classes = useStyles();
    return (
        <div
            key={index}
            className={classes.root}
            onClick={() => {setIsSelected(!isSelected); onSelect(index)}}
        >
            <Chip className={classes.root} label={label}/>
        </div>
    );
};

export default FilterChip;
