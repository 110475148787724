import {Show, useDataProvider} from "react-admin";
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import BackButton from "../../components/button/backButton";
import LoadingView from "../transition/loadingView";
import GenericError from "../../components/error/genericError";
import PaymentMethodsCard from "../../components/card/paymentMethodsCard";
import CustomerDetailsCard from "../../components/card/customerDetailsCard";
import {useEffect, useRef, useState} from "react";
import VerificationDetailsCard from "../../components/card/verificationDetailsCard"
import CustomerTitleCard from "../../components/card/customerTitleCard";
import CustomerBalanceCard from "../../components/card/customerBalanceCard";
import NotesCard from "../../components/card/notesCard";
import ActivityList from "../../components/list/activityList";

const CUSTOMER_CURRENCY = "USD";

const CustomerShow = (props) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%'
        },
        titleContainer: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(3)
        },
    }));
    const classes = useStyles();
    const activityList = useRef(null);
    const transactionList = useRef(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    const dataProvider = useDataProvider();
    const [triggerRefresh, setTriggerRefresh] = useState(false);

    useEffect(() => {
        if (triggerRefresh) {
            setLoading(true);
            dataProvider.getOne('customers', {id: props.id})
                .then(({data}) => {
                    setData(data);
                    setError(null)
                })
                .catch((error) => {
                    setError(error);
                })
                .finally(() => {
                    setLoading(false);
                    setTriggerRefresh(false);
                });
        }
    }, [triggerRefresh]);

    useEffect(() => {
        dataProvider.getOne('customers', {id: props.id})
            .then(({data}) => {
                setData(data);
                setError(null)
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    if (error) return <><BackButton/><GenericError/></>;
    if (loading) return <LoadingView variant="show"/>;

    const {
        name,
        id,
        accounts,
        status,
        kycStatus
    } = data;
    const {
        id: accountId,
        balances
    } = accounts?.[0] || {wallet: {}};
    const {[CUSTOMER_CURRENCY]: customerBalances} = balances || {[CUSTOMER_CURRENCY]: 0};
    const customerBalance = customerBalances.availableBalance ?? 0
    const totalInterest = customerBalances.totalInterestAccrued ?? 0
    return (
        <Show className={console.root} {...props}>
            <>
                <BackButton/>
                <div className={classes.titleContainer}>
                    <CustomerTitleCard customerLocked={status == "SUSPENDED"} customerClosed={status == "CLOSED"}
                                       kycStatus={kycStatus} name={name} customerId={id}
                                       onCustomerUpdate={() => setTriggerRefresh(true)}/>
                </div>
                <Grid container spacing={3} className={classes.gridContainer}>
                    <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                        <CustomerDetailsCard data={data} refreshCustomerView={() => setTriggerRefresh(true)}/>
                        <VerificationDetailsCard customerData={data}/>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <CustomerBalanceCard accountId={accountId} customerBalance={customerBalance}
                                             totalInterest={totalInterest} activityListRef={activityList}/>
                        <ActivityList ref={activityList} customerId={id} accountId={accountId}/>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                        <PaymentMethodsCard customerId={id} customerName={name}/>
                        <NotesCard notes={data.notes} customerId={id}
                                   refreshCustomerView={() => setTriggerRefresh(true)}/>
                    </Grid>
                </Grid>
            </>
        </Show>
    );
};

export default CustomerShow;