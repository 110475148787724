import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    root: {
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    label: {
        marginTop: '12px'
    }
}));

const NoResults = ({className, label="No results"}) => {
    const classes = useStyles();
    return (
        <div className={`${classes.root} ${className}`}>
            <Typography variant="subtitle1" className={classes.label}>{label}</Typography>
        </div>
    )
}

export default NoResults;