import { gql } from "@apollo/client";

export const createCustomerNoteMutation = gql`
  mutation CreateCustomerNote_Admin($input: CreateCustomerNoteMutationInput) {
    createCustomerNote_Admin(input: $input) {
      note
      lastUpdateDate
      id
      customerId
      createDate
      actor
      noteType
      additionalContext
    }
  }
`

export const updateCustomerNoteMutation = gql`
  mutation UpdateCustomerNote_Admin($input: UpdateCustomerNoteMutationInput) {
    updateCustomerNote_Admin(input: $input) {
      note
      lastUpdateDate
      id
      customerId
      createDate
      actor
      noteType
      additionalContext
    }
  }
`

export const deleteCustomerNoteMutation = gql`
  mutation DeleteCustomerNote_Admin($input: DeleteCustomerNoteMutationInput) {
    deleteCustomerNote_Admin(input: $input) {
      id
      customerId
      actor
      note
      createDate
      lastUpdateDate
      noteType
      additionalContext
    }
  }
`