import React from "react";

function BankIcon() {
  return (
      <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          fill="none"
          viewBox="0 0 40 40"
      >
        <rect width="40" height="40" fill="#36363B" rx="20"></rect>
        <path
            fill="#FDFDFD"
            fillRule="evenodd"
            d="M19.614 11.107a.75.75 0 01.771 0l9.254 5.552a.75.75 0 01-.772 1.286l-8.868-5.32-8.867 5.32a.75.75 0 01-.772-1.286l9.254-5.552zm-7.016 7.296H28.328a.75.75 0 010 1.5h-.176v7.753h1.1a.75.75 0 010 1.5H10.747a.75.75 0 010-1.5h1.102v-7.753h-.176a.75.75 0 010-1.5h.926zm.75 9.253h1.277v-7.753h-1.277v7.753zm2.777 0h7.752v-7.753h-7.752v7.753zm9.252 0h1.275v-7.753h-1.275v7.753z"
            clipRule="evenodd"
        ></path>
      </svg>
  );
}

export default BankIcon;
