import { client } from "./graphql/client";
import { formatApolloError } from "./graphql/error";
import { mapPaymentMethodMutation } from "./graphql/mapper";
import { getPaymentMethodsQuery } from "./graphql/paymentMethod/queries";

export const paymentMethodDataProvider = {

    getOne: async(resource, params) => {
        const {
            customerId,
        } = params;
        try {
            const result = await client.mutate({
                mutation: getPaymentMethodsQuery,
                variables: {
                    customerId
                }
            });
    
            const response = result.data
    
            return {
                data: {
                    id: customerId,
                    result: response
                }
            }
        } catch (e) {
            return Promise.resolve({
                data: {
                    id: -1,
                    error: formatApolloError(e)
                }
            });
        }
    },

    create: async(resource, params) => {
        const {
            customerId,
            paymentMethodType,
            data,
            ...args
        } = params;

        try {
            const result = await client.mutate({
                mutation: mapPaymentMethodMutation(paymentMethodType),
                variables: {
                    input: {
                        customerId,
                        ...args
                    }
                }
            });
            await client.clearStore(); // forces cache reset for new queries

            const response = result.data

            return {
                data: {
                    id: customerId,
                    result: response
                }
            }
        } catch (e) {
            return Promise.resolve({
                data: {
                    id: -1,
                    error: formatApolloError(e)
                }
            });
        }
    },
}