import { client } from "./graphql/client";
import { formatApolloError } from "./graphql/error";
import { findOrderByReferenceQuery, getOrderQuery } from "./graphql/order/queries";

export const orderDataProvider = {
    getOne: async(resource, params) => {
        try {
            const { orderId } = params;
            if (!orderId) {
                return {
                    data: {},
                    total: 0
                }
            }
            const result = await client.query({
                query: getOrderQuery,
                variables: {
                    orderId
                }
            });
            const order = result.data["order_Admin"]
            return {
                data: order,
            }
        } catch (e) {
            return Promise.reject({
                error: formatApolloError(e),
                data: [],
                total: 0
            });
        }

    },

    getManyReference: async(resource, params) => {
        try {
            const { reference } = params;
            if (!reference) {
                return {
                    data: {},
                    total: 0
                }
            }
            const result = await client.query({
                query: findOrderByReferenceQuery,
                variables: {
                    reference
                }
            });
            const results = result.data["findOrderByReference_Admin"]
            return {
                data: results,
                total: results.length
            }
        } catch (e) {
            return Promise.reject({
                error: formatApolloError(e),
                data: [],
                total: 0
            });
        }
    }
}
