import { useState } from "react";
import { useDelete, useUpdate } from "react-admin";

export const useEditNoteFormControls = (initValues) => {

    const [values, setValues] = useState({
        note: initValues.note || '',
        noteId: initValues.noteId,
        customerId: initValues.customerId,
        actorEmail: initValues.actorEmail,
    });

    const [errors, setErrors] = useState({});
    const [update] = useUpdate('notes');
    const [deleteOne] = useDelete('notes');

    const getValues = () => (values);

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        const { note } = fieldValues;

        temp.note = note ? '' : "This field is required."

        setErrors({
          ...temp
        });
    }

    const handleTextInputValue = (e) => {
        const { name, value } = e.target;
        setValues({
          ...values,
          [name]: value,
          isUpdated: true
        });
        validate({ [name]: value });
    }

    const handleUpdateRequested = async (onComplete) => {
      const { note, customerId, actorEmail, noteId } = values;
      update({
          payload: {
            customerId,
            id: noteId,
            actor: actorEmail,
            note
          }
      }, {
        onSuccess: ({data}) => {
          const isSuccessful = data.error?.error === undefined
          setTimeout(() => {
            if (data.error) {
                onComplete(false);
            } else {
                onComplete(isSuccessful);
            }
          }, 1000);
        },
        onFailure: ((e) => console.error(e))
      });
    }

    const handleDeleteRequested = async (onComplete) => {
      const { customerId, noteId } = values;
      deleteOne({
          payload: {
            customerId: customerId,
            id: noteId
          }
      }, {
        onSuccess: ({data}) => {
          const isSuccessful = data.error?.error === undefined
          setTimeout(() => {
              if (data.error) {
                  onComplete(false);
              } else {
                  onComplete(isSuccessful);
              }
          }, 1000);
        },
        onFailure: ((e) => console.error(e))
      });
    }

    const formIsValid = (fieldValues = values) => {
      const isValid =
          fieldValues.note &&
          Object.values(errors).every((x) => x === '');
      return isValid;
    }

    return {
        getValues,
        handleTextInputValue,
        handleUpdateRequested,
        handleDeleteRequested,
        formIsValid,
        inputErrors: errors
    };
}