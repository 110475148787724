
import { makeStyles, Switch as MuiSwitch } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        '& span.Mui-checked': {
            color: theme.text.button.secondary
        },
        '& span.Mui-checked + .MuiSwitch-track': {
            backgroundColor: theme.text.button.secondary
        },
        '& .MuiSwitch-track': {
            backgroundColor: theme.text.primary
        }
    }
}));

const Switch = (props) => {
    const classes = useStyles();

    return (
        <MuiSwitch classes={classes} {...props}/>
    );
}

export default Switch;