import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/styles";
import MobileAppbar from "./mobileAppBar";
import DesktopSidebar from "./desktopSidebar";

const DashboardNavBar = (props) => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("sm")) ? (
    <MobileAppbar {...props} />
  ) : (
    <DesktopSidebar {...props} />
  );
};

export default DashboardNavBar;
