import {FormControl, Select, Typography} from '@material-ui/core';
import {randstr} from "../../utils/common";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import ChevronIcon from "../icons/chevronIcon";
import CloseIcon from "../icons/closeIcon";
import TickIcon from "../icons/tickIcon";

export default function Selector({children, selected, label, defaultOption, valueLookup, ...rest}) {
    const useStyles = makeStyles((theme) => ({
        root: {
            height: '44px',
            width: 'fit-content',
            border: 'none',
            background: selected ? "rgba(132, 130, 255, 0.2)" : '#47474C',
            borderRadius: '37px',
            borderBottom: 'none',
            padding: '0px 24px',
            cursor: 'pointer !important',
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
            },
            '& .MuiSelect-select': {
                cursor: 'pointer !important',
                padding: '0px',
                display: 'flex',
                alignItems: 'center',
                background: 'transparent',
            },
            '& > svg': {
                minHeight: '6px',
                minWidth: '12px',
                margin: '4px 0px'
            },
            '&:before': {
                borderBottom: 'none !important'
            },
            '&:after': {
                borderBottom: 'none !important'
            }
        },
        label: {
            paddingLeft: selected ? '8px' : '0px',
            paddingRight: selected ? '16px' : '8px',
            color: selected ? '#A3A2FF' : '#FDFDFD',
        },
        menu: {
            marginTop: '8px',
            marginStart: '-24px',
            background: 'transparent',
            '& .MuiPopover-paper': {
                width: '304px',
                background: '#24242A !important',
                borderRadius: '0px 0px 8px 8px',
                boxShadow: 'none' // '0px 2px 2px rgb(0 0 0 / 25%)',
            },
        },
        menuList: {
            background: '#24242A !important',
            padding: 0,
            '& > *': {
                height: '54px',
                '&.Mui-selected': {
                    background: 'transparent !important'
                },
                '&:hover': {
                    background: "#36363B !important"
                },
            }
        },
        menuPaper: {}
    }));
    const classes = useStyles();
    const id = randstr('select-');
    const [value, setValue] = React.useState(selected);
    return (
        <FormControl
            style={{width: 'fit-content', display: 'flex', cursor: 'pointer !important'}}
            onClick={
                (e) => {
                    if (selected) {
                        e.stopPropagation();
                        setValue(undefined);
                        rest.onChange({target: {value: undefined}});
                    } else if (!value) {
                        e.stopPropagation()
                        try {
                            const newSelection = valueLookup?.(e.target.getAttribute('data-value'))
                            setValue(newSelection)
                            rest.onChange({target: {value: newSelection}})
                        } catch (e) {
                            console.error(e)
                        }
                    }
                }
            }
        >
            <Select
                disabled={selected}
                value={value || null}
                className={classes.root}
                defaultValue={defaultOption}
                displayEmpty={true}
                id={id}
                label={label}
                IconComponent={NoIcon}
                renderValue={(_) => {
                    return <div style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        {selected && <TickIcon/>}
                        <Typography variant="subtitle4" className={classes.label}>{selected || label}</Typography>
                        {selected ? <CloseIcon/> : <ChevronIcon/>}
                    </div>
                }}
                MenuProps={
                    {
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null,
                        PaperProps: {
                            className: classes.menuPaper
                        },
                        className: classes.menu,
                        MenuListProps: {
                            className: classes.menuList
                        }
                    }
                }
                {...rest}
            >
                {children}
            </Select>
        </FormControl>
    );
}

function NoIcon() {
    return <></>
}