import {makeStyles} from "@material-ui/core/styles";
import {Button, Typography} from "@material-ui/core";

export const useMenuButtonStyles = makeStyles((theme) => {
    return {
        label: {
            color: theme.text.primary,
            fontSize: '15px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '-0.23px',
        },
        [theme.breakpoints.up('sm')]: {
            'root': {
                height: '56px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                width: '100%',
                justifyContent: 'flex-start',
                marginBottom: '24px',
                borderRadius: '8px',
                '&:hover': {
                    backgroundClip: 'content-box',
                    backgroundColor: 'rgb(0 0 0 / 25%)',
                    opacity: .75,
                },
                '& > span.MuiTouchRipple-root': {
                    margin: '0px 24px 0px 24px',
                    padding: 0
                },
                '& > span': {
                    padding: '16px',
                    '& > svg': {
                        paddingRight: '16px'
                    }
                },
                '& > .MuiButton-label': {
                    height: '-webkit-fill-available',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                },
                [theme.breakpoints.down('sm')]: {
                    '&:first-child': {
                        paddingLeft: '4px',
                    }
                },
            },
            active: {
                background: 'rgba(73, 65, 236, 0.04)',
                backgroundClip: 'content-box',
                '& > div > svg': {
                    color: 'rgba(73, 65, 236, 1)'
                },
                '& > p': {
                    color: 'rgba(163, 162, 255, 1)'
                }
            },
        },
    }
});

const MenuButton = ({label, onClick, icon, children, ...props}) => {
    const classes = useMenuButtonStyles();
    return (
        <Button variant={'text'}
                classes={classes}
                onClick={onClick}
                {...props}
        >
            {icon}
            <Typography variant="body1" className={classes.label}>{label}</Typography>
        </Button>
    );
};

export default MenuButton;
