
import React from "react";

function LogoIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      fill="none"
      viewBox="0 0 34 34"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M2.265 30.458c-.92-1.68.292-3.921 2.947-6.095.31-.254.715-.358 1.11-.296 3.466.533 8.575-.142 13.768-2.03 3.105-1.13 5.792-2.532 7.842-4.003.348-.25.79-.33 1.2-.212 1.336.38 2.304.996 2.773 1.849 1.662 3.03-3.627 7.902-11.81 10.88-8.185 2.98-16.167 2.938-17.826-.093h-.004zm17.414-16.2c8.184-2.979 13.564-7.685 12.015-10.512-1.55-2.827-9.44-2.703-17.623.276C5.886 7 .505 11.706 2.054 14.534c1.55 2.827 9.44 2.703 17.624-.276z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default LogoIcon;
