export const TRANSACTION_TYPES = {
    CREDIT: "CREDIT",
    PENDING_CREDIT: "PENDING_CREDIT",
    CANCEL_PENDING_CREDIT: "CANCEL_PENDING_CREDIT",
    REFUND_PENDING_CREDIT: "REFUND_PENDING_CREDIT",
    DEBIT: "DEBIT",
    PENDING_DEBIT: "PENDING_DEBIT",
    SEND_FIAT: "SEND_FIAT",
    CANCEL_SEND_FIAT: "CANCEL_SEND_FIAT",
    RETRY_SEND_FIAT: "RETRY_SEND_FIAT",
}

export const TRANSACTION_STATUS_LABELS = {
    CREDIT: "COMPLETED",
    PENDING_CREDIT: "PENDING",
    DEBIT: "COMPLETED",
    PENDING_DEBIT: "PENDING",
    REFUND: 'FAILED',
    CANCEL: 'FAILED',
}

export const ACTIVITY_STATUS_FILTERS = {
    COMPLETE: "Completed",
    PENDING: "Pending",
    INCOMPLETE: "Incomplete"
}

export const activityStatusFilterValue = (value) => {
    switch (value) {
        case ACTIVITY_STATUS_FILTERS.COMPLETE:
            return 'COMPLETE';
        case ACTIVITY_STATUS_FILTERS.PENDING:
            return 'PENDING';
        case ACTIVITY_STATUS_FILTERS.INCOMPLETE:
            return 'INCOMPLETE';
        default:
            return undefined;
    }
}

export const TRANSACTION_CATEGORIES = {
    INTEREST: "INTEREST",
    WITHDRAWAL: "WITHDRAWAL",
    DEPOSIT: "DEPOSIT",
    AUTH: "AUTH",
    CAPTURE: "CAPTURE",
    REFUND: "REFUND",
    CANCEL: "CANCEL",
    ADJUSTMENT: "ADJUSTMENT"
}

export const ACTIVITY_TYPES = {
    ONBOARDING: "ONBOARDING",
    WITHDRAWAL: "WITHDRAWAL",
    DEPOSIT: "DEPOSIT",
    PAYMENT: "PAYMENT",
    REFUND: "REFUND",
    SENT: "SENT",
    RECEIVED: "RECEIVED",
}

export const ACTIVITY_TYPE_FILTERS = {
    WITHDRAWAL: "Withdrawal",
    DEPOSIT: "Top Up",
    PAYMENT: "Payment",
    REFUND: "Refund",
    SENT: "Sent",
    RECEIVED: "Received",
}

export const activityTypeFilterValue = (value) => {
    switch (value) {
        case ACTIVITY_TYPE_FILTERS.WITHDRAWAL:
            return 'WITHDRAWAL';
        case ACTIVITY_TYPE_FILTERS.DEPOSIT:
            return 'DEPOSIT';
        case ACTIVITY_TYPE_FILTERS.PAYMENT:
            return 'PAYMENT';
        case ACTIVITY_TYPE_FILTERS.REFUND:
            return 'REFUND';
        case ACTIVITY_TYPE_FILTERS.SENT:
            return 'SENT';
        case ACTIVITY_TYPE_FILTERS.RECEIVED:
            return 'RECEIVED';
        default:
            return undefined;
    }
}

export const ACTIVITIES_VIEW_MODE = {
    ALL: 'All transactions',
    NEEDS_ACTION: 'Needs action',
}

export const activityViewModeValue = (value) => {
    switch (value) {
        case ACTIVITIES_VIEW_MODE.ALL:
            return 'ALL';
        case ACTIVITIES_VIEW_MODE.NEEDS_ACTION:
            return 'NEEDS_ACTION';
        default:
            return undefined;
    }
}

export const PAYMENT_METHOD_TYPES = {
    PAY_ID_RECEIVABLES: "PAY_ID_RECEIVABLES",
    BANK_ACCOUNT_RECEIVABLES: "BANK_ACCOUNT_RECEIVABLES",
    PAY_ID_PAYABLES: "PAY_ID_PAYABLES",
    BANK_ACCOUNT_PAYABLES: "BANK_ACCOUNT_PAYABLES"
}

export const DEPOSIT_CURRENCIES = {
    AED: 'AED',
    AFN: 'AFN',
    ALL: 'ALL',
    AMD: 'AMD',
    ANG: 'ANG',
    AOA: 'AOA',
    ARS: 'ARS',
    AUD: 'AUD',
    AWG: 'AWG',
    AZN: 'AZN',
    BAM: 'BAM',
    BBD: 'BBD',
    BDT: 'BDT',
    BGN: 'BGN',
    BHD: 'BHD',
    BIF: 'BIF',
    BMD: 'BMD',
    BND: 'BND',
    BOB: 'BOB',
    BRL: 'BRL',
    BSD: 'BSD',
    BTN: 'BTN',
    BWP: 'BWP',
    BYN: 'BYN',
    BZD: 'BZD',
    CAD: 'CAD',
    CDF: 'CDF',
    CHF: 'CHF',
    CLP: 'CLP',
    CNY: 'CNY',
    COP: 'COP',
    CRC: 'CRC',
    CUP: 'CUP',
    CVE: 'CVE',
    CZK: 'CZK',
    DJF: 'DJF',
    DKK: 'DKK',
    DOP: 'DOP',
    DZD: 'DZD',
    EGP: 'EGP',
    ERN: 'ERN',
    ETB: 'ETB',
    EUR: 'EUR',
    FKP: 'FKP',
    GBP: 'GBP',
    GEL: 'GEL',
    GHS: 'GHS',
    GMD: 'GMD',
    GNF: 'GNF',
    GTQ: 'GTQ',
    GYD: 'GYD',
    HKD: 'HKD',
    HNL: 'HNL',
    HTG: 'HTG',
    HUF: 'HUF',
    IDR: 'IDR',
    ILS: 'ILS',
    INR: 'INR',
    IQD: 'IQD',
    IRR: 'IRR',
    ISK: 'ISK',
    JMD: 'JMD',
    JOD: 'JOD',
    JPY: 'JPY',
    KES: 'KES',
    KGS: 'KGS',
    KHR: 'KHR',
    KMF: 'KMF',
    KRW: 'KRW',
    KWD: 'KWD',
    KYD: 'KYD',
    KZT: 'KZT',
    LAK: 'LAK',
    LKR: 'LKR',
    LRD: 'LRD',
    LSL: 'LSL',
    LYD: 'LYD',
    MAD: 'MAD',
    MDL: 'MDL',
    MGA: 'MGA',
    MKD: 'MKD',
    MMK: 'MMK',
    MNT: 'MNT',
    MOP: 'MOP',
    MUR: 'MUR',
    MVR: 'MVR',
    MWK: 'MWK',
    MXN: 'MXN',
    MYR: 'MYR',
    MZN: 'MZN',
    NAD: 'NAD',
    NGN: 'NGN',
    NIO: 'NIO',
    NOK: 'NOK',
    NPR: 'NPR',
    NZD: 'NZD',
    OMR: 'OMR',
    PAB: 'PAB',
    PEN: 'PEN',
    PHP: 'PHP',
    PKR: 'PKR',
    PLN: 'PLN',
    PYG: 'PYG',
    QAR: 'QAR',
    RON: 'RON',
    RSD: 'RSD',
    RWF: 'RWF',
    SAR: 'SAR',
    SCR: 'SCR',
    SDG: 'SDG',
    SEK: 'SEK',
    SGD: 'SGD',
    SOS: 'SOS',
    SRD: 'SRD',
    SYP: 'SYP',
    SZL: 'SZL',
    THB: 'THB',
    TJS: 'TJS',
    TMT: 'TMT',
    TND: 'TND',
    TRY: 'TRY',
    TTD: 'TTD',
    TWD: 'TWD',
    TZS: 'TZS',
    UAH: 'UAH',
    UGX: 'UGX',
    USD: 'USD',
    UYU: 'UYU',
    UZS: 'UZS',
    VND: 'VND',
    XAF: 'XAF',
    XCD: 'XCD',
    XOF: 'XOF',
    YER: 'YER',
    ZAR: 'ZAR',
    ZMW: 'ZMW',
    USDC: 'USDC',
    USDT: 'USDT',
    BUSD: 'BUSD'
}

export const CUSTOMER_NOTE_TYPES = {
    MANUAL: "MANUAL",
    ACTIVITY: "ACTIVITY"
}
