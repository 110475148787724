import { makeStyles, TextField as MuiTextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '16px 14px',
        '& label.Mui-focused': {
            color: theme.text.subtitle,
            borderBottom: 'none !important',
            transition: 'none',
        },
        '& ::-webkit-calendar-picker-indicator': {
            filter: 'invert(1)'
        },
        '& .MuiInput-underline:before': {
            transition: 'none',
            borderBottom: 'none !important'
        },
        '& .MuiInput-underline:after': {
            transition: 'none',
            borderBottom: 'none !important'
        },
        '& .MuiInputBase-root': {
            height: 'inherit !important',
            '& > textarea': {
                height: 'inherit !important',
                fontFamily: 'DM Sans',
                fontWeight: '400',
                fontSize: '15px',
                lineHeight: '20px',
                color: '#C6C6C8'
            },
        },
        '& .MuiFormHelperText-root': {
            marginTop: '24px'
        },
        fontWeight: 'bold',
        fontSize: '20px',
        color: 'purple',
        width: '-webkit-fill-available',
        height: '344px'    
    }
}));

const TextArea = (props) => {
    const classes = useStyles();

    return (
        <MuiTextField
            classes={classes}
            multiline
            rows={16}
            {...props}
        />
    );
}

export default TextArea;