import React from "react";

function LogoutIcon() {
  return (
      <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="20"
          fill="none"
          viewBox="0 0 17 20"
      >
        <path
            stroke="#C8BCF6"
            strokeLinecap="round"
            strokeWidth="1.5"
            d="M6.5 10h9m0 0l-2-2m2 2l-2 2m-2 2v3a2 2 0 01-2 2h-6a2 2 0 01-2-2V3a2 2 0 012-2h6a2 2 0 012 2v3"
        ></path>
      </svg>
  );
}

export default LogoutIcon;
