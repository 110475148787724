import { Card, CardContent, Divider, makeStyles, Typography } from "@material-ui/core";
import DrawerModal from "./drawerModal";
import { formatActivityTimestamp } from "../../utils/formatter.js";
import { ACTIVITY_TYPES, TRANSACTION_CATEGORIES, TRANSACTION_TYPES } from "../../utils/types";
import CircularLoader from "../loading/circularLoader";
import GenericError from "../error/genericError";
import { useEffect, useState } from "react";
import { useDataProvider } from "react-admin";
import ActionButton from "../button/actionButton";
import ModalConsumer from "../consumer/modalConsumer";
import CompleteTransactionDialog from "../dialog/completeTransactionDialog";
import { AdminRole } from "../../utils/role";
import { useHistory } from "react-router-dom";
import ConfirmTransactionDialog from "../dialog/confirmTransactionDialog";
import RetryIcon from "../icons/retryIcon";
import RefundCancelTransactionDialog from "../dialog/refundCancelTransactionDialog";

const ViewActivityDrawer = ({
                                params: {row},
                                customerId,
                                activityReference,
                                refreshActivityList,
                                onClose,
                                resource = "activities"
                            }) => {
    const {
        id,
        userId,
        type,
        reference,
        linkedTransaction
    } = row;
    let content = <UnknownActivityDetails data={row}/>
    const [customer, setCustomer] = useState()
    const [refresh, setRefresh] = useState(false)
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const dataProvider = useDataProvider();
    const showCustomerCard = window.location.href.includes("/#/globalActivities")
    useEffect(() => {
        showCustomerCard && setRefresh(true)
    }, [showCustomerCard])
    useEffect(() => {
        if (refresh === true && showCustomerCard) {
            setLoading(true)
            dataProvider.getOne('customers', {id: userId})
                .then(({data}) => {
                    setCustomer(data);
                })
                .catch((error) => {
                    setError(error)
                })
                .finally(() => {
                    setLoading(false)
                    setRefresh(false)
                });
        }
    }, [refresh, showCustomerCard])
    switch (type) {
        case ACTIVITY_TYPES.DEPOSIT:
            content = <DepositActivityDetails activityId={id} customerId={customerId} type={type}
                                              linkedTransaction={linkedTransaction}
                                              resource={resource}
                                              activityReference={activityReference}
                                              orderId={reference}
                                              isLoading={loading}
                                              parentError={error}
                                              customer={customer}
                                              onClose={onClose}
                                              refreshActivityList={refreshActivityList}/>
            break
        case ACTIVITY_TYPES.WITHDRAWAL:
            content = <WithdrawActivityDetails activityId={id} customerId={customerId} type={type}
                                               linkedTransaction={linkedTransaction}
                                               resource={resource}
                                               activityReference={activityReference}
                                               orderId={reference}
                                               isLoading={loading}
                                               parentError={error}
                                               customer={customer}
                                               onClose={onClose}
                                               refreshActivityList={refreshActivityList}/>
            break
        case ACTIVITY_TYPES.PAYMENT:
            content = <PaymentActivityDetails activityId={id} customerId={customerId} type={type}
                                              resource={resource}
                                              activityReference={activityReference}
                                              orderId={reference}
                                              isLoading={loading}
                                              parentError={error}
                                              customer={customer}
                                              onClose={onClose}
                                              linkedTransaction={linkedTransaction}/>
            break
        case ACTIVITY_TYPES.REFUND:
            content = <RefundActivityDetails activityId={id} customerId={customerId} type={type}
                                             resource={resource}
                                             activityReference={activityReference}
                                             orderId={reference}
                                             isLoading={loading}
                                             parentError={error}
                                             customer={customer}
                                             onClose={onClose}
                                             linkedTransaction={linkedTransaction}/>
            break
        case ACTIVITY_TYPES.SENT:
            content = <SentActivityDetails activityId={id} customerId={customerId} type={type}
                                           linkedTransaction={linkedTransaction}
                                           resource={resource}
                                           isLoading={loading}
                                           activityReference={activityReference}
                                           parentError={error}
                                           customer={customer}
                                           onClose={onClose}
                                           refreshActivityList={refreshActivityList}/>
            break
        case ACTIVITY_TYPES.RECEIVED:
            content = <SendReceivedActivityDetails activityId={id} customerId={customerId} type={type}
                                                   resource={resource}
                                                   isLoading={loading}
                                                   parentError={error}
                                                   customer={customer}
                                                   activityReference={activityReference}
                                                   onClose={onClose}
                                                   linkedTransaction={linkedTransaction}/>
            break
        default:
            content = <UnknownActivityDetails data={row}/>
    }
    return (
        <DrawerModal onClose={onClose}>
            {content}
        </DrawerModal>
    )
}

const useDetailStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    category: {
        marginBottom: '16px'
    },
    heading: {
        marginBottom: '16px'
    },
    status: {
        margin: 0,
        color: theme.text.primary,
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '21px',
    },
    errorContainer: {
        width: '100%',
        height: '100%',
        // minHeight: `${cardHeight}em`,
        display: 'flex',
    },
    error: {
        display: 'flex',
        margin: 'auto'
    },
    infoCard: {
        margin: '16px 0px 16px 0px',
        '&:first-child': {
            marginTop: '49px',
        },
        '& > div': {
            '& > div': {
                paddingTop: '16px',
                paddingBottom: '16px'
            },
            '& > hr': {
                marginTop: '16px',
                marginBottom: '16px'
            }
        }
    },
    infoRow: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '12px 0px 0px 12px',
    },
    statusRow: {
        display: 'flex',
        padding: '12px 0px 0px 12px',
    },
    buttonRow: {
        paddingTop: '8px',
        display: 'flex',
        justifyContent: 'center',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '32px',
    },
    loader: {
        marginLeft: '-24px',
    },
    resultContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        height: '100%',
    },
    name: {
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '21px',
        textAlign: 'left',
        color: '#FFFFFF',
        opacity: 0.5
    },
    retry: {
        fontFamily: 'SF Pro Text',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '21px',
        textAlign: 'left',
        color: '#A3A2FF',
    },
    value: {
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '21px',
        textAlign: 'right',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '75%',
        color: '#FFFFFF'
    },
    linkValue: {
        textDecorationLine: 'underline',
        color: '#A3A2FF',
        cursor: 'pointer'
    },
    divider: {
        width: '100%',
        marginLeft: '16px',
    },
}));

const UnknownActivityDetails = ({
                                    data: {
                                        userId,
                                        timestamp,
                                        reference,
                                        feedInfo: {
                                            heading,
                                            subHeading
                                        }
                                    }
                                }) => {
    const classes = useDetailStyles();
    return (
        <div>
            <Typography variant='h2' className={classes.heading}>
                {subHeading}
            </Typography>
            <Typography variant='h1' className={classes.heading}>
                {heading}
            </Typography>
            <p className={classes.status}>
                {`${formatActivityTimestamp(timestamp)} • ${subHeading}`}
            </p>
            <Card className={classes.infoCard}>
                <CardContent className={classes.infoContent}>
                    <div className={classes.infoRow}>
                        <Typography variant="body1" className={classes.name}>Customer ID</Typography>
                        <Typography variant="body1" className={classes.value}>{userId}</Typography>
                    </div>
                </CardContent>
            </Card>
            <Card className={classes.infoCard}>
                <CardContent className={classes.infoContent}>
                    {
                        reference &&
                        <div className={classes.infoRow}>
                            <Typography variant="body1" className={classes.name}>Reference</Typography>
                            <Typography variant="body1" className={classes.value}>{reference}</Typography>
                        </div>
                    }
                </CardContent>
            </Card>
        </div>
    )
}

const DepositActivityDetails = ({
                                    customerId,
                                    activityId,
                                    type,
                                    linkedTransaction,
                                    refreshActivityList,
                                    resource,
                                    isLoading,
                                    parentError,
                                    customer,
                                    onClose,
                                    orderId,
                                    activityReference
                                }) => {
    const history = useHistory();
    const [data, setData] = useState({})
    const [refresh, setRefresh] = useState(false)
    const [loading, setLoading] = useState(isLoading);
    const [error, setError] = useState(parentError);
    const dataProvider = useDataProvider();
    useEffect(() => {
        setRefresh(true)
    }, [])
    useEffect(() => {
        if (refresh === true) {
            setLoading(true)
            dataProvider.getOne(resource, {customerId, activityId, type, orderId})
                .then(({data}) => {
                    setData(data);
                })
                .catch((error) => {
                    setError(error)
                })
                .finally(() => {
                    setLoading(false)
                    setRefresh(false)
                });
        }
    }, [refresh])
    const {
        categoryName,
        heading,
        status,
        transactionDate,
        reference,
        transferMethodName,
        transferMethodValue,
        exchangeRateName,
        exchangeRateValue,
        blockchainNetwork,
        blockchainScanLink
    } = data
    const resolvedLinkedTransaction = linkedTransaction ?? data["linkedTransaction"] ?? {}
    const {
        accountId,
        amount: linkedAmount,
        currency: linkedCurrency,
        orderId: linkedOrderId,
        type: transactionType,
        category: transactionCategory,
    } = resolvedLinkedTransaction
    const classes = useDetailStyles();
    const isBank = transferMethodName === "From bank account"
    const isPayId = transferMethodName === "From PayID"
    const isFiat = isBank || isPayId
    return (
        <div className={classes.root}>
            {(error || (!data && !loading)) ?
                <div className={classes.errorContainer}>
                    <GenericError onClick={() => setRefresh(true)} className={classes.error}/>
                </div>
                : loading ?
                    <div className={classes.resultContainer}>
                        <CircularLoader className={classes.loader} trigger={loading} loaderSize='125px'/>
                    </div>
                    :
                    <>
                        <Typography variant='button' className={classes.category}>
                            {categoryName}
                        </Typography>
                        <Typography variant='h2' className={classes.heading}>
                            {heading}
                        </Typography>
                        {customer && <Card className={classes.infoCard}>
                            <CardContent className={classes.infoContent}>
                                <div className={classes.infoRow}>
                                    <Typography variant="body1" className={classes.name}>Customer</Typography>
                                    <Typography variant="body1"
                                                onClick={() => {
                                                    history.push(`/customers/${customer.id}/show`);
                                                    onClose();
                                                }}
                                                className={`${classes.value} ${classes.linkValue}`}>{customer.name}</Typography>
                                </div>
                                <div className={classes.infoRow}>
                                    <Typography variant="body1" className={classes.name}>Customer ID</Typography>
                                    <Typography variant="body1" className={classes.value}>{customer.id}</Typography>
                                </div>
                            </CardContent>
                        </Card>}
                        <Card className={classes.infoCard}>
                            <CardContent className={classes.infoContent}>
                                <div className={classes.infoRow}>
                                    <Typography variant="body1" className={classes.name}>When</Typography>
                                    <Typography variant="body1"
                                                className={classes.value}>{`${formatActivityTimestamp(transactionDate)}`}</Typography>
                                </div>
                                {
                                    transferMethodName && transferMethodValue &&
                                    <div className={classes.infoRow}>
                                        <Typography variant="body1"
                                                    className={classes.name}>{transferMethodName}</Typography>
                                        <Typography variant="body1"
                                                    className={classes.value}>{transferMethodValue}</Typography>
                                    </div>
                                }
                                {
                                    blockchainNetwork &&
                                    <div className={classes.infoRow}>
                                        <Typography variant="body1" className={classes.name}>Network</Typography>
                                        <Typography variant="body1"
                                                    className={classes.value}>{blockchainNetwork}</Typography>
                                    </div>
                                }
                                {
                                    reference &&
                                    <div className={classes.infoRow}>
                                        <Typography variant="body1" className={classes.name}>Reference</Typography>
                                        <Typography variant="body1" className={classes.value}>{reference}</Typography>
                                    </div>
                                }
                                {
                                    activityReference &&
                                    <div className={classes.infoRow}>
                                        <Typography variant="body1" className={classes.name}>OrderID</Typography>
                                        <Typography variant="body1"
                                                    className={classes.value}>{activityReference}</Typography>
                                    </div>
                                }
                                <Divider
                                    className={classes.divider}
                                    orientation="horizontal"
                                    variant="fullWidth"
                                />
                                {
                                    exchangeRateName && exchangeRateValue &&
                                    <div className={classes.infoRow}>
                                        <Typography variant="body1"
                                                    className={classes.name}>{exchangeRateName}</Typography>
                                        <Typography variant="body1"
                                                    className={classes.value}>{exchangeRateValue}</Typography>
                                    </div>
                                }
                                <div className={classes.infoRow}>
                                    <Typography variant="body1" className={classes.name}>Status</Typography>
                                    <Typography variant="body1" className={classes.value}>{status}</Typography>
                                </div>
                                {blockchainScanLink && <div className={classes.buttonRow}>
                                    <ActionButton
                                        variant={'text'}
                                        onClick={() => window.open(blockchainScanLink, '_blank')}
                                    >
                                        View in explorer
                                    </ActionButton>
                                </div>}
                            </CardContent>
                        </Card>
                        <AdminRole>
                            {transactionType === TRANSACTION_TYPES.PENDING_CREDIT && transactionCategory === TRANSACTION_CATEGORIES.DEPOSIT && resolvedLinkedTransaction &&
                                <div className={classes.buttonContainer}>
                                    <ModalConsumer>
                                        {({showModal}) => (
                                            <ActionButton
                                                onClick={() => showModal(CompleteTransactionDialog, {
                                                    accountId,
                                                    onComplete: refreshActivityList,
                                                    orderId: linkedOrderId,
                                                    amount: linkedAmount,
                                                    currency: linkedCurrency,
                                                    transactionType: TRANSACTION_TYPES.CREDIT,
                                                    transactionCategory: TRANSACTION_CATEGORIES.DEPOSIT,
                                                    selectableCurrencies: ["USD"]
                                                })}
                                                variant='primary'
                                                size='lg'
                                            >
                                                Complete
                                            </ActionButton>
                                        )}
                                    </ModalConsumer>
                                </div>
                            }
                            {transactionType === TRANSACTION_TYPES.PENDING_CREDIT && transactionCategory === TRANSACTION_CATEGORIES.DEPOSIT && resolvedLinkedTransaction && isFiat &&
                                <div style={{ marginTop: "16px"}} className={classes.buttonContainer}>
                                    <ModalConsumer>
                                        {({showModal}) => (
                                            <ActionButton
                                                onClick={() => showModal(RefundCancelTransactionDialog, {
                                                    accountId,
                                                    onComplete: refreshActivityList,
                                                    orderId: linkedOrderId,
                                                    amount: linkedAmount,
                                                    currency: linkedCurrency,
                                                    transactionCategory: TRANSACTION_CATEGORIES.DEPOSIT,
                                                    selectableCurrencies: ["AUD"]
                                                })}
                                                variant='cancel'
                                                size='lg'
                                            >
                                                Cancel
                                            </ActionButton>
                                        )}
                                    </ModalConsumer>
                                </div>
                            }
                        </AdminRole>
                    </>
            }
        </div>
    )
}

const WithdrawActivityDetails = ({
                                     customerId,
                                     activityId,
                                     type,
                                     linkedTransaction,
                                     refreshActivityList,
                                     resource,
                                     isLoading,
                                     parentError,
                                     customer,
                                     onClose,
                                     orderId,
                                     activityReference
                                 }) => {
    const history = useHistory();
    const [data, setData] = useState({})
    const [refresh, setRefresh] = useState(false)
    const [loading, setLoading] = useState(isLoading);
    const [error, setError] = useState(parentError);
    const dataProvider = useDataProvider();
    useEffect(() => {
        setRefresh(true)
    }, [])
    useEffect(() => {
        if (refresh === true) {
            setLoading(true)
            dataProvider.getOne(resource, {customerId, activityId, type, orderId})
                .then(({data}) => {
                    setData(data);
                })
                .catch((error) => {
                    setError(error)
                })
                .finally(() => {
                    setLoading(false)
                    setRefresh(false)
                });
        }
    }, [refresh])
    const {
        categoryName,
        heading,
        status,
        transactionDate,
        reference,
        transferMethodName,
        transferMethodValue,
        exchangeRateName,
        exchangeRateValue,
        blockchainNetwork,
        totalFee,
        sentAmount,
        blockchainScanLink
    } = data
    const resolvedTransaction = linkedTransaction ?? data["linkedTransaction"] ?? {}
    const {
        accountId,
        amount: linkedAmount,
        currency: linkedCurrency,
        orderId: linkedOrderId,
        type: transactionType,
        category: transactionCategory,
    } = resolvedTransaction
    const classes = useDetailStyles();
    return (
        <div className={classes.root}>
            {(error || (!data && !loading)) ?
                <div className={classes.errorContainer}>
                    <GenericError onClick={() => setRefresh(true)} className={classes.error}/>
                </div>
                : loading ?
                    <div className={classes.resultContainer}>
                        <CircularLoader className={classes.loader} trigger={loading} loaderSize='125px'/>
                    </div>
                    :
                    <>
                        <Typography variant='button' className={classes.category}>
                            {categoryName}
                        </Typography>
                        <Typography variant='h2' className={classes.heading}>
                            {heading}
                        </Typography>
                        {customer && <Card className={classes.infoCard}>
                            <CardContent className={classes.infoContent}>
                                <div className={classes.infoRow}>
                                    <Typography variant="body1" className={classes.name}>Customer</Typography>
                                    <Typography variant="body1"
                                                onClick={() => {
                                                    history.push(`/customers/${customer.id}/show`);
                                                    onClose();
                                                }}
                                                className={`${classes.value} ${classes.linkValue}`}>{customer.name}</Typography>
                                </div>
                                <div className={classes.infoRow}>
                                    <Typography variant="body1" className={classes.name}>Customer ID</Typography>
                                    <Typography variant="body1" className={classes.value}>{customer.id}</Typography>
                                </div>
                            </CardContent>
                        </Card>}
                        <Card className={classes.infoCard}>
                            <CardContent className={classes.infoContent}>
                                <div className={classes.infoRow}>
                                    <Typography variant="body1" className={classes.name}>When</Typography>
                                    <Typography variant="body1"
                                                className={classes.value}>{`${formatActivityTimestamp(transactionDate)}`}</Typography>
                                </div>
                                {
                                    transferMethodName && transferMethodValue &&
                                    <div className={classes.infoRow}>
                                        <Typography variant="body1"
                                                    className={classes.name}>{transferMethodName}</Typography>
                                        <Typography variant="body1"
                                                    className={classes.value}>{transferMethodValue}</Typography>
                                    </div>
                                }
                                {
                                    blockchainNetwork &&
                                    <div className={classes.infoRow}>
                                        <Typography variant="body1" className={classes.name}>Network</Typography>
                                        <Typography variant="body1"
                                                    className={classes.value}>{blockchainNetwork}</Typography>
                                    </div>
                                }
                                {
                                    reference &&
                                    <div className={classes.infoRow}>
                                        <Typography variant="body1" className={classes.name}>Reference</Typography>
                                        <Typography variant="body1" className={classes.value}>{reference}</Typography>
                                    </div>
                                }
                                {
                                    activityReference &&
                                    <div className={classes.infoRow}>
                                        <Typography variant="body1" className={classes.name}>OrderID</Typography>
                                        <Typography variant="body1"
                                                    className={classes.value}>{activityReference}</Typography>
                                    </div>
                                }
                                <Divider
                                    className={classes.divider}
                                    orientation="horizontal"
                                    variant="fullWidth"
                                />
                                {
                                    exchangeRateName && exchangeRateValue &&
                                    <div className={classes.infoRow}>
                                        <Typography variant="body1"
                                                    className={classes.name}>{exchangeRateName}</Typography>
                                        <Typography variant="body1"
                                                    className={classes.value}>{exchangeRateValue}</Typography>
                                    </div>
                                }
                                {
                                    sentAmount &&
                                    <div className={classes.infoRow}>
                                        <Typography variant="body1" className={classes.name}>Sent</Typography>
                                        <Typography variant="body1" className={classes.value}>{sentAmount}</Typography>
                                    </div>
                                }
                                {
                                    totalFee &&
                                    <div className={classes.infoRow}>
                                        <Typography variant="body1" className={classes.name}>Fee</Typography>
                                        <Typography variant="body1" className={classes.value}>{totalFee}</Typography>
                                    </div>
                                }
                                <div className={classes.infoRow}>
                                    <Typography variant="body1" className={classes.name}>Status</Typography>
                                    <Typography variant="body1" className={classes.value}>{status}</Typography>
                                </div>
                                {blockchainScanLink && <div className={classes.buttonRow}>
                                    <ActionButton
                                        variant={'text'}
                                        onClick={() => window.open(blockchainScanLink, '_blank')}
                                    >
                                        View in explorer
                                    </ActionButton>
                                </div>}
                            </CardContent>
                        </Card>
                        <AdminRole>
                            {transactionType === TRANSACTION_TYPES.PENDING_DEBIT && transactionCategory === TRANSACTION_CATEGORIES.WITHDRAWAL && resolvedTransaction &&
                                <>
                                    <div className={classes.buttonContainer}>
                                        <ModalConsumer>
                                            {({showModal}) => (
                                                <ActionButton
                                                    onClick={() => showModal(CompleteTransactionDialog, {
                                                        accountId,
                                                        onComplete: refreshActivityList,
                                                        orderId: linkedOrderId,
                                                        amount: linkedAmount,
                                                        currency: linkedCurrency,
                                                        transactionType: TRANSACTION_TYPES.DEBIT,
                                                        transactionCategory: TRANSACTION_CATEGORIES.WITHDRAWAL,
                                                        selectableCurrencies: ["USD"]
                                                    })}
                                                    variant='primary'
                                                    size='lg'
                                                >
                                                    Complete transaction
                                                </ActionButton>
                                            )}
                                        </ModalConsumer>
                                    </div>
                                </>
                            }
                        </AdminRole>
                    </>
            }
        </div>
    )
}

const PaymentActivityDetails = ({
                                    customerId,
                                    activityId,
                                    type,
                                    resource,
                                    activityReference,
                                    isLoading,
                                    parentError,
                                    onClose,
                                    customer
                                }) => {
    const history = useHistory();
    const [data, setData] = useState({})
    const [refresh, setRefresh] = useState(false)
    const [loading, setLoading] = useState(isLoading);
    const [error, setError] = useState(parentError);
    const dataProvider = useDataProvider();
    useEffect(() => {
        setRefresh(true)
    }, [])
    useEffect(() => {
        if (refresh == true) {
            setLoading(true)
            dataProvider.getOne(resource, {customerId, activityId, type})
                .then(({data}) => {
                    setData(data);
                })
                .catch((error) => {
                    setError(error)
                })
                .finally(() => {
                    setLoading(false)
                    setRefresh(false)
                });
        }
    }, [refresh])
    const {
        categoryName,
        heading,
        status,
        transactionDate,
        reference,
        exchangeRateName,
        exchangeRateValue,
        cardLastFourDigits,
        transactionFee
    } = data
    const classes = useDetailStyles();
    return (
        <div className={classes.root}>
            {(error || (!data && !loading)) ?
                <div className={classes.errorContainer}>
                    <GenericError onClick={() => setRefresh(true)} className={classes.error}/>
                </div>
                : loading ?
                    <div className={classes.resultContainer}>
                        <CircularLoader className={classes.loader} trigger={loading} loaderSize='125px'/>
                    </div>
                    :
                    <>
                        <Typography variant='button' className={classes.category}>
                            {categoryName}
                        </Typography>
                        <Typography variant='h2' className={classes.heading}>
                            {heading}
                        </Typography>
                        {customer && <Card className={classes.infoCard}>
                            <CardContent className={classes.infoContent}>
                                <div className={classes.infoRow}>
                                    <Typography variant="body1" className={classes.name}>Customer</Typography>
                                    <Typography variant="body1"
                                                onClick={() => {
                                                    history.push(`/customers/${customer.id}/show`);
                                                    onClose();
                                                }}
                                                className={`${classes.value} ${classes.linkValue}`}>{customer.name}</Typography>
                                </div>
                                <div className={classes.infoRow}>
                                    <Typography variant="body1" className={classes.name}>Customer ID</Typography>
                                    <Typography variant="body1" className={classes.value}>{customer.id}</Typography>
                                </div>
                            </CardContent>
                        </Card>}
                        <Card className={classes.infoCard}>
                            <CardContent className={classes.infoContent}>
                                <div className={classes.infoRow}>
                                    <Typography variant="body1" className={classes.name}>When</Typography>
                                    <Typography variant="body1"
                                                className={classes.value}>{`${formatActivityTimestamp(transactionDate)}`}</Typography>
                                </div>
                                {
                                    cardLastFourDigits &&
                                    <div className={classes.infoRow}>
                                        <Typography variant="body1" className={classes.name}>From Stables
                                            Card</Typography>
                                        <Typography variant="body1"
                                                    className={classes.value}>{cardLastFourDigits}</Typography>
                                    </div>
                                }
                                {
                                    reference &&
                                    <div className={classes.infoRow}>
                                        <Typography variant="body1" className={classes.name}>Reference</Typography>
                                        <Typography variant="body1" className={classes.value}>{reference}</Typography>
                                    </div>
                                }
                                {
                                    activityReference &&
                                    <div className={classes.infoRow}>
                                        <Typography variant="body1" className={classes.name}>OrderID</Typography>
                                        <Typography variant="body1"
                                                    className={classes.value}>{activityReference}</Typography>
                                    </div>
                                }
                                <Divider
                                    className={classes.divider}
                                    orientation="horizontal"
                                    variant="fullWidth"
                                />
                                {
                                    exchangeRateName && exchangeRateValue &&
                                    <div className={classes.infoRow}>
                                        <Typography variant="body1"
                                                    className={classes.name}>{exchangeRateName}</Typography>
                                        <Typography variant="body1"
                                                    className={classes.value}>{exchangeRateValue}</Typography>
                                    </div>
                                }
                                {
                                    transactionFee &&
                                    <div className={classes.infoRow}>
                                        <Typography variant="body1" className={classes.name}>Transaction
                                            Fee</Typography>
                                        <Typography variant="body1"
                                                    className={classes.value}>{transactionFee}</Typography>
                                    </div>
                                }
                                <div className={classes.infoRow}>
                                    <Typography variant="body1" className={classes.name}>Status</Typography>
                                    <Typography variant="body1" className={classes.value}>{status}</Typography>
                                </div>
                            </CardContent>
                        </Card>
                    </>
            }
        </div>
    )
}

const RefundActivityDetails = ({customerId, activityId, type, resource, orderId, activityReference, isLoading, customer, onClose}) => {
    const history = useHistory();
    const [data, setData] = useState({})
    const [refresh, setRefresh] = useState(false)
    const [loading, setLoading] = useState(isLoading);
    const [error, setError] = useState();
    const dataProvider = useDataProvider();
    useEffect(() => {
        setRefresh(true)
    }, [])
    useEffect(() => {
        if (refresh == true) {
            setLoading(true)
            dataProvider.getOne(resource, {customerId, activityId, type, orderId})
                .then(({data}) => {
                    setData(data);
                })
                .catch((error) => {
                    setError(error)
                })
                .finally(() => {
                    setLoading(false)
                    setRefresh(false)
                });
        }
    }, [refresh])
    const {
        categoryName,
        heading,
        status,
        transactionDate,
        reference,
        exchangeRateName,
        exchangeRateValue,
        cardLastFourDigits,
    } = data
    const classes = useDetailStyles();
    return (
        <div className={classes.root}>
            {(error || (!data && !loading)) ?
                <div className={classes.errorContainer}>
                    <GenericError onClick={() => setRefresh(true)} className={classes.error}/>
                </div>
                : loading ?
                    <div className={classes.resultContainer}>
                        <CircularLoader className={classes.loader} trigger={loading} loaderSize='125px'/>
                    </div>
                    :
                    <>
                        <Typography variant='button' className={classes.category}>
                            {categoryName}
                        </Typography>
                        <Typography variant='h2' className={classes.heading}>
                            {heading}
                        </Typography>
                        {customer && <Card className={classes.infoCard}>
                            <CardContent className={classes.infoContent}>
                                <div className={classes.infoRow}>
                                    <Typography variant="body1" className={classes.name}>Customer</Typography>
                                    <Typography variant="body1"
                                                onClick={() => {
                                                    history.push(`/customers/${customer.id}/show`);
                                                    onClose();
                                                }}
                                                className={`${classes.value} ${classes.linkValue}`}>{customer.name}</Typography>
                                </div>
                                <div className={classes.infoRow}>
                                    <Typography variant="body1" className={classes.name}>Customer ID</Typography>
                                    <Typography variant="body1" className={classes.value}>{customer.id}</Typography>
                                </div>
                            </CardContent>
                        </Card>}
                        <Card className={classes.infoCard}>
                            <CardContent className={classes.infoContent}>
                                <div className={classes.infoRow}>
                                    <Typography variant="body1" className={classes.name}>When</Typography>
                                    <Typography variant="body1"
                                                className={classes.value}>{`${formatActivityTimestamp(transactionDate)}`}</Typography>
                                </div>
                                {
                                    cardLastFourDigits &&
                                    <div className={classes.infoRow}>
                                        <Typography variant="body1" className={classes.name}>To Stables
                                            Card</Typography>
                                        <Typography variant="body1"
                                                    className={classes.value}>{cardLastFourDigits}</Typography>
                                    </div>
                                }
                                {
                                    reference &&
                                    <div className={classes.infoRow}>
                                        <Typography variant="body1" className={classes.name}>Reference</Typography>
                                        <Typography variant="body1" className={classes.value}>{reference}</Typography>
                                    </div>
                                }
                                {
                                    activityReference &&
                                    <div className={classes.infoRow}>
                                        <Typography variant="body1" className={classes.name}>OrderID</Typography>
                                        <Typography variant="body1"
                                                    className={classes.value}>{activityReference}</Typography>
                                    </div>
                                }
                                <Divider
                                    className={classes.divider}
                                    orientation="horizontal"
                                    variant="fullWidth"
                                />
                                {
                                    exchangeRateName && exchangeRateValue &&
                                    <div className={classes.infoRow}>
                                        <Typography variant="body1"
                                                    className={classes.name}>{exchangeRateName}</Typography>
                                        <Typography variant="body1"
                                                    className={classes.value}>{exchangeRateValue}</Typography>
                                    </div>
                                }
                                <div className={classes.infoRow}>
                                    <Typography variant="body1" className={classes.name}>Status</Typography>
                                    <Typography variant="body1" className={classes.value}>{status}</Typography>
                                </div>
                            </CardContent>
                        </Card>
                    </>
            }
        </div>
    )
}

const SentActivityDetails = ({
                                 customerId,
                                 activityId,
                                 type,
                                 refreshActivityList,
                                 resource,
                                 activityReference,
                                 isLoading,
                                 parentError,
                                 onClose,
                                 customer
                             }) => {
    const history = useHistory();
    const [data, setData] = useState({})
    const [order, setOrder] = useState({})
    const [refresh, setRefresh] = useState(false)
    const [loading, setLoading] = useState(isLoading);
    const [error, setError] = useState(parentError);
    const dataProvider = useDataProvider();
    useEffect(() => {
        setRefresh(true)
    }, [])
    useEffect(() => {
        if (refresh === true) {
            setLoading(true)
            dataProvider.getOne(resource, {customerId, activityId, type})
                .then(({data}) => {
                    setData(data);
                })
                .catch((error) => {
                    setError(error)
                })
                .finally(() => {
                    setLoading(false)
                    setRefresh(false)
                });
        }
    }, [refresh])
    const {
        categoryName,
        heading,
        status,
        recipientName,
        sentAmount,
        tagline,
        transactionDate,
        transactionFee,
        blockchainNetwork,
        blockchainScanLink,
        reference,
        transferMethodName,
        transferMethodValue,
        exchangeRateName,
        exchangeRateValue,
        totalFee,
        totalCost
    } = data
    const {paymentAdditionalData: {destinationChannelName: destinationChannel} = {}} = order
    const isBank = transferMethodName === "To bank account"
    const isEWallet = transferMethodName === "To e-wallet"
    const isFiat = isBank || isEWallet
    const isPending = status === "Pending"
    useEffect(() => {
        if (reference) {
            setLoading(true)
            dataProvider.getManyReference('orders', {reference})
                .then((orders) => {
                    setOrder(orders.data[0])
                })
                .catch((error) => {
                    setError(error)
                })
                .finally(() => {
                    setLoading(false)
                });
        }
    }, [reference]);
    const {
        accountId,
        id,
        amount,
        currency,
        status: orderStatus
    } = order
    const orderIsPending = orderStatus === "PENDING"
    const retryIcon = <RetryIcon/>
    const classes = useDetailStyles();
    return (
        <div className={classes.root}>
            {(error || (!data && !loading)) ?
                <div className={classes.errorContainer}>
                    <GenericError onClick={() => setRefresh(true)} className={classes.error}/>
                </div>
                : loading ?
                    <div className={classes.resultContainer}>
                        <CircularLoader className={classes.loader} trigger={loading} loaderSize='125px'/>
                    </div>
                    :
                    <>
                        <Typography variant='button' className={classes.category}>
                            {categoryName}
                        </Typography>
                        <Typography variant='h2' className={classes.heading}>
                            {heading}
                        </Typography>
                        <Typography variant='h4' className={classes.heading}>
                            {tagline}
                        </Typography>
                        {customer && <Card className={classes.infoCard}>
                            <CardContent className={classes.infoContent}>
                                <div className={classes.infoRow}>
                                    <Typography variant="body1" className={classes.name}>Customer</Typography>
                                    <Typography variant="body1"
                                                onClick={() => {
                                                    history.push(`/customers/${customer.id}/show`);
                                                    onClose();
                                                }}
                                                className={`${classes.value} ${classes.linkValue}`}>{customer.name}</Typography>
                                </div>
                                <div className={classes.infoRow}>
                                    <Typography variant="body1" className={classes.name}>Customer ID</Typography>
                                    <Typography variant="body1" className={classes.value}>{customer.id}</Typography>
                                </div>
                            </CardContent>
                        </Card>}
                        <Card className={classes.infoCard}>
                            <CardContent className={classes.infoContent}>
                                <div className={classes.infoRow}>
                                    <Typography variant="body1" className={classes.name}>When</Typography>
                                    <Typography variant="body1"
                                                className={classes.value}>{`${formatActivityTimestamp(transactionDate)}`}</Typography>
                                </div>
                                {
                                    isFiat ?
                                        <div className={classes.infoRow}>
                                            <Typography variant="body1"
                                                        className={classes.name}>{transferMethodName}</Typography>
                                            <Typography variant="body1"
                                                        className={classes.value}>{isBank ? `${destinationChannel || "Unknown"}${transferMethodValue ? "  •  " + transferMethodValue : ""}` : transferMethodValue}</Typography>
                                        </div>
                                        : recipientName &&
                                        <div className={classes.infoRow}>
                                            <Typography variant="body1" className={classes.name}>To</Typography>
                                            <Typography variant="body1"
                                                        className={classes.value}>{recipientName}</Typography>
                                        </div>
                                }
                                {
                                    !isFiat && blockchainNetwork &&
                                    <div className={classes.infoRow}>
                                        <Typography variant="body1" className={classes.name}>Network</Typography>
                                        <Typography variant="body1"
                                                    className={classes.value}>{blockchainNetwork}</Typography>
                                    </div>
                                }
                                {
                                    isFiat && reference &&
                                    <div className={classes.infoRow}>
                                        <Typography variant="body1" className={classes.name}>Reference</Typography>
                                        <Typography variant="body1"
                                                    className={classes.value}>{reference}</Typography>
                                    </div>
                                }
                                {
                                    activityReference &&
                                    <div className={classes.infoRow}>
                                        <Typography variant="body1" className={classes.name}>OrderID</Typography>
                                        <Typography variant="body1"
                                                    className={classes.value}>{activityReference}</Typography>
                                    </div>
                                }
                                <Divider
                                    className={classes.divider}
                                    orientation="horizontal"
                                    variant="fullWidth"
                                />
                                {
                                    sentAmount &&
                                    <div className={classes.infoRow}>
                                        <Typography variant="body1"
                                                    className={classes.name}>Sent</Typography>
                                        <Typography variant="body1"
                                                    className={classes.value}>{sentAmount}</Typography>
                                    </div>
                                }
                                {
                                    exchangeRateName && exchangeRateValue &&
                                    <div className={classes.infoRow}>
                                        <Typography variant="body1"
                                                    className={classes.name}>{exchangeRateName}</Typography>
                                        <Typography variant="body1"
                                                    className={classes.value}>{exchangeRateValue}</Typography>
                                    </div>
                                }
                                {
                                    totalFee &&
                                    <div className={classes.infoRow}>
                                        <Typography variant="body1"
                                                    className={classes.name}>Transaction fee</Typography>
                                        <Typography variant="body1"
                                                    className={classes.value}>{totalFee}</Typography>
                                    </div>
                                }
                                {
                                    transactionFee &&
                                    <div className={classes.infoRow}>
                                        <Typography variant="body1"
                                                    className={classes.name}>Transaction fee</Typography>
                                        <Typography variant="body1"
                                                    className={classes.value}>{transactionFee}</Typography>
                                    </div>
                                }
                                {
                                    totalCost &&
                                    <div className={classes.infoRow}>
                                        <Typography variant="body1"
                                                    className={classes.name}>Total cost</Typography>
                                        <Typography variant="body1"
                                                    className={classes.value}>{totalCost}</Typography>
                                    </div>
                                }
                                <div className={classes.statusRow}>
                                    <Typography variant="body1" className={classes.name}>Status</Typography>
                                    {isFiat && order && isPending && orderIsPending &&
                                        <AdminRole>
                                            <ModalConsumer>
                                                { ({ showModal }) => (
                                                    <ActionButton className={classes.retry} style={{marginLeft: "60px"}}
                                                        variant={'text'}
                                                        onClick={ () => showModal(ConfirmTransactionDialog, {
                                                          accountId,
                                                          onComplete: refreshActivityList,
                                                          orderId: id,
                                                          amount: amount,
                                                          currency: currency,
                                                          label: "retry",
                                                          transactionType: TRANSACTION_TYPES.RETRY_SEND_FIAT,
                                                          transactionCategory: TRANSACTION_CATEGORIES.ADJUSTMENT,
                                                          selectableCurrencies: ["USD"]
                                                        }) }
                                                      >
                                                        {retryIcon} Retry
                                                    </ActionButton>
                                                ) }
                                            </ModalConsumer>
                                        </AdminRole>
                                    }
                                    <Typography variant="body1" className={classes.value} style={{marginLeft: "auto"}}>{status}</Typography>
                                </div>

                                {blockchainScanLink && <div className={classes.buttonRow}>
                                    <ActionButton
                                        variant={'text'}
                                        onClick={() => window.open(blockchainScanLink, '_blank')}
                                    >
                                        View in explorer
                                    </ActionButton>
                                </div>}
                            </CardContent>
                        </Card>
                        <AdminRole>
                            {isFiat && order && isPending &&
                                <>
                                    <div className={ classes.buttonContainer }>
                                        <ModalConsumer>
                                            { ({ showModal }) => (
                                                <ActionButton
                                                    onClick={ () => showModal(CompleteTransactionDialog, {
                                                        accountId,
                                                        onComplete: refreshActivityList,
                                                        orderId: id,
                                                        amount: amount,
                                                        currency: currency,
                                                        transactionType: TRANSACTION_TYPES.SEND_FIAT,
                                                        transactionCategory: TRANSACTION_CATEGORIES.ADJUSTMENT,
                                                        selectableCurrencies: ["USD"]
                                                    }) }
                                                    variant='primary'
                                                    size='lg'
                                                >
                                                    Complete
                                                </ActionButton>
                                            ) }
                                        </ModalConsumer>
                                    </div>
                                    { orderIsPending &&
                                    <div style={{ marginTop: "16px"}} className={ classes.buttonContainer }>
                                        <ModalConsumer>
                                            { ({ showModal }) => (
                                                <ActionButton
                                                    onClick={ () => showModal(ConfirmTransactionDialog, {
                                                        accountId,
                                                        onComplete: refreshActivityList,
                                                        amount: amount,
                                                        currency: currency,
                                                        reference: reference,
                                                        label: "cancel",
                                                        transactionType: TRANSACTION_TYPES.CANCEL_SEND_FIAT,
                                                        transactionCategory: TRANSACTION_CATEGORIES.ADJUSTMENT,
                                                        selectableCurrencies: ["USD"]
                                                    }) }
                                                    variant='cancel'
                                                    size='lg'
                                                >
                                                    Cancel
                                                </ActionButton>
                                            ) }
                                        </ModalConsumer>
                                    </div>
                                    }
                                </>
                            }
                        </AdminRole>
                    </>
            }
        </div>
    )
}

const SendReceivedActivityDetails = ({
                                         customerId,
                                         activityId,
                                         type,
                                         resource,
                                         orderId,
                                         activityReference,
                                         isLoading,
                                         parentError,
                                         onClose,
                                         customer
                                     }) => {
    const history = useHistory();
    const [data, setData] = useState({})
    const [refresh, setRefresh] = useState(false)
    const [loading, setLoading] = useState(isLoading);
    const [error, setError] = useState(parentError);
    const dataProvider = useDataProvider();
    useEffect(() => {
        setRefresh(true)
    }, [])
    useEffect(() => {
        if (refresh === true) {
            setLoading(true)
            dataProvider.getOne(resource, { customerId, activityId, type, orderId })
                .then(({ data }) => {
                    setData(data);
                })
                .catch((error) => {
                    setError(error)
                })
                .finally(() => {
                    setLoading(false)
                    setRefresh(false)
                });
        }
    }, [refresh])
    const {
        categoryName,
        heading,
        status,
        senderName,
        sentAmount,
        tagline,
        transactionDate,
        transactionFee,
        blockchainNetwork,
        blockchainScanLink
    } = data
    const classes = useDetailStyles();
    return (
        <div className={ classes.root }>
            { (error || (!data && !loading)) ?
                <div className={ classes.errorContainer }>
                <GenericError onClick={() => setRefresh(true)} className={classes.error}/>
                </div>
                : loading ?
                    <div className={classes.resultContainer}>
                        <CircularLoader className={classes.loader} trigger={loading} loaderSize='125px'/>
                    </div>
                    :
                    <>
                        <Typography variant='button' className={classes.category}>
                            {categoryName}
                        </Typography>
                        <Typography variant='h2' className={classes.heading}>
                            {heading}
                        </Typography>
                        <Typography variant='h4' className={classes.heading}>
                            {tagline}
                        </Typography>
                        {customer && <Card className={classes.infoCard}>
                            <CardContent className={classes.infoContent}>
                                <div className={classes.infoRow}>
                                    <Typography variant="body1" className={classes.name}>Customer</Typography>
                                    <Typography variant="body1"
                                                onClick={() => {
                                                    history.push(`/customers/${customer.id}/show`);
                                                    onClose();
                                                }}
                                                className={`${classes.value} ${classes.linkValue}`}>{customer.name}</Typography>
                                </div>
                                <div className={classes.infoRow}>
                                    <Typography variant="body1" className={classes.name}>Customer ID</Typography>
                                    <Typography variant="body1" className={classes.value}>{customer.id}</Typography>
                                </div>
                            </CardContent>
                        </Card>}
                        <Card className={classes.infoCard}>
                            <CardContent className={classes.infoContent}>
                                <div className={classes.infoRow}>
                                    <Typography variant="body1" className={classes.name}>When</Typography>
                                    <Typography variant="body1"
                                                className={classes.value}>{`${formatActivityTimestamp(transactionDate)}`}</Typography>
                                </div>
                                {
                                    senderName &&
                                    <div className={classes.infoRow}>
                                        <Typography variant="body1" className={classes.name}>From</Typography>
                                        <Typography variant="body1"
                                                    className={classes.value}>{senderName}</Typography>
                                    </div>
                                }
                                {
                                    blockchainNetwork &&
                                    <div className={classes.infoRow}>
                                        <Typography variant="body1" className={classes.name}>Network</Typography>
                                        <Typography variant="body1"
                                                    className={classes.value}>{blockchainNetwork}</Typography>
                                    </div>
                                }
                                {
                                    activityReference &&
                                    <div className={classes.infoRow}>
                                        <Typography variant="body1" className={classes.name}>OrderID</Typography>
                                        <Typography variant="body1" className={classes.value}>{activityReference}</Typography>
                                    </div>
                                }
                                <Divider
                                    className={classes.divider}
                                    orientation="horizontal"
                                    variant="fullWidth"
                                />
                                {
                                    sentAmount &&
                                    <div className={classes.infoRow}>
                                        <Typography variant="body1"
                                                    className={classes.name}>Sent</Typography>
                                        <Typography variant="body1"
                                                    className={classes.value}>{sentAmount}</Typography>
                                    </div>
                                }
                                {
                                    transactionFee &&
                                    <div className={classes.infoRow}>
                                        <Typography variant="body1"
                                                    className={classes.name}>Transaction fee</Typography>
                                        <Typography variant="body1"
                                                    className={classes.value}>{transactionFee}</Typography>
                                    </div>
                                }
                                <div className={classes.infoRow}>
                                    <Typography variant="body1" className={classes.name}>Status</Typography>
                                    <Typography variant="body1" className={classes.value}>{status}</Typography>
                                </div>

                                {blockchainScanLink && <div className={classes.buttonRow}>
                                    <ActionButton
                                        variant={'text'}
                                        onClick={() => window.open(blockchainScanLink, '_blank')}
                                    >
                                        View in explorer
                                    </ActionButton>
                                </div>}
                            </CardContent>
                        </Card>
                    </>
            }
        </div>
    )
}

export default ViewActivityDrawer;
