import {gql} from "@apollo/client";

export const getCardIsEnabledQuery = gql`
    query GetCustomer($customerId: String!) {
        customer: customer_Admin(id: $customerId) {
            id
            profile {
                config {
                    cardsEnabled
                }
            }
        }
    }
`