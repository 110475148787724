import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import ThemeToggle from "../button/themeToggle";

import LogoIcon from "../icons/logoIcon";

const MobileAppBar = ({ children }) => {
  const useStyles = makeStyles(() => ({
    toolbar: {
      height: '100%',
    },
    divider: {
      height: '70%',
      margin: '24px',
    },
    themeSwitch: {
      position: "fixed",
      right: "5px",
    },
    menuHeader: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
    },
    logoIcon: {
        width: "54px",
        height: "24px",
    },
  }));
  const classes = useStyles();
  return (
    <AppBar position="fixed">
      <Toolbar className={classes.toolbar}>
        <div className={classes.menuHeader}>
          <LogoIcon className={classes.logoIcon} />
          <ThemeToggle />
        </div>
        <Divider
          className={classes.divider}
          orientation="vertical"
          variant="fullWidth"
        />
        {children}
      </Toolbar>
    </AppBar>
  );
};

export default MobileAppBar;
