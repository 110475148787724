import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import Brightness7Icon from "@material-ui/icons/Brightness7";
import { useTheme } from "@material-ui/styles";
import { makeStyles } from "@material-ui/core/styles";

import { DashboardThemeContext } from "../../styles/themes/dashboardThemeProvider";

const useStyles = makeStyles((theme) => ({
    themeSwitch: {
        position: "fixed",
        bottom: "0px",
        right: "0px",
        marginLeft: '4px',
    },
}));

const ThemeToggle = () => {
    const theme = useTheme();
    const mode = React.useContext(DashboardThemeContext);
    const classes = useStyles();

    return (
        <IconButton
            className={classes.themeSwitch}
            onClick={mode.toggleColorMode}
            color="inherit"
        >
            {theme.palette.mode === "dark" ? (
            <Brightness7Icon />
            ) : (
            <Brightness4Icon />
            )}
        </IconButton>
    );
};

export default ThemeToggle;