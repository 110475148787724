import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ActionButton from "../button/actionButton";
import GenericError from "../error/genericError";
import SuccessTick from "../success/successTick";
import CircularLoader from "../loading/circularLoader";
import { Typography, Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useCompleteKycFormControls } from "../../utils/formControls/completeKycFormControls";
import TextArea from '../input/textArea';
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles((theme) => ({
    root: {
        top: '50%',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        width: '416px',
        height: '706px',
        borderRadius: '20px',
        background: theme.background.dialog
    },
    cardContent: {
        height: '90%',
        margin: '32px !important',
        padding: '0 !important',
        display: 'flex',
    },
    infoCard: {
        marginTop: '32px'
    },
    error: {
        marginTop: '60%'
    },
    success: {
        height: '95%',
        marginLeft: '24px'
    },
    closeButton: {
        position: 'absolute',
        top: theme.spacing(4),
        right: theme.spacing(3),
    },
    backdrop: {
        ['@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))']: {
            '-webkit-backdrop-filter': 'blur(2em)',
            backdropFilter: 'blur(2em)',
            backgroundColor: 'rgb(0 0 0 / 33%) !important',
        }
    },
    content: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '42px',
    },
    confirmButton: {
        marginBottom: '40px',
        position: 'absolute',
        bottom: 0,
    },
    heading: {
        marginTop: '12px',
        marginBottom: '16px',
        alignSelf: 'start'
    },
    description: {
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '15px',
        lineHeight: '20px',
        letterSpacing: '-0.23px',
        color: '#C6C6C8',
        marginBottom: '16px',
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    selectLabel: {
        marginBottom: '4px'
    },
    resultContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        width: '100%',
        height: '100%',
    },
}));

const CompleteKycDialog = ({ onClose, onComplete, customerId }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [submitError, setSubmitError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [submitRequested, setSubmitRequested] = useState(false);

    const { isLoading: identityLoading, user } = useAuth0();
    const actorEmail = user?.email

    const {
        getValues,
        handleCompleteKycFormSubmit,
        handleTextInputValue,
        formIsValid,
        inputErrors
    } = useCompleteKycFormControls({ customerId, actorEmail });

    const currentValues = getValues();

    const submitCallback = (success) => {
        setLoading(false);
        if (success) {
            setSuccess(true);
            onComplete?.();
        } else {
            setSubmitError(true)
        }
    }

    useEffect(() => {
        if (loading && submitRequested) {
            handleCompleteKycFormSubmit(submitCallback, customerId);
        } else if (!loading && submitRequested) {
        }
    }, [loading, submitRequested]);

    return (
        <Modal open={true} onClose={onClose} BackdropProps={{ className: classes.backdrop }}>
            <Fade in={true}>
                <Card className={classes.root}>
                    <CardContent className={classes.cardContent}>
                        <ActionButton variant="secondary" size="lg"
                            className={classes.closeButton}
                            onClick={onClose}
                        >
                            Close
                        </ActionButton>
                        {submitError ? <div className={classes.resultContainer}> <GenericError onClick={() => { setSubmitError(false); setLoading(true); setSubmitRequested(true); }} /></div> :
                            success ? <div className={classes.resultContainer}><SuccessTick trigger={success} tickSize='175px' /></div> :
                                loading ? <div className={classes.resultContainer}><CircularLoader trigger={loading} loaderSize='125px' /></div> :
                                    <div className={classes.content}>
                                        <Typography variant="h3" className={classes.heading}>Manually complete KYC</Typography>
                                        <Typography variant="h3" className={classes.description}>This action cannot be undone</Typography>
                                        <div className={classes.conversionContainer}>
                                            <TextArea
                                                className={classes.input}
                                                name='additionalContext'
                                                type='text'
                                                value={currentValues.additionalContext}
                                                InputLabelProps={{shrink: false, required: true, disableAnimation: true}}
                                                onChange={handleTextInputValue}
                                                placeholder="Please add a note as to why manual KYC is necessary"
                                                {...(inputErrors['additionalContext'] && { error: true, helperText: inputErrors['additionalContext'] })}
                                            />
                                        </div>
                                        <ActionButton
                                            variant="primary"
                                            size="lg"
                                            className={classes.confirmButton}
                                            disabled={!formIsValid() || identityLoading}
                                            onClick={() => { setLoading(true); setSubmitRequested(true); }}
                                        >
                                            {`Confirm Manual KYC`}
                                        </ActionButton>
                                    </div>}
                    </CardContent>
                </Card>
            </Fade>
        </Modal>
    )
}

export default CompleteKycDialog;