import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '12px'
    },
    button: {
        width: '64px',
        height: '64px',
        backgroundColor: theme.text.primary,        
        border: `1px solid ${theme.text.primary}`,
        boxSizing: 'border-box',
        '&:hover': {
            backgroundColor: theme.text.primary,
            opacity: 0.5
        }
    },
    label: {
        color: theme.text.subtitle,
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.4px',
        textAlign: 'center'
    }
}));

const ActionIconButton = ({label, onClick, icon, ...props}) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <IconButton
                className={classes.button}
                onClick={onClick}
                {...props}
            >
                {icon}
            </IconButton>
            <p className={classes.label}>
                {label}
            </p>
        </div>
    );
};

export default ActionIconButton;
