import {useAuth0} from "@auth0/auth0-react";


export const AuthProvider = () => {

    const { logout, user } = useAuth0()

    return {

        login: () => {
            return Promise.resolve()
        },

        logout: () => {
            logout({ returnTo: window.location.origin })
        },

        checkAuth: () => {
            return Promise.resolve()
        },

        getPermissions: () => {
            return Promise.resolve()
        },

        checkError: () => {
            return Promise.resolve()
        },
    }
}