import React from "react";

function ChevronIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="6"
            fill="none"
            viewBox="0 0 10 6"
        >
            <path
                fill="#EDEDEE"
                fillRule="evenodd"
                d="M.47.47a.75.75 0 011.06 0L5 3.94 8.47.47a.75.75 0 111.06 1.06l-4 4a.75.75 0 01-1.06 0l-4-4a.75.75 0 010-1.06z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default ChevronIcon;
