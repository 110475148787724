import { client } from "../data/graphql/client";
import { formatApolloError } from "../data/graphql/error";
import { depositMutation, withdrawalMutation } from "./graphql/yield/mutations";

export const yieldDataProvider = {

    getList: async (resource, params) => {

    },

    getOne: async (resource, params) => {

    },

    create: async (resource, params) => {
        try {
            const mutationResult = await client.mutate({
                mutation: depositMutation,
                variables: { 
                    input: {
                        accountId: params.accountId,
                        amount: params.amount,
                        orderId: params.orderId
                    }
                }
            })
            await client.clearStore();
    
            const result = mutationResult.data.deposit

            const data = {
                id: params.id,
                status: result.status,
                fee: result.fee
            }
            return {
                data: data
            }
        } catch (e) {
            return Promise.resolve({
                data: {
                    id: -1,
                    error: formatApolloError(e)
                }
            });
        }
    },

    delete: async (resource, params) => {
        try {
            const mutationResult = await client.mutate({
                mutation: withdrawalMutation,
                variables: { 
                    input: {
                        accountId: params.accountId,
                        amount: params.amount,
                        orderId: params.orderId
                    }
                }
            });
            await client.clearStore();

            const result = mutationResult.data.withdrawal
    
            const data = {
                id: params.id,
                status: result.status,
                fee: result.fee
            }
    
            return {
                data: data
            }
        } catch (e) {
            return Promise.resolve({
                data: {
                    id: -1,
                    error: formatApolloError(e)
                }
            });
        }
    }
}