import LabelChip from "../chip/labelChip";
import {ACTIVITY_TYPE_FILTERS, ACTIVITY_TYPES} from "../../utils/types";
import {formatShortTimestamp} from "../../utils/formatter";
import {filterByActivityType, sortActivitiesByDate} from "../../utils/filters";
import Datagrid from "../table/Datagrid";
import {makeStyles, Typography} from "@material-ui/core";
import ViewActivityDrawer from "../drawer/viewActivityDrawer";
import DepositActivityIcon from "../icons/depositActivityIcon";
import PaymentActivityIcon from "../icons/paymentActivityIcon";
import RefundActivityIcon from "../icons/refundActivityIcon";
import WithdrawActivityIcon from "../icons/withdrawActivityIcon";
import ModalConsumer from "../consumer/modalConsumer";
import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import SendIcon from "../icons/sendIcon";
import {useDataProvider} from "react-admin";

const TRANSACTIONS_PER_PAGE = 5;
const PAGES_TO_FETCH = 3;

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: '24px',
    },
    descriptionRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        height: '40px'
    },
    descriptionColumn: {
        paddingLeft: '16px',
        '& > p': {
            paddingBottom: '3px'
        }
    },
    descriptionIcon: {
        width: '100%',
    },
}));

export const ActivityDescription = (classes, type, heading, subHeading, timestamp) => {
    let icon
    switch (type) {
        case ACTIVITY_TYPES.WITHDRAWAL:
            icon = <WithdrawActivityIcon/>
            break
        case ACTIVITY_TYPES.REFUND:
            icon = <RefundActivityIcon/>
            break
        case ACTIVITY_TYPES.PAYMENT:
            icon = <PaymentActivityIcon className={classes.descriptionIcon}/>
            break
        case ACTIVITY_TYPES.SENT:
            icon = <SendIcon/>
            break
        case ACTIVITY_TYPES.RECEIVED:
        case ACTIVITY_TYPES.DEPOSIT:
        default:
            icon = <DepositActivityIcon/>
    }
    return (
        <div className={classes.descriptionRow}>
            {icon}
            <div className={classes.descriptionColumn}>
                <Typography variant="body1">{heading}</Typography>
                <Typography
                    variant="subtitle1">{`${formatShortTimestamp(timestamp)}${subHeading ? `• ${subHeading}` : ""}`}</Typography>
            </div>
        </div>
    )
}

const ActivityList = forwardRef(({customerId, accountId}, ref) => {
    const classes = useStyles();
    const [transactions, setTransactions] = useState([]);
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [refresh, setRefresh] = useState(true);
    useEffect(() => {
        if (refresh) {
            setLoading(true)
            dataProvider.getList("transactions", {accountId})
                .then(({data, transactions}) => {
                    setTransactions(data);
                    setError(null)
                })
                .catch((error) => {
                    setError(error);
                })
                .finally(() => {
                    setLoading(false);
                    setRefresh(false);
                });
        }
    }, [refresh]);
    const columns = [
        {
            field: 'heading',
            headerName: 'Activity',
            sortable: 'false',
            renderCell: ({
                             row: {
                                 type,
                                 feedInfo: {heading, subHeading},
                                 timestamp
                             }
                         }) => (ActivityDescription(classes, type, heading, subHeading, timestamp)),
            renderHeader: ({colDef: {headerName}}) => (<Typography variant="subtitle1">{headerName}</Typography>),
            flex: 0.6,
        },
        {
            field: 'type',
            headerName: 'Status',
            sortable: 'false',
            renderCell: ({row: {feedInfo: {sideSubText: status}}}) => (status?.length > 0 ?
                <LabelChip label={status} variant={status}/> : <LabelChip label={"Complete"} variant={"Completed"}/>),
            renderHeader: ({colDef: {headerName}}) => (<Typography variant="subtitle1">{headerName}</Typography>),
            flex: 0.25,
        },
        {
            field: 'amount',
            headerName: 'Amount',
            sortable: 'false',
            renderCell: ({row: {feedInfo: {sideText: amount}}}) => (<Typography variant="body1">{amount}</Typography>),
            renderHeader: ({colDef: {headerName}}) => (<Typography variant="subtitle1">{headerName}</Typography>),
            flex: 0.15,
        },
    ];
    const datagrid = useRef(null);
    useImperativeHandle(ref, () => ({
        refresh: () => {
            datagrid?.current?.refresh();
            setRefresh(true);
        }
    }));
    return (
        <ModalConsumer>
            {({showModal}) => (
                <Datagrid
                    className={classes.root}
                    searchEnabled
                    parentError={error}
                    parentLoading={loading}
                    ref={datagrid}
                    parentRef={ref}
                    params={{customerId, accountId, transactions}}
                    columns={columns}
                    resource="activities"
                    paginationEnabled
                    perPage={TRANSACTIONS_PER_PAGE}
                    preFetch={PAGES_TO_FETCH}
                    transforms={[sortActivitiesByDate]}
                    categoryFilter={filterByActivityType}
                    filterCategories={[
                        {
                            key: "DEPOSIT",
                            label: ACTIVITY_TYPE_FILTERS.DEPOSIT
                        },
                        {
                            key: "WITHDRAWAL",
                            label: ACTIVITY_TYPE_FILTERS.WITHDRAWAL
                        },
                        {
                            key: "PAYMENT",
                            label: ACTIVITY_TYPE_FILTERS.PAYMENT
                        },
                        {
                            key: "REFUND",
                            label: ACTIVITY_TYPE_FILTERS.REFUND
                        },
                        {
                            key: "SENT",
                            label: ACTIVITY_TYPE_FILTERS.SENT
                        },
                        {
                            key: "RECEIVED",
                            label: ACTIVITY_TYPE_FILTERS.RECEIVED
                        }
                    ]}
                    onRowClick={(params) => showModal(ViewActivityDrawer, {
                        params,
                        customerId,
                        refreshActivityList: () => datagrid?.current?.refresh(),
                    })}
                    rowHeight={40}
                    headerHeight={32}
                    cellHeight={40}
                    cardHeight={36.5}
                />
            )}
        </ModalConsumer>
    );
});

export default ActivityList;
