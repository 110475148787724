/* eslint-disable no-useless-computed-key */
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Divider } from "@material-ui/core";
import BackIcon from "../icons/backIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    display: "flex",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    "& > svg": {
      minWidth: "36px",
    },
  },
  innerColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginLeft: "16px",
    width: "-webkit-fill-available"
  },
  detailRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "8px"
  },
  description: {
    fontFamily: "DM Sans",
    fontWeight: "400",
    color: "#77777B",
    maxWidth: "80%",
  },
  divider: {
    marginBottom: "24px",
    marginTop: "24px",
    width: "109%",
  },
  actionArrow: {
    width: "24px",
    height: "24px",
    "-webkit-transform": "scaleX(-1)",
    transform: "scaleX(-1)",
    paddingRight: "25px",
    "&:hover": {
        opacity: 0.75,
        cursor: "pointer",
    }
  },
}));

const ActionsRowItem = ({ heading, description, onClick, icon }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {icon}
        <div className={classes.innerColumn}>
          <div className={classes.detailRow}>
            <Typography variant="h5">
              {heading}
            </Typography>
            <BackIcon className={classes.actionArrow} onClick={onClick}/>
          </div>
          <Typography variant="button" className={classes.description}>
              {description}
            </Typography>
        </div>
      </div>
      <Divider
        className={classes.divider}
        orientation="horizontal"
        variant="fullWidth"
      />
    </div>
  );
};

export default ActionsRowItem;
