import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import { withRouter } from 'react-router'
import { makeStyles } from '@material-ui/core'
import BackIcon from '../icons/backIcon'

const useStyles = makeStyles(() => ({
    root: {
        padding: 0,
        display: 'flex',
        justifyContent: 'flex-start'
    }
}));

const BackButton = ({ history: { goBack }, staticContext, ...props }) => {
    const classes = useStyles();
    return (
        <IconButton className={classes.root} {...props} onClick={goBack}>
            <BackIcon/>
        </IconButton>
    )
}

export default withRouter(BackButton);