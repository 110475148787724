import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from "@material-ui/core/styles";

import ActionButton from '../button/actionButton';
import { InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import TextField from '../input/textField';
import SuccessTick from '../success/successTick';
import CircularLoader from '../loading/circularLoader';
import GenericError from '../error/genericError';
import { useTransactionFormControls } from '../../utils/formControls/transactionFormControls';

const useStyles = makeStyles((theme) => ({
    root: {
        top: '50%',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        width: '416px',
        height: '706px',
        borderRadius: '20px',
        background: theme.background.dialog
    },
    cardContent: {
        height: '90%',
        margin: '32px !important',
        padding: '0 !important',
        display: 'flex',
        justifyContent: 'center',
    },
    error: {
        marginTop: '60%'
    },
    success: {
        height: '95%',
        marginLeft: '24px'
    },
    closeButton: {
        position: 'absolute',
        top: theme.spacing(4),
        right: theme.spacing(3),
    },
    backdrop: {
        ['@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))']: {
            '-webkit-backdrop-filter': 'blur(2em)',
            backdropFilter: 'blur(2em)',
            backgroundColor: 'rgb(0 0 0 / 33%) !important',
        }
    },
    content: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '42px',
    },
    confirmButton: {
        marginBottom: '40px',
        position: 'absolute',
        bottom: 0,
    },
    heading: {
        marginTop: '12px',
        alignSelf: 'start'
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        margin: '32px 0px 32px 0px'
    },
    input: {
        margin: '32px 0px 12px 0px !important',
    },
    selectLabel: {
        marginBottom: '4px'
    },
    resultContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        height: '100%',
    },
}));

const PendingTransactionDialog = ({ onClose, onComplete, transactionType, transactionCategory, accountId, selectableCurrencies }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [success, setSuccess] = useState(false);
    const [submitRequested, setSubmitRequested] = useState(false);
    const currencies = selectableCurrencies === undefined || selectableCurrencies.length === 0 ? ["AUD", "USD"] : [...selectableCurrencies]
    const {
        getValues,
        handleTextInputValue,
        handleCreateTransactionFormSubmit,
        formIsValid,
        inputErrors
    } = useTransactionFormControls({ accountId });

    const currentValues = getValues();

    const submitCallback = (success) => {
        setLoading(false);
        if (success) {
            setSuccess(true);
            setError(false);
            onComplete();
        } else {
            setError(true)
        }
    }

    useEffect(() => {
        if (loading && submitRequested) {
            handleCreateTransactionFormSubmit(submitCallback, transactionType);
        }
    }, [submitRequested]);

    return (
        <Modal open={true} onClose={onClose} BackdropProps={{className:classes.backdrop}}>
            <Fade in={true}>
                <Card className={classes.root}>
                    <CardContent className={classes.cardContent}>
                        <ActionButton variant="secondary" size="lg"
                            className={classes.closeButton}
                            onClick={onClose}
                        >
                            Close
                        </ActionButton>
                        {
                            error ? <div className={classes.resultContainer}>
                                        <GenericError onClick={() => { setError(false); setLoading(true); handleCreateTransactionFormSubmit(submitCallback, transactionType);}}/>
                                    </div> :
                            success ? <div className={classes.resultContainer}><SuccessTick trigger={success} tickSize='175px'/></div> :
                            loading ? <div className={classes.resultContainer}><CircularLoader trigger={loading} loaderSize='125px'/></div> :
                            <div className={classes.content}>
                                <Typography variant="h3" className={classes.heading}>{`Create new pending ${transactionCategory.toLowerCase()}:`}</Typography>
                                <div className={classes.inputContainer}>
                                    <InputLabel htmlFor='currency-select' className={classes.selectLabel}>
                                        Currency
                                    </InputLabel>
                                    <Select
                                        className={classes.select}
                                        id='currency-select'
                                        label='Currency'
                                        name='currency'
                                        value={currentValues['currency']}
                                        onChange={handleTextInputValue}
                                    >
                                        {currencies.map(currency => <MenuItem value={currency}>{currency}</MenuItem>)}
                                    </Select>
                                    <TextField
                                        className={classes.input}
                                        name='depositAmount'
                                        type='text'
                                        value={currentValues.depositAmount}
                                        label={`Amount ${currentValues?.currency}`}
                                        InputLabelProps={{shrink: false, required: true, disableAnimation: true}}
                                        onChange={handleTextInputValue}
                                        {...(inputErrors['depositAmount'] && { error: true, helperText: inputErrors['depositAmount'] })}
                                    />
                                </div>
                                <ActionButton
                                    variant="primary"
                                    size="lg"
                                    className={classes.confirmButton}
                                    disabled={!formIsValid()}
                                    onClick={() => { setLoading(true); setSubmitRequested(true);}}
                                >
                                    {`Create ${transactionCategory.toLowerCase()}`}
                                </ActionButton>
                            </div>
                        }
                    </CardContent>
                </Card>
            </Fade>
        </Modal>
    )
}

export default PendingTransactionDialog;