/* eslint-disable no-useless-computed-key */
import { Notification } from "react-admin";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import DashboardNavBar from "../navbar/dashboardNavbar";
import DashboardMenu from "../menu/dashboardMenu";

const DashboardLayout = ({ children }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      zIndex: 1,
      backgroundColor: theme.palette.background.primary,
      position: "relative", 
    },
    appFrame: {
      display: "flex",
      flexDirection: "column",
      overflowX: "auto",
      overflowY: "clip",
      minHeight: "100vh",
    },
    contentWithSidebar: {
      display: "flex",
      flexGrow: 1,
    },
    content: {
      maxWidth: '100%',
      display: "flex",
      flexDirection: "column",
      flexGrow: 2,
      margin: theme.spacing(4),
      marginTop: '60px',
      ['@media (min-width:600px)']: {
        marginTop: '24px',
      },
    },
    contentMoz: {
      maxWidth: '-moz-available',
    },
    contentChrome: {
      maxWidth: '-webkit-fill-available',
    },
    contentDefault: {
      maxWidth: 'fill-available',
    },
    profileContainer: {
      height: 'fit-content',
      width: 'fit-content',
      position: 'absolute',
      top: '36px',
      right: '32px'
    }
  }));
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.appFrame}>
        <main className={classes.contentWithSidebar}>
            <DashboardNavBar>
                <DashboardMenu/>
            </DashboardNavBar>
          <div className={`${classes.content} ${classes.contentDefault} ${classes.contentChrome} ${classes.contentMoz}`}>
            {children}
          </div>
        </main>
        <Notification />
      </div>
    </div>
  );
};

export default DashboardLayout;
