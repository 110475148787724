import {ListBase, Title,} from "react-admin";
import {Divider, Grid, MenuItem, Typography} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import {useCallback, useRef, useState} from "react";
import SearchField from "../input/searchField";
import LabelChip from "../chip/labelChip";
import ViewActivityDrawer from "../drawer/viewActivityDrawer";
import ModalConsumer from "../consumer/modalConsumer";
import {
    DEFAULT_GLOBAL_ACTIVITIES_PAGE_SIZE,
    DEFAULT_GLOBAL_ACTIVITIES_PAGES_TO_FETCH
} from "../../utils/data/globalActivitiesDataProvider";
import {formatActivityTimestamp} from "../../utils/formatter";
import {
    ACTIVITIES_VIEW_MODE,
    ACTIVITY_STATUS_FILTERS,
    ACTIVITY_TYPE_FILTERS,
    ACTIVITY_TYPES,
    activityStatusFilterValue,
    activityTypeFilterValue,
    activityViewModeValue
} from "../../utils/types";
import WithdrawActivityIcon from "../icons/withdrawActivityIcon";
import RefundActivityIcon from "../icons/refundActivityIcon";
import PaymentActivityIcon from "../icons/paymentActivityIcon";
import SendIcon from "../icons/sendIcon";
import DepositActivityIcon from "../icons/depositActivityIcon";
import _ from 'lodash'
import ScrollableDatagrid from "../table/ScrollableDatagrid";
import Selector from "../select/Selector";
import ActionButton from "../button/actionButton";

const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: '32px'
    },
    divider: {
        marginBottom: "28px",
        width: "109%",
        marginLeft: "-24px"
    },
    viewModeRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        '&> :not(:last-child)': {
            marginRight: '32px'
        }
    },
    searchRow: {
        paddingTop: "36px",
        paddingBottom: "48px",
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    filterRow: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'start',
        '&> :not(:last-child)': {
            marginRight: '16px'
        }
    },
    detailRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        alignItems: 'flex-start',
        paddingTop: '2px',
    },
    detailsColumn: {
        paddingLeft: '16px',
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
    },
    detailsDate: {
        paddingTop: '2px',
        paddingBottom: '2px',
        color: "#ACACAE",
        fontWeight: 400,
        letterSpacing: '-0.3100000023841858px',
    },
    gridContainer: {
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(3),
        },
        height: '100%',
    },
    tableCard: {
        minHeight: '96%',
        padding: theme.spacing(3),
        paddingTop: theme.spacing(2),
        backgroundColor: theme.surface.secondary,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    }
}));

const GlobalActivityList = (props) => {
    const classes = useStyles();
    const [activityTypeFilter, setActivityTypeFilter] = useState();
    const [activityStatusFilter, setActivityStatusFilter] = useState();
    const datagrid = useRef(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    const columns = [
        {
            field: 'type',
            headerName: 'Transaction type',
            sortable: 'false',
            renderCell: ({
                             row
                         }) => {
                const {
                    type,
                    feedInfo: {heading, subHeading},
                    timestamp
                } = row;
                let icon
                let subtype = ""
                switch (type) {
                    case ACTIVITY_TYPES.WITHDRAWAL:
                        icon = <WithdrawActivityIcon/>
                        break
                    case ACTIVITY_TYPES.REFUND:
                        icon = <RefundActivityIcon/>
                        break
                    case ACTIVITY_TYPES.PAYMENT:
                        icon = <PaymentActivityIcon className={classes.descriptionIcon}/>
                        subtype = "Payment"
                        break
                    case ACTIVITY_TYPES.SENT:
                        icon = <SendIcon/>
                        subtype = "Sent"
                        break
                    case ACTIVITY_TYPES.RECEIVED:
                        subtype = "Received"
                    case ACTIVITY_TYPES.DEPOSIT:
                    default:
                        icon = <DepositActivityIcon/>
                }
                return (
                    <div className={classes.detailRow}>
                        {icon}
                        <div className={classes.detailsColumn}>
                            <Typography
                                variant="button">{`${heading}${subtype !== "" ? `  •  ${subtype}` : ""}`}</Typography>
                            <Typography variant="button"
                                        className={classes.detailsDate}>{`${formatActivityTimestamp(timestamp)}`}</Typography>
                        </div>
                    </div>
                )
            },
            renderHeader: ({colDef: {headerName}}) => (<Typography variant="subtitle1">{headerName}</Typography>),
            flex: 0.2,
        },
        {
            field: 'amount',
            headerName: 'Amount',
            sortable: 'false',
            renderCell: ({
                             row: {
                                 feedInfo: {sideText},
                             }
                         }) => (<Typography variant="body1">{`${sideText}`}</Typography>),
            renderHeader: ({colDef: {headerName}}) => (<Typography variant="subtitle1">{headerName}</Typography>),
            flex: 0.10,
        },
        {
            field: 'status',
            headerName: 'Status',
            sortable: 'false',
            renderCell: ({row: {feedInfo: {sideSubText: status}}}) => (status?.length > 0 ?
                <LabelChip label={status} variant={status}/> : <LabelChip label={"Complete"} variant={"Completed"}/>),
            renderHeader: ({colDef: {headerName}}) => (<Typography variant="subtitle1">{headerName}</Typography>),
            flex: 0.1,
        },
        {
            field: 'customerId',
            headerName: 'CustomerID',
            sortable: 'false',
            renderCell: ({row: {userId}}) => (<Typography variant="body1">{userId}</Typography>),
            renderHeader: ({colDef: {headerName}}) => (<Typography variant="subtitle1">{headerName}</Typography>),
            flex: 0.3,
        },
        {
            field: 'orderId',
            headerName: 'OrderID',
            sortable: 'false',
            renderCell: ({row: {reference}}) => (<Typography variant="body1">{reference}</Typography>),
            renderHeader: ({colDef: {headerName}}) => (<Typography variant="subtitle1">{headerName}</Typography>),
            flex: 0.3,
        }
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const queryDebouncer = useCallback(_.debounce((value) => {
        setDebouncedSearchTerm(value)
    }, 500), []);
    const [viewMode, setViewMode] = useState("ALL");
    return (
        <>
            <Title title='Transactions'/>
            <ListBase {...props} perPage={8}>
                <Grid container spacing={3} className={classes.gridContainer}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <ModalConsumer>
                            {({showModal}) => (
                                <ScrollableDatagrid
                                    params={{
                                        searchTerm: debouncedSearchTerm,
                                        activityStatus: viewMode !== "NEEDS_ACTION" ? activityStatusFilter : "PENDING",
                                        activityType: activityTypeFilter
                                    }}
                                    ref={datagrid}
                                    columns={columns}
                                    resource="globalActivities"
                                    perPage={DEFAULT_GLOBAL_ACTIVITIES_PAGE_SIZE}
                                    preFetch={DEFAULT_GLOBAL_ACTIVITIES_PAGES_TO_FETCH}
                                    paginationEnabled
                                    onRowClick={(params) => showModal(ViewActivityDrawer, {
                                        params,
                                        activityReference: params.row['reference'],
                                        customerId: params.row["userId"],
                                        resource: "globalActivities",
                                        refreshActivityList: () => datagrid?.current?.refresh(),
                                    })} rowHeight={44}
                                    cellHeight={42}
                                    headerHeight={32}
                                    cardHeight={62}
                                >
                                    <Typography variant="h5" className={classes.title}>Transactions</Typography>
                                    <Divider
                                        className={classes.divider}
                                        orientation="horizontal"
                                        variant="fullWidth"
                                    />
                                    <div className={classes.viewModeRow}>
                                        {
                                            Object.entries(ACTIVITIES_VIEW_MODE).map(([key, value]) => <ActionButton
                                                    key={key}
                                                    variant="text"
                                                    onClick={() => {
                                                        setViewMode(activityViewModeValue(value))
                                                    }}
                                                    selected={viewMode === activityViewModeValue(value)}
                                                    label={value}
                                                    size="md"
                                                >
                                                    {value}
                                                </ActionButton>
                                            )
                                        }
                                    </div>
                                    <div className={classes.searchRow}>
                                        <div className={classes.filterRow}>
                                            <Selector
                                                selected={ACTIVITY_TYPE_FILTERS[activityTypeFilter]}
                                                onChange={(e) => {
                                                    setActivityTypeFilter(activityTypeFilterValue(e.target.value))
                                                }}
                                                valueLookup={(value) => activityTypeFilterValue(value)}
                                                label="Type of transaction"
                                            >
                                                {Object.entries(ACTIVITY_TYPE_FILTERS).map(([key, value]) =>
                                                    (<MenuItem key={key} value={value}>{value}</MenuItem>)
                                                )}
                                            </Selector>
                                            {viewMode !== "NEEDS_ACTION" && <Selector
                                                selected={ACTIVITY_STATUS_FILTERS[activityStatusFilter]}
                                                onChange={(e) => {
                                                    setActivityStatusFilter(activityStatusFilterValue(e.target.value))
                                                }}
                                                valueLookup={(value) => activityStatusFilterValue(value)}
                                                label="Transaction status"
                                            >
                                                {Object.entries(ACTIVITY_STATUS_FILTERS).map(([key, value]) =>
                                                    (<MenuItem key={key} value={value}>{value}</MenuItem>)
                                                )}
                                            </Selector>}
                                        </div>
                                        <SearchField
                                            value={searchTerm}
                                            placeholder="Search by orderID"
                                            onChange={
                                                ({target: {value}}) => {
                                                    setSearchTerm(value)
                                                    queryDebouncer(value);
                                                }
                                            }
                                        />
                                    </div>
                                </ScrollableDatagrid>
                            )}
                        </ModalConsumer>
                    </Grid>
                </Grid>
            </ListBase>
        </>
    );
}

export default GlobalActivityList;