import {makeStyles} from "@material-ui/core/styles";
import Pagination from "../pagination/pagination";
import NoResults from "../error/noResults";
import GenericError from "../error/genericError";
import {Card, CardContent, Divider} from "@material-ui/core";
import {DataGrid} from '@mui/x-data-grid';
import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import CircularLoader from "../loading/circularLoader";
import InlineSearchField from "../input/inlineSearchField";
import FilterChip from "../chip/filterChip";
import {initializeArray} from "../../utils/common";
import {useDataProvider} from "react-admin";

const Datagrid = forwardRef(({
                                 className,
                                 children,
                                 resource,
                                 params = {},
                                 paginationEnabled = true,
                                 categoryFilter,
                                 filterCategories = [],
                                 searchEnabled = false,
                                 columns,
                                 transforms,
                                 perPage,
                                 preFetch,
                                 onRowClick,
                                 rowInnerPadding = 24,
                                 rowHeight = 24,
                                 headerHeight = 32,
                                 cellHeight = 24,
                                 cardHeight = 36,
                                 parentLoading = false,
                                 parentError = undefined,
                                 parentRef = undefined,
                             }, ref) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            backgroundColor: theme.surface.primary,
        },
        divider: {
            height: '100%',
            marginLeft: '16px',
            marginRight: '16px',
            color: '#FAFAFA',
            opacity: 0.5
        },
        searchRow: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            height: '40px',
            marginBottom: '28px',
        },
        content: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: '24px 0px 24px 24px',
            minHeight: `${cardHeight}em`,
            maxHeight: `${cardHeight}em`,
        },
        errorContainer: {
            width: '100%',
            height: '100%',
            display: 'flex',
        },
        error: {
            display: 'flex',
            margin: 'auto'
        },
        emptyContainer: {
            width: '100%',
            marginTop: searchEnabled || filterCategories.length > 0 ? '-94px' : '-146px',
            minHeight: `${cardHeight - 4.5}em`,
            display: 'flex',
        },
        empty: {
            display: 'flex',
            margin: 'auto'
        },
        loader: {
            marginLeft: '-24px',
        },
        resultContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            height: '100%',
        },
    }));
    const gridStyles = makeStyles((theme) => ({
        root: {
            border: 'none !important',
            height: '96% !important',
            '& .MuiDataGrid-row': {
                borderTopLeftRadius: '4px',
                padding: `${rowInnerPadding}px 0px ${rowInnerPadding}px 0px`
            },
            '& .MuiIconButton-root': {
                color: theme.text.primary
            },
            '& .MuiDataGrid-row::after': {
                content: '""',
                display: 'inline-block',
                cursor: 'pointer',
                width: '100%',
                height: `${rowInnerPadding * 2 + 16}px`,
                borderBottom: '1px solid #D6D6DF',
                opacity: 0.23,
                position: 'absolute'
            },
            '& .MuiDataGrid-row:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.1) !important',
            },
            '& .MuiDataGrid-cell': {
                borderBottom: 'none !important',
                padding: '0px !important',
                minHeight: `${cellHeight}px !important`,
                maxHeight: `${cellHeight}px !important`,
            },
            '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-colCell:focus-within,  & .MuiDataGrid-columnHeader:focus-within': {
                outline: 0,
            },
            '& .MuiDataGrid-columnHeaders': {
                borderBottom: 'none !important',
            },
            '& .MuiDataGrid-columnHeader': {
                padding: '0 !important',
            },
            '& .MuiDataGrid-footerContainer': {
                display: 'none !important',
            },
            '& .MuiDataGrid-columnSeparator': {
                display: 'none !important',
            },
        },
    }));

    const classes = useStyles();
    const gridClasses = gridStyles();
    const [currentPage, setCurrentPage] = useState(0);
    const [dataStore, setDataStore] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(parentError);
    const [refresh, setRefresh] = useState(false);
    const [refetch, setRefetch] = useState(false);
    const [visitedPages, setVisitedPages] = useState([])
    const [searchTerm, setSearchTerm] = useState('');
    const [filterChipsSelected, setFilterChipsSelected] = useState(initializeArray(filterCategories.length, false))
    const dataProvider = useDataProvider();

    useImperativeHandle(ref, () => ({
        refresh: () => {
            setRefresh(true);
        }
    }));

    const filterByCategory = (newTransformedData) => {
        if (categoryFilter) {
            let selectedFilterCategoryKeys = []
            let allCategorykeys = filterCategories.map(category => category.key)
            for (var i = 0; i < filterCategories.length; i++) {
                if (filterChipsSelected[i] === true) {
                    selectedFilterCategoryKeys.push(filterCategories[i].key)
                }
            }
            const filteredItems = categoryFilter(newTransformedData, selectedFilterCategoryKeys, allCategorykeys)
            const dedupedItems = filteredItems.reduce((accumulator, current) => {
                let exists = accumulator.find(item => {
                    return item.id === current.id;
                });
                if (!exists) {
                    accumulator = accumulator.concat(current);
                }
                return accumulator;
            }, []);
            const filteredDataChanged = filteredData.find((item, index) => dedupedItems[index] !== item) !== undefined
            if (filteredDataChanged) {
                setCurrentPage(0)
                setVisitedPages([])
            }
            setFilteredData(dedupedItems)
        } else {
            const dedupedItems = newTransformedData.reduce((accumulator, current) => {
                let exists = accumulator.find(item => {
                    return item.id === current.id;
                });
                if (!exists) {
                    accumulator = accumulator.concat(current);
                }
                return accumulator;
            }, []);
            setFilteredData(dedupedItems)
        }
    }

    const applyTransforms = () => {
        let newTransformedData = dataStore;
        if (transforms?.length > 0) {
            for (var i = 0; i < transforms.length; i++) {
                newTransformedData = transforms[i](newTransformedData)
            }
        }
        filterByCategory(newTransformedData)
    }

    useEffect(() => {
        applyTransforms()
    }, [dataStore]);

    useEffect(() => {
        if (!parentError && !parentLoading) {
            const unifiedSearchTerm = searchTerm || params.searchTerm
            if (unifiedSearchTerm !== undefined && unifiedSearchTerm !== "") {
                setCurrentPage(0)
                setVisitedPages([])
            }
            if (dataStore.length === 0 || (unifiedSearchTerm !== undefined && unifiedSearchTerm !== "")) {
                const pagination = {
                    first: perPage * preFetch,
                    offset: currentPage * perPage
                }
                dataProvider.getList(resource, {
                    ...params,
                    unifiedSearchTerm,
                    pagination: pagination,
                    prevActivities: dataStore
                })
                    .then(({data}) => {
                        setDataStore(data);
                        setError(null)
                    })
                    .catch((error) => {
                        setError(error);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    }, [searchTerm, params, parentError, parentLoading]);


    useEffect(() => {
        if (refresh && parentError) {
            parentRef?.current?.refresh()
        }
        if (refresh) {
            const first = perPage * preFetch;
            const offset = currentPage * perPage;
            setLoading(true);
            setTimeout(() => {
                dataProvider.getList(resource, {
                    ...params,
                    searchTerm,
                    pagination: {first, offset},
                    prevActivities: dataStore
                })
                    .then(({data}) => {
                        setDataStore(data);
                        setError(null)
                    })
                    .catch((error) => {
                        setError(error);
                    })
                    .finally(() => {
                        setLoading(false);
                        setRefresh(false);
                    });
            }, 500)
        }
    }, [refresh]);

    useEffect(() => {
        if (refetch === true) {
            const first = perPage;
            const offset = (currentPage + 1) * perPage;
            dataProvider.getList(resource, {...params, pagination: {first, offset}, prevActivities: dataStore})
                .then(({data}) => {
                    setDataStore([...dataStore, ...data]);
                })
                .catch((error) => {
                    setError(error)
                })
                .finally(() => {
                    setRefetch(false);
                });
        }
    }, [refetch]);

    const total = filteredData?.length;
    const currentRowStart = currentPage * perPage
    const nextRowStart = Math.min(currentRowStart + perPage, total)
    const rows = filteredData?.slice(currentRowStart, nextRowStart);
    let filterCategoriesChips = []
    for (var i = 0; i < filterCategories.length; i++) {
        filterCategoriesChips.push(
            <FilterChip
                index={i}
                label={filterCategories[i].label}
                isDefaultSelected={filterChipsSelected[i]}
                onSelect={(index) => {
                    let newFilterChipsSelected = filterChipsSelected
                    newFilterChipsSelected[index] = !newFilterChipsSelected[index]
                    setFilterChipsSelected(newFilterChipsSelected)
                    applyTransforms()
                }}
            />
        )
    }
    const displayLoading = loading || parentLoading
    return (
        <Card elevation={0} className={`${classes.root} ${className}`}>
            <CardContent className={classes.content}>
                {children}
                {(error || (!dataStore && !displayLoading)) ?
                    <div className={classes.errorContainer}><GenericError onClick={() => setRefresh(true)}
                                                                          className={classes.error}/></div>
                    : !displayLoading
                        ?
                        <>
                            {
                                (searchEnabled || filterCategoriesChips.length > 0) &&
                                <div className={classes.searchRow}>
                                    {searchEnabled &&
                                        <InlineSearchField
                                            value={searchTerm}
                                            placeholder="Search"
                                            onChange={({target: {value}}) => setSearchTerm(value)}
                                        />
                                    }
                                    {
                                        filterCategoriesChips.length > 0 &&
                                        <Divider
                                            className={classes.divider}
                                            orientation="vertical"
                                            variant="fullWidth"
                                        />
                                    }
                                    {filterCategoriesChips}
                                </div>
                            }
                            {
                                rows.length === 0 ?
                                    <div className={classes.emptyContainer}>
                                        <NoResults label={'No results'} className={classes.empty}/>
                                    </div>
                                    : <DataGrid
                                        classes={gridClasses}
                                        rows={rows}
                                        columns={columns}
                                        rowHeight={rowHeight}
                                        headerHeight={headerHeight}
                                        onRowClick={onRowClick}
                                        disableColumnMenu
                                        disableColumnFilter
                                        disableSelectionOnClick
                                        disableColumnSelector
                                        disableDensitySelector
                                        hideFooterPagination
                                        hideFooterRowCount
                                        hideFooterSelectedRowCount
                                        isCellEditable={() => false}
                                        isRowSelectable={() => false}
                                        editMode={'row'}
                                    />
                            }
                            <Pagination
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                updatePages={paginationEnabled ? setRefetch : undefined}
                                total={total}
                                perPage={perPage}
                                visitedPages={visitedPages}
                                setVisitedPages={setVisitedPages}
                            />

                        </>
                        : <div className={classes.resultContainer}><CircularLoader className={classes.loader}
                                                                                   trigger={displayLoading}
                                                                                   loaderSize='125px'/></div>
                }
            </CardContent>
        </Card>
    )
});

export default Datagrid;