import {Card, CardContent, Divider, makeStyles, Typography} from "@material-ui/core";
import DrawerModal from "./drawerModal";
import BankIcon from "../icons/bankIcon";

const useDetailStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    category: {
        marginBottom: '16px'
    },
    heading: {
        marginBottom: '16px'
    },
    status: {
        margin: 0,
        color: theme.text.primary,
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '21px',
    },
    errorContainer: {
        width: '100%',
        height: '100%',
        // minHeight: `${cardHeight}em`,
        display: 'flex',
    },
    error: {
        display: 'flex',
        margin: 'auto'
    },
    infoCard: {
        margin: '51px 0px 24px 0px',
        '& > div': {
            '& > div': {
                paddingTop: '16px',
                paddingBottom: '16px'
            },
            '& > hr': {
                marginTop: '16px',
                marginBottom: '16px'
            }
        }
    },
    infoRow: {
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '12px 0px 0px 12px',
        "& > svg": {
            paddingRight: '16px',
        }
    },
    infoColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        maxWidth: '85%'
    },
    buttonRow: {
        display: 'flex',
        justifyContent: 'center',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '32px',
    },
    loader: {
        marginLeft: '-24px',
    },
    resultContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        height: '100%',
    },
    name: {
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '21px',
        textAlign: 'left',
        color: '#FFFFFF',
        opacity: 0.5
    },
    value: {
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '21px',
        textAlign: 'start',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '75%',
        color: '#FFFFFF'
    },
    divider: {
        width: '100%',
        marginTop: '28px',
        marginLeft: '76px'
    },
}));

const ViewWhitelistBankAccountsDrawer = ({whitelistedSourceAccounts, onClose}) => {
    const classes = useDetailStyles();
    return (
        <DrawerModal onClose={onClose}>
            <Typography variant='h1' className={classes.heading}>
                Whitelisted bank accounts
            </Typography>
            {whitelistedSourceAccounts.length > 0 &&
                <Card className={classes.infoCard}>
                    <CardContent className={classes.infoContent}>
                        {whitelistedSourceAccounts.map(({
                                                            displayAccountName,
                                                            accountName,
                                                            accountNumber,
                                                            bsbNumber
                                                        }, index) => (

                            <>
                                <div className={classes.infoRow} key={index}>
                                    <BankIcon/>
                                    <div className={classes.infoColumn} key={index}>
                                        <Typography className={classes.name}>
                                            {displayAccountName}
                                        </Typography>
                                        <Typography className={classes.value}>
                                            {accountName}
                                        </Typography>
                                        <Typography className={classes.value}>
                                            {`${bsbNumber} / ${accountNumber}`}
                                        </Typography>
                                    </div>
                                </div>
                                {index !== whitelistedSourceAccounts.length - 1 && (
                                    <Divider
                                        className={classes.divider}
                                        orientation="horizontal"
                                        variant="fullWidth"
                                    />
                                )}
                            </>
                        ))}
                    </CardContent>
                </Card>
            }
        </DrawerModal>
    )
}

export default ViewWhitelistBankAccountsDrawer;