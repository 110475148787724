export const formatApolloError = (e => {
    try {
        if (e.graphQLErrors.length > 0) {
            console.error(e.graphQLErrors)
            // graphql may return multiple errors
            // extract the first to process
            const error = e.graphQLErrors[0]
            var status;

            const httpErrorResponse = error.extensions.response

            if (httpErrorResponse) {
                status = httpErrorResponse.status
            }

            return { 
                error: error,
                message : error.message,
                status: status
            }
        }

        if (e.networkError) {
            const status = e.networkError.statusCode
            const message = e.networkError.message
            
            return {
                error: e,
                message: message,
                status: status
            }
        }
    } catch (e) {
        return e
    }
})
