/* eslint-disable no-useless-computed-key */
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Link } from "@material-ui/core";
import ActionButton from "../button/actionButton";
import CreateNoteDialog from '../dialog/createNoteDialog';
import ModalConsumer from "../consumer/modalConsumer";
import { useAuth0 } from "@auth0/auth0-react";
import EditNoteDialog from "../dialog/editNoteDialog";
import ViewNotesDrawer from "../drawer/viewNotesDrawer";
import NoteRowItem from "../row/notesRowItem";

const useNotesCardStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginTop: '24px',
        minHeight: '395px',
        maxHeight: '395px',
        backgroundColor: theme.surface.secondary,
    },
    content: {
        width: '100%',
        padding: '24px 16px 24px 16px',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
    headerRow: {
        marginLeft: '8px',
        marginBottom: '22px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    submitButton: {
        width: 'auto !important',
        height: 'auto !important',
    },
    viewAllButton: {
        display: 'flex',
        justifyContent: 'flex-start'
    },
    upperContent: {
        width: 'inherit'
    }
}));

const NotesCard = (props) => {
    const classes = useNotesCardStyles();
    const { customerId, refreshCustomerView } = props
    const notes = [...props.notes].sort((note1, note2) => {
        var date1 = new Date(note1.createDate).getTime();
        var date2 = new Date(note2.createDate).getTime();
        return date1 < date2 ? 1 : -1;
    })
    const { isLoading: identityLoading, user } = useAuth0();

    return (
        <Card elevation={0} className={classes.root}>
            <CardContent className={classes.content}>
                <div className={classes.upperContent}>    
                    <div className={classes.headerRow}>
                        <Typography variant="h5">Notes</Typography>
                        <ModalConsumer>
                            {({ showModal }) => (
                                <ActionButton
                                    disabled={identityLoading}
                                    className={classes.submitButton}
                                    onClick={() => showModal(CreateNoteDialog, { data: { customerId, actorEmail: user.email }, onComplete: refreshCustomerView })}
                                    variant='primary'
                                    size='md'
                                >
                                    Add
                                </ActionButton>
                            )}
                        </ModalConsumer>
                    </div>
                    {
                        notes.slice(0,3).map((note) => (
                            <ModalConsumer>
                                {({ showModal }) => (
                                    <NoteRowItem
                                        note={note}
                                        onClick={() => showModal(EditNoteDialog, { data: { customerId, actorEmail: user.email, noteData: note }, onComplete: refreshCustomerView })}
                                    />
                                )}
                            </ModalConsumer>
                        ))
                    }
                 </div>
                { notes.length > 0 && 
                    <ModalConsumer>
                        {({ showModal }) => (
                            <ActionButton
                                disabled={identityLoading}
                                className={classes.viewAllButton}
                                variant="secondary"
                                size="md"
                                onClick={() => 
                                    showModal(
                                        ViewNotesDrawer,
                                        { data: { notes, customerId, email: user?.email }, onClose: refreshCustomerView, onComplete: refreshCustomerView }
                                    )
                                }
                            >
                                {"View all"}
                            </ActionButton>
                        )}
                    </ModalConsumer>
                }
            </CardContent>
        </Card>
    );
};

export default NotesCard;