import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from "@material-ui/core";

const LoadingView = ({variant}) => {
  const useStyles = makeStyles((theme) => ({
    loadingRoot: {
      backgroundColor: theme.background.primary,
      width: variant === 'show' ? '85vw' : '100vw',
      height: variant === 'show' ? '85vh' : '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    spinner: {
        minHeight: '100px',
        minWidth: '100px',
    }
  }));
  const classes = useStyles();
  return (
    <div className={classes.loadingRoot}>
        <CircularProgress className={classes.spinner}/>
    </div>
  );
};

export default LoadingView;