import * as React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import ActionButton from "../button/actionButton";

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: 'auto',
    }
}))

const GenericError = ({customMessage = "", customRetryLabel = "", onClick = () => window.location.reload(true), className}) => {
    const classes = useStyles();
    return (<div className={`${classes.root} ${className}`}>
                <Typography variant="h4">{customMessage || "There was an error"}</Typography>
                <ActionButton variant="secondary" size="lg" onClick={onClick}>{customRetryLabel || "Please try again"}</ActionButton>
            </div>);
};

export default GenericError;