import { makeStyles, TextField } from "@material-ui/core";
import SearchIcon from "../icons/searchIcon";

const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: "24px",
        marginRight: "24px",
        minWidth: '250px',
        maxWidth: '250px',
        minHeight: '44px',
        maxHeight: '44px',
        backgroundColor: '#141417',
        border: '1px solid #77777B',
        borderRadius: '37px',
        '& .MuiInputBase-root.Mui-focused:after': {
            display: 'none'
        },
        '& .MuiInputBase-root.Mui-error:after': {
            display: 'none'
        },
        '& .MuiInputBase-input': {
            padding: '9px 0px 11px 18px',
            borderRadius: '0px',
        },
        '& .MuiInput-root': {
            height: '100%',
            fontFamily: 'DM Sans',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: '21px',
            letterSpacing: '-0.31px',
            color: '#FAFAFA',
            '& > svg': {
                paddingLeft: '24px',
                size: '20px'
            },
            "& > input": {
                color: theme.text.primary,
                backgroundColor: "transparent !important",
                padding: "12px"
            },
            "& > input[value='']": {
                color: "#5F5F63"
            }
        },
        '& .MuiInput-underline::before': {
            display: 'none'
        },
        '& .MuiInputLabel-root': {
            marginLeft: '16px',
            marginTop: '-3px',
            zIndex: 10,
        },
        '& .MuiInputLabel-root.Mui-error': {
            color: theme.palette.input.error
        },
        '& .MuiInputLabel-shrink': {
            marginTop: '10px',
        },
        '& .MuiInputLabel-shrink, .MuiInputLabel-root.Mui-focused:not(.Mui-error)': {
            color: theme.palette.input.primary,
        },
        '& .MuiInputAdornment-root': {
            position: 'absolute',
            top: '4px',
            right: '8px',
            '& > svg': {
                marginRight: '8px',
            }
        },
        '& .MuiFormHelperText-root': {
            marginBottom: '-18px',
            paddingTop: '8px',
            color: theme.palette.input.primary,
            '&.Mui-error': {
                color: theme.palette.input.error,
            }
        },
        '& input:autofill': {
            '-webkit-box-shadow': `0 0 0 50px ${theme.palette.input.background} inset`,
            background: `${theme.palette.input.background} !important`,
            backgroundColor: `${theme.palette.input.background} !important`,
            backgroundClip: 'content-box !important',
        },
        '& input:-webkit-autofill': {
            '-webkit-box-shadow': `0 0 0 50px ${theme.palette.input.background} inset`,
            background: `${theme.palette.input.background} !important`,
            backgroundColor: `${theme.palette.input.background} !important`,
            backgroundClip: 'content-box !important',
        },
        '& input:-webkit-autofill:focus': {
            '-webkit-box-shadow': `0 0 0 50px ${theme.palette.input.background} inset`,
            background: `${theme.palette.input.background} !important`,
            backgroundColor: `${theme.palette.input.background} !important`,
            backgroundClip: 'content-box !important',
        },
        '& input:-webkit-autofill:hover': {
            '-webkit-box-shadow': `0 0 0 50px ${theme.palette.input.background} inset`,
            background: `${theme.palette.input.background} !important`,
            backgroundColor: `${theme.palette.input.background} !important`,
            backgroundClip: 'content-box !important',
        },
        '& input:-webkit-autofill:active': {
            '-webkit-box-shadow': `0 0 0 50px ${theme.palette.input.background} inset`,
            background: `${theme.palette.input.background} !important`,
            backgroundColor: `${theme.palette.input.background} !important`,
            backgroundClip: 'content-box !important',
        },
        '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button ': {
            '-webkit-appearance': 'none',
            'margin': 0,
        },
        '& input': {
            '-moz-appearance': 'textfield',
            '-webkit-text-fill-color': theme.palette.text.primary,
            backgroundColor: `${theme.palette.input.background} !important`,
            opacity: 1,
        },
    }
}));

const SearchField = ({children, value, onChange, placeholder, ...rest}) => {
    const classes = useStyles();
    return (
        <TextField
            placeholder={placeholder}
            type='text'
            classes={classes}
            value={value}
            onChange={onChange}
            InputProps={{
                startAdornment: <SearchIcon/>
            }}
            {...rest}
        >
            {children}
        </TextField>
    );
}

export default SearchField;