import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Divider, Typography } from "@material-ui/core";
import LabelChip from "../chip/labelChip";
import DepositIcon from "../icons/depositIcon";
import WithdrawIcon from "../icons/withdrawIcon";
import { DEPOSIT_CURRENCIES, TRANSACTION_CATEGORIES, TRANSACTION_TYPES } from "../../utils/types";
import ModalConsumer from "../consumer/modalConsumer";
import ActionIconButton from "../button/actionIconButton";
import { formatCurrency } from "../../utils/formatter";
import PendingTransactionDialog from "../dialog/pendingTransactionDialog";
import { AdminRole } from "../../utils/role";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        marginBottom: '24px',
        backgroundColor: theme.surface.primary,
    },
    content: {
        width: '100%',
        padding: '24px'
    },
    primaryContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    secondaryContent: {

    },
    currency: {
        marginBottom: '16px'
    },
    actionsContainer: {
        display: 'flex',
        marginTop: '6px'
    }
}));

const CustomerBalanceCard = (props) => {
    const { customerBalance, totalInterest, activityListRef, accountId } = props
    const classes = useStyles();
    return (
        <Card elevation={0} className={classes.root}>
            <CardContent className={classes.content}>
                <div className={classes.primaryContent}>
                    <div>
                        <Typography variant="h1" className={classes.currency}>
                            {formatCurrency(customerBalance, 'USD', false)}
                        </Typography>
                        <LabelChip variant="secondary" label="Customer balance" />
                    </div>
                    <AdminRole>
                        <ModalConsumer>
                            {({ showModal }) => (
                                <div className={classes.actionsContainer}>
                                    <ActionIconButton
                                        onClick={() => showModal(PendingTransactionDialog, {
                                            accountId,
                                            transactionType: TRANSACTION_TYPES.PENDING_DEBIT,
                                            transactionCategory: TRANSACTION_CATEGORIES.WITHDRAWAL,
                                            selectableCurrencies: ["USD"],
                                            onComplete: () => activityListRef?.current?.refresh()
                                        })}
                                        label="Withdraw"
                                        icon={<WithdrawIcon />}
                                    />
                                    <ActionIconButton
                                        onClick={() => showModal(PendingTransactionDialog, {
                                            accountId,
                                            transactionType: TRANSACTION_TYPES.PENDING_CREDIT,
                                            transactionCategory: TRANSACTION_CATEGORIES.DEPOSIT,
                                            onComplete: () => activityListRef?.current?.refresh(),
                                            selectableCurrencies: Object.values(DEPOSIT_CURRENCIES),
                                        })}
                                        label="Deposit"
                                        icon={<DepositIcon />}
                                    />
                                </div>)}
                        </ModalConsumer>
                    </AdminRole>
                </div>
            </CardContent>
        </Card>
    );
};

export default CustomerBalanceCard;
