import {
    Title,
    ListBase,
} from "react-admin";
import {Divider, Grid, Typography} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import Datagrid from "../table/Datagrid";
import { useHistory } from "react-router-dom";
import { useRef, useState } from "react";
import SearchField from "../input/searchField";

const CUSTOMER_PAGE_SIZE = 8
const PAGES_TO_FETCH = 2;

const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: '32px'
    },
    divider: {
        width: "109%",
        marginLeft: "-24px"
    },
    searchRow: {
        display: 'flex',
        paddingTop: "32px",
        paddingBottom: "48px",
        marginLeft: "-32px",
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    gridContainer: {
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(3),
        },
        height: '100%',
    },
    tableCard: {
        minHeight: '96%',
        padding: theme.spacing(3),
        paddingTop: theme.spacing(2),
        backgroundColor: theme.surface.secondary,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    }
}));

const CustomerList = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const datagrid = useRef(null);
    const [searchTerm, setSearchTerm] = useState('');
    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            sortable: 'false',
            renderCell: ({row: {name}}) => (<Typography variant="body1">{name}</Typography>),
            renderHeader: ({colDef: { headerName }}) => (<Typography variant="subtitle1">{headerName}</Typography>),
            flex: 0.45,
        },
        {
            field: 'email',
            headerName: 'Email',
            sortable: 'false',
            renderCell: ({row: {email}}) => (<Typography variant="body1">{email}</Typography>),
            renderHeader: ({colDef: { headerName }}) => (<Typography variant="subtitle1">{headerName}</Typography>), 
            flex: 0.25,
        },
        {
            field: 'phone',
            headerName: 'Phone',
            sortable: 'false',
            renderCell: ({row: {phone}}) => (<Typography variant="body1">{phone}</Typography>),
            renderHeader: ({colDef: { headerName }}) => (<Typography variant="subtitle1">{headerName}</Typography>),
            flex: 0.15,
        },
        {
            field: 'kycStatus',
            headerName: 'Kyc Status',
            sortable: 'false',
            renderCell: ({row: {kycStatus}}) => (<Typography variant="body1">{kycStatus}</Typography>),
            renderHeader: ({colDef: { headerName }}) => (<Typography variant="subtitle1">{headerName}</Typography>),
            flex: 0.15,
        }
    ];
    return (
        <>
            <Title title='Customers'/>
            <ListBase {...props} perPage={8}>
                <Grid container spacing={3} className={classes.gridContainer}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Datagrid
                            params={{searchTerm}}
                            ref={datagrid}
                            columns={columns}
                            resource="customers"
                            perPage={CUSTOMER_PAGE_SIZE}
                            preFetch={PAGES_TO_FETCH}
                            onRowClick={({id}) => history.push(`/customers/${id}/show`)}
                            rowHeight={40}
                            cellHeight={40}
                            headerHeight={32}
                            cardHeight={62}
                        >
                            <Typography variant="h5" className={classes.title}>Customers</Typography>
                            <Divider
                                className={classes.divider}
                                orientation="horizontal"
                                variant="fullWidth"
                            />
                            <div className={classes.searchRow}>
                                <SearchField
                                    value={searchTerm}
                                    placeholder="Search users"
                                    onChange={({target: { value }}) => setSearchTerm(value)}
                                />
                            </div>
                        </Datagrid>
                    </Grid>
                </Grid>
            </ListBase>
        </>
    );
}

export default CustomerList;