import { useState } from "react";
import { useCreate } from "react-admin";
import validator from 'validator';
import { DEPOSIT_CURRENCIES, TRANSACTION_TYPES } from "../types";

export const useTransactionFormControls = (initValues) => {

    const [values, setValues] = useState({
        currency: initValues.currency || DEPOSIT_CURRENCIES.USD,
        depositAmount: initValues.depositAmount || '',
        accountId: initValues.accountId,
        orderId: initValues.orderId,
        reference: initValues.reference
    });

    const [errors, setErrors] = useState({});
    const [create] = useCreate('transactions');

    const getValues = () => (values);

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        const { currency, depositAmount } = fieldValues;

        if (currency)
          temp.currency = currency ? Object.values(DEPOSIT_CURRENCIES).includes(currency) ? '' : 'Please select a valid currency.' : "This field is required."

        if (depositAmount)
            temp.depositAmount = depositAmount ? validator.isFloat(depositAmount) && parseFloat(depositAmount) > 0 ? '' : 'Please enter a valid amount.' : "This field is required."

        setErrors({
          ...temp
        });
    }

    const handleTextInputValue = (e) => {
        const { name, value } = e.target;
        setValues({
          ...values,
          [name]: value,
          isUpdated: true
        });
        validate({ [name]: value });
    }

    const handleCreateTransactionFormSubmit = async (onComplete, transactionType) => {
      const opts = values.orderId ? { orderId: values.orderId } : { };
      create({
          payload: {
              accountId: values.accountId,
              amount: parseFloat(values.depositAmount),
              currency: values.currency,
              transactionType: transactionType,
              ...opts
          }
      }, {
        onSuccess: ({data}) => {
          const isSuccessful = data.error === undefined
          setTimeout(() => onComplete(isSuccessful), 1000);
        },
        onFailure: ((e) => console.error(e))
      });
    }

    const handleConfirmTransactionFormSubmit = async (onComplete, transactionType) => {
        let opts = {}
        opts = values.orderId ? { ...opts, orderId: values.orderId } : opts;
        if (transactionType === TRANSACTION_TYPES.CANCEL_SEND_FIAT) {
            opts = values.reference ? { ...opts, sendTransactionId: values.reference } : opts;
        } else {
            opts = values.reference ? { ...opts, reference: values.reference } : opts;
        }
        create({
            payload: {
                transactionType: transactionType,
                ...opts
            }
        }, {
            onSuccess: ({data}) => {
            const isSuccessful = data.error === undefined
            setTimeout(() => onComplete(isSuccessful), 1000);
            },
            onFailure: ((e) => console.error(e))
        });
    }

    const formIsValid = (fieldValues = values) => {
      const isValid =
          fieldValues.currency &&
          fieldValues.depositAmount &&
          Object.values(errors).every((x) => x === '');
      return isValid;
    }

    return {
        getValues,
        handleTextInputValue,
        handleCreateTransactionFormSubmit,
        handleConfirmTransactionFormSubmit,
        formIsValid,
        inputErrors: errors
    };
}
