import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import customerAvatar from "../images/customerAvatar.png";
import ActionIconButton from "../button/actionIconButton";
import MoreIcon from "../icons/moreIcon";
import { MoreActionsDialog } from "../dialog/moreActionsDialog";
import ModalConsumer from "../consumer/modalConsumer";
import { AdminRole } from "../../utils/role";
import LabelChip from "../chip/labelChip";
import LockIcon from "../icons/lockIcon";

const getCustomerColor = (type) => {
  switch (type) {
    case "TRUST":
      return "#FF768D";
    case "COMPANY":
      return "#5887FF";
    case "SMSF":
      return "#FFA3FD";
    case "INDIVIDUAL":
    default:
      return "#9892FF";
  }
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
  },
  actionButton: {
    width: "40px",
    height: "40px",
    backgroundColor: "#24242A",
    border: "1px solid #24242A",
    "&:hover": {
      backgroundColor: "#24242A",
      opacity: 0.75,
    },
    "& + p": {
      margin: 0,
    },
  },
  customerSection: {
    display: "flex",
    alignItems: "center",
  },
  avatarSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  customerTypeChip: {
    color: (props) => getCustomerColor(props.type?.toUpperCase()),
  },
  avatar: {
    borderRadius: "33px",
    width: "56px"
  },
  detailsSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    marginLeft: "24px",
  },
  lockChip: {
    marginLeft: "24px",
    "& > div": {
      borderRadius: "25px",
      padding: "15px 16px",
      height: "40px",
      background: "rgba(255, 84, 71, 0.20)",
      "& > span": {
        fontFamily: "DM Sans",
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "20px",
        letterSpacing: "-0.23px",
        color: "#FF5447",
        paddingLeft: "8px",
        padding: 0,
      },
    },
  },
});

const CustomerTitleCard = (props) => {
  const { name, customerLocked, customerClosed, customerId, onCustomerUpdate, kycStatus } = props;
  const classes = useStyles(props);
  return (
    <Box className={classes.root}>
      <Box className={classes.customerSection}>
        <div className={classes.avatarSection}>
          <img src={customerAvatar} alt="" className={classes.avatar} />
        </div>
        <div className={classes.detailsSection}>
          <Typography variant="h3">{name}</Typography>
        </div>
        {customerLocked && (
          <LabelChip
            label="Locked"
            icon={<LockIcon />}
            className={classes.lockChip}
          />
        )}
        {customerClosed && (
            <LabelChip
                label="Closed"
                icon={<LockIcon />}
                className={classes.lockChip}
            />
        )}
      </Box>
      { kycStatus === "VERIFIED" &&
        <AdminRole>
          <ModalConsumer>
            {({ showModal }) => (
              <ActionIconButton
                className={classes.actionButton}
                onClick={() =>
                  showModal(MoreActionsDialog, {
                    customerId: customerId,
                    customerLocked: customerLocked,
                    customerClosed: customerClosed,
                    onComplete: onCustomerUpdate,
                  })
                }
                icon={<MoreIcon />}
              />
            )}
          </ModalConsumer>
        </AdminRole>
      }
    </Box>
  );
};

export default CustomerTitleCard;
