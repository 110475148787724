import {makeStyles} from "@material-ui/core/styles";
import {Button, CircularProgress} from "@material-ui/core";

const ActionButton = ({variant, size, onClick, selected, children, loading, ...props}) => {
    const useStyles = makeStyles((theme) => {
        let rootSize = {}
        let labelSize = {}
        switch (size) {
            case 'lg':
                rootSize = {
                    width: '350px',
                    height: '61px'
                }
                labelSize = {
                    fontSize: '18px',
                    lineHeight: '23px',
                    letterSpacing: '-0.5px'
                }
                break;
            case 'md':
                rootSize = {
                    width: '160px',
                    height: '41px'
                }
                labelSize = {
                    fontSize: '17px',
                    lineHeight: '22px',
                    letterSpacing: '-0.43px',
                    fontWeight: 600,
                }
                break;
            case 'sm':
            default:
                rootSize = {
                    width: '82px',
                    height: '32px'
                }
                labelSize = {
                    fontSize: '15px',
                    lineHeight: '16px',
                }
        }
        return {
            contained: {
                '&.Mui-disabled': {
                    opacity: 0.5,
                    color: theme.text.button.primary,
                    backgroundColor: theme.button.backgroundColor,
                },
                backgroundColor: theme.button.backgroundColor,
                borderRadius: '100px',
                fontFamily: 'DM Sans',
                fontStyle: 'normal',
                fontWeight: 'bold',
                color: theme.text.button.primary,
                ...labelSize,
                ...rootSize
            },
            containedTransparent: {
                '&.Mui-disabled': {
                    opacity: 0.5,
                    color: theme.text.button.primary,
                    backgroundColor: theme.button.backgroundColor,
                },
                backgroundColor: 'transparent',
                borderRadius: '100px',
                fontFamily: 'DM Sans',
                fontStyle: 'normal',
                fontWeight: 'bold',
                color: theme.button.backgroundColor,
                border: `1px solid ${theme.button.backgroundColor}`,
                ...labelSize,
                ...rootSize
            },
            text: {
                '&.Mui-disabled': {
                    opacity: 0.25,
                    color: theme.text.button.inline,
                },
                fontFamily: 'DM Sans',
                fontStyle: 'normal',
                '& > span': {
                    color: selected ? theme.text.button.secondary : variant === 'inline' ? theme.text.button.inline : selected === undefined ? theme.text.button.secondary : "#FDFDFD !important"
                },
                '&::after': {
                    borderBottom: `2px solid ${variant === 'inline' ? theme.text.button.inline : theme.text.button.secondary}`,
                },
                fontSize: '17px',
                lineHeight: '22px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                ...labelSize
            }
        }
    });
    const classes = useStyles();
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
        }}>
            <Button variant={variant === 'primary' ? 'contained' : variant === 'cancel' ? 'containedTransparent' : 'text'}
                    classes={classes}
                    onClick={onClick}
                    {...props}
            >
                {
                    loading ? <CircularProgress/>
                        : children
                }
            </Button>
            {variant === 'text' && selected &&
                <span style={{height: '2px', backgroundColor: "#A3A2FF", width: '96%', marginLeft: "2px"}}/>}
        </div>
    );
};

export default ActionButton;
