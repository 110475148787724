import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from "@material-ui/core/styles";

import ActionButton from '../button/actionButton';
import { Typography } from '@material-ui/core';
import SuccessTick from '../success/successTick';
import CircularLoader from '../loading/circularLoader';
import GenericError from '../error/genericError';
import { useTransactionFormControls } from '../../utils/formControls/transactionFormControls';

const useStyles = makeStyles((theme) => ({
    root: {
        top: '50%',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        width: '416px',
        height: '531px',
        borderRadius: '20px',
        background: theme.background.dialog
    },
    cardContent: {
        height: '90%',
        margin: '32px !important',
        padding: '0 !important',
        display: 'flex',
        justifyContent: 'center',
    },
    success: {
        height: '95%',
        marginLeft: '24px'
    },
    closeButton: {
        position: 'absolute',
        top: theme.spacing(4),
        right: theme.spacing(3),
    },
    backdrop: {
        ['@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))']: {
            '-webkit-backdrop-filter': 'blur(2em)',
            backdropFilter: 'blur(2em)',
            backgroundColor: 'rgb(0 0 0 / 33%) !important',
        }
    },
    content: {
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '42px',
    },
    confirmButton: {
        marginBottom: '40px',
        position: 'absolute',
        bottom: 0,
    },
    heading: {
        marginTop: '12px',
        alignSelf: 'start'
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        margin: '32px 0px 32px 0px'
    },
    input: {
        margin: '32px 0px 12px 0px !important',
    },
    selectLabel: {
        marginBottom: '4px'
    },
    resultContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        height: '100%',
    },
}));

const ConfirmTransactionDialog = ({ onClose, onComplete, transactionType, accountId, currency, amount, orderId, reference, label }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [success, setSuccess] = useState(false);
    const [submitRequested, setSubmitRequested] = useState(false);

    const {
        handleConfirmTransactionFormSubmit
    } = useTransactionFormControls({ depositAmount: amount, accountId, orderId, currency, reference });


    const submitCallback = (success) => {
        setLoading(false);
        if (success) {
            setSuccess(true);
            setError(false);
            onComplete();
        } else {
            setError(true)
        }
    }

    useEffect(() => {
        if (loading && submitRequested) {
            handleConfirmTransactionFormSubmit(submitCallback, transactionType);
        }
    }, [submitRequested]);

    return (
        <Modal open={true} onClose={onClose} BackdropProps={{className:classes.backdrop}}>
            <Fade in={true}>
                <Card className={classes.root}>
                    <CardContent className={classes.cardContent}>
                        <ActionButton variant="secondary" size="lg"
                            className={classes.closeButton}
                            onClick={onClose}
                        >
                            Close
                        </ActionButton>
                        {
                            error ? <div className={classes.resultContainer}>
                                        <GenericError onClick={() => { setError(false); setLoading(true); handleConfirmTransactionFormSubmit(submitCallback, transactionType);}}/>
                                    </div> :
                            success ? <div className={classes.resultContainer}><SuccessTick trigger={success} tickSize='175px'/></div> :
                            loading ? <div className={classes.resultContainer}><CircularLoader trigger={loading} loaderSize='125px'/></div> :
                            <div className={classes.content}>
                                <Typography variant="h3" className={classes.heading}>{`Confirm you'd like to ${label} this transaction?`}</Typography>
                                <ActionButton
                                    variant="primary"
                                    size="lg"
                                    className={classes.confirmButton}
                                    disabled={false}
                                    onClick={() => { setLoading(true); setSubmitRequested(true);}}
                                >
                                    {`Confirm ${label}`}
                                </ActionButton>
                            </div>
                        }
                    </CardContent>
                </Card>
            </Fade>
        </Modal>
    )
}

export default ConfirmTransactionDialog;
