import { gql } from "@apollo/client";

export const getPaymentMethodsQuery = gql`
  query PaymentMethods($customerId: String!) {
    paymentMethods: paymentMethods_Admin(customerId: $customerId) {
      cards {
        id
        customerId
        type
        accountName
        accountDetailsText
        iconUrl
        roundedIconUrl
        scheme
        nickname
        extras {
          maximumCardOnRampUSDC
          minimumCardOnRampUSDC
        }
        inputMetadata {
          value
          fieldName
          editable
        }
        displayMetadata {
          label
          value
          order
        }
      }
      payables {
        externalWallets {
          id
          customerId
          nickName
          address
        }
        accountFiatMethods {
          id
          customerId
          type
          accountName
          accountDetailsText
        }
      }
    }
    accounts_Admin(customerId: $customerId) {
      paymentMethods {
        autoMatcherAccount {
          id
          accountName
          accountNumber
          bsb
          status
          dateModified
          dateCreated
          regionType
          whitelistedSourceAccounts {
            id
            displayAccountName
            accountName
            accountNumber
            bsbNumber
            accountStatus
          }
        }
        payId {
          payId
          payIdName
          status
        }
        crypto {
          id
          accountId
          generatingWallets {
            network {
              id
              name
              longName
              iconUrl
              altIconUrls {
                depositDisplayIcon
                depositSelectorDisplayIcon
                smallDisplayIcon
              }
              uriPrefix
            }
          }
          wallets {
            address
            network {
              id
              name
              longName
              iconUrl
              altIconUrls {
                depositDisplayIcon
                depositSelectorDisplayIcon
                smallDisplayIcon
              }
              uriPrefix
            }
            uri
          }
        }
      }
      id
      name
    }
  }
`

