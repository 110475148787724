import { gql } from "@apollo/client";

export const updateCustomerMutation = gql`
  mutation UpdateCustomer($input: UpdateCustomerMutationInput!) {
    updateCustomer: updateCustomer_Admin(input: $input) {
      id,
      name
      email
      phone
      customerType
      dateCreated
      kycRef
      externalRef,
      address {
        formattedAddress
        id
      },
      status
    }
  }
`

export const closeCustomerMutation = gql`
    mutation CloseCustomer_Admin($input: CloseCustomerMutationInput!) {
	closeCustomer_Admin(input: $input)
}
`