import { makeStyles, TextField } from "@material-ui/core";
import SearchIcon from "../icons/searchIcon";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: '200px',
        maxWidth: '200px',
        minHeight: '40px',
        maxHeight: '40px',
        background: 'transparent',
        '& .MuiTextField-root': {
            background: 'transparent'
        },
        '& .MuiInputBase-root.Mui-focused:after': {
            display: 'none'
        },
        '& .MuiInputBase-root.Mui-error:after': {
            display: 'none'
        },
        '& .MuiInputBase-input': {
            padding: '12px 12px 12px 9px',
            borderRadius: '0px',
        },
        '& .MuiInput-root': {
            boxSizing: 'border-box',
            width: '200px',
            height: '40px',
            border: '1px solid #FFFBFF',
            borderRadius: '54px',
            '& > svg': {
                paddingLeft: '12px',
            },
            "& > input[value='']": {
                opacity: 0.4,
            }
        },
        '& .MuiInput-underline::before': {
            display: 'none'
        },
        '& .MuiInputLabel-root': {
            marginLeft: '16px',
            marginTop: '-3px',
            zIndex: 10,
        },
        '& .MuiInputLabel-root.Mui-error': {
            color: theme.palette.input.error
        },
        '& .MuiInputLabel-shrink': {
            marginTop: '10px',
        },
        '& .MuiInputLabel-shrink, .MuiInputLabel-root.Mui-focused:not(.Mui-error)': {
            color: theme.palette.input.primary,
        },
        '& .MuiInputAdornment-root': {
            position: 'absolute',
            top: '4px',
            right: '8px',
            '& > svg': {
                marginRight: '8px',
            }
        },
        '& .MuiFormHelperText-root': {
            marginBottom: '-18px',
            paddingTop: '8px',
            color: theme.palette.input.primary,
            '&.Mui-error': {
                color: theme.palette.input.error,
            }
        },
        '& input:autofill': {
            '-webkit-box-shadow': `0 0 0 50px transparent inset`,
            background: `transparent !important`,
            backgroundColor: `transparent !important`,
            backgroundClip: 'content-box !important',
        },
        '& input:-webkit-autofill': {
            '-webkit-box-shadow': `0 0 0 50px transparent inset`,
            background: `transparent !important`,
            backgroundColor: `transparent !important`,
            backgroundClip: 'content-box !important',
        },
        '& input:-webkit-autofill:focus': {
            '-webkit-box-shadow': `0 0 0 50px transparent inset`,
            background: `transparent !important`,
            backgroundColor: `transparent !important`,
            backgroundClip: 'content-box !important',
        },
        '& input:-webkit-autofill:hover': {
            '-webkit-box-shadow': `0 0 0 50px transparent inset`,
            background: `transparent !important`,
            backgroundColor: `transparent !important`,
            backgroundClip: 'content-box !important',
        },
        '& input:-webkit-autofill:active': {
            '-webkit-box-shadow': `0 0 0 50px transparent inset`,
            background: `transparent !important`,
            backgroundColor: `transparent !important`,
            backgroundClip: 'content-box !important',
        },
        '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button ': {
            '-webkit-appearance': 'none',
            'margin': 0,
        },
        '& input': {
            '-moz-appearance': 'textfield',
            '-webkit-text-fill-color': theme.palette.text.primary,
            backgroundColor: `transparent !important`,
            opacity: 1,
        },
    }
}));

const InlineSearchField = ({children, value, onChange, placeholder, ...rest}) => {
    const classes = useStyles();
    return (
        <TextField
            placeholder={placeholder}
            type='text' 
            classes={classes}
            value={value}
            onChange={onChange}
            InputProps={{
                startAdornment: <SearchIcon/>
            }}
            {...rest}
        >
            {children}
        </TextField>
    );
}

export default InlineSearchField;