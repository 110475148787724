import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from "@material-ui/core/styles";

import ActionButton from '../button/actionButton';
import { Typography } from '@material-ui/core';
import SuccessTick from '../success/successTick';
import CircularLoader from '../loading/circularLoader';
import GenericError from '../error/genericError';
import { useCreateNoteFormControls } from '../../utils/formControls/createNoteFormControls';
import TextArea from '../input/textArea';

const useStyles = makeStyles((theme) => ({
    root: {
        top: '50%',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        width: '416px',
        height: '706px',
        borderRadius: '20px',
        background: theme.background.dialog
    },
    cardContent: {
        height: '90%',
        margin: '32px !important',
        padding: '0 !important'
    },
    success: {
        height: '95%',
        marginLeft: '24px'
    },
    closeButton: {
        position: 'absolute',
        top: theme.spacing(4),
        right: theme.spacing(3),
    },
    backdrop: {
        ['@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))']: {
            '-webkit-backdrop-filter': 'blur(2em)',
            backdropFilter: 'blur(2em)',
            backgroundColor: 'rgb(0 0 0 / 33%) !important',
        }
    },
    content: {
        height: '104%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '42px',
        justifyContent: 'space-between',
    },
    upperContent:  {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'stretch',
    },
    heading: {
        marginTop: '24px'
    },
    conversionContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: '16px'
    },
    input: {
        margin: '0px !important',
    },
    confirmButton: {
        marginBottom: '40px',
        '& MuiButton-label': {
            height: '61px'
        }
    },
    resultContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        height: '100%',
    },
    loaderLabel: {
        position: 'absolute',
        top: '60%'
    }
}));

const CreateNoteDialog = ({ onClose, onComplete, data: { customerId, actorEmail } }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [success, setSuccess] = useState(false);
    const [submitRequested, setSubmitRequested] = useState(false);

    const {
        getValues,
        handleTextInputValue,
        handleFormSubmit,
        formIsValid,
        inputErrors
    } = useCreateNoteFormControls({ customerId, actorEmail });

    const currentValues = getValues();

    const submitCallback = (success) => {
        setLoading(false);
        if (success) {
            setSuccess(true);
            setError(false);
            onComplete();
        } else {
            setError(true)
        }
    }

    useEffect(() => {
        if (loading && submitRequested) {
            handleFormSubmit(submitCallback);
        }
    }, [submitRequested]);
    
    return (
        <Modal open={true} onClose={() => {onComplete(); onClose();}} BackdropProps={{className:classes.backdrop}}>
            <Fade in={true}>
                <Card className={classes.root}>
                    <CardContent className={classes.cardContent}>
                        <ActionButton variant="secondary" size="lg"
                            className={classes.closeButton}
                            onClick={() => {onComplete(); onClose();}}
                        >
                            Close
                        </ActionButton>
                        {
                            error ? <div className={classes.resultContainer}>
                                    <GenericError onClick={() => { setError(false); setLoading(true); handleFormSubmit(submitCallback);}}/>
                                </div> :
                            success ? <div className={classes.resultContainer}><SuccessTick trigger={success} tickSize='175px'/></div> :
                            loading ? <div className={classes.resultContainer}>
                                            <CircularLoader trigger={loading} loaderSize='125px'/>
                                        </div> :
                            <div className={classes.content}>
                                <div className={classes.upperContent}>
                                    <Typography variant="h3" className={classes.heading}>Add note</Typography>
                                    <div className={classes.conversionContainer}>
                                        <TextArea
                                            className={classes.input}
                                            name='note'
                                            type='text'
                                            value={currentValues.note}
                                            InputLabelProps={{shrink: false, required: true, disableAnimation: true}}
                                            onChange={handleTextInputValue}
                                            placeholder="Add note"
                                            {...(inputErrors['note'] && { error: true, helperText: inputErrors['note'] })}
                                        />
                                    </div>
                                </div>
                                <ActionButton
                                    variant="primary"
                                    size="lg"
                                    className={classes.confirmButton}
                                    disabled={!formIsValid()}
                                    onClick={() => { setLoading(true); setSubmitRequested(true);}}
                                >
                                    Save
                                </ActionButton>
                            </div>
                        }
                    </CardContent>
                </Card>
            </Fade>
        </Modal>
    )
}

export default CreateNoteDialog;