import {
    ApolloClient,
    HttpLink,
    InMemoryCache,
    from,
    ApolloLink
  } from "@apollo/client";
  import { onError } from "@apollo/client/link/error";

// define http
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_CLIENT_API
});

// attatch auth token
const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => {
   const result = {
      headers: {
        authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        ...headers
      }
    }

    if (process.env.REACT_APP_DEV_ADD_ROLES_TO_REQUEST === 'true') {
      result.headers['roles'] = 'admin'
    }

    return result;
  });
    return forward(operation);
});

// add extra logging for errors
const errorLink = onError(({ graphQLErrors, networkError, response }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }

});

// setup apollo client
export const client = new ApolloClient({
  query: {
    errorPolicy: "all",
  },
  mutate: {
    errorPolicy: "all",
  },
  link: from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
});