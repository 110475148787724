import { makeStyles, Zoom } from "@material-ui/core";
import DoneIcon from '@material-ui/icons/Done';


const SuccessTick = ({trigger, tickSize}) => {
    const useStyles = makeStyles((theme) => ({
        animContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 'auto'
        },
        successIcon: {
            minWidth: tickSize || '64px',
            minHeight: tickSize || '64px',
            fill: theme.text.positive
        }
    }));
    const classes = useStyles();
    return (
        <div className={classes.animContainer}>
            <Zoom in={trigger}>
                <DoneIcon className={classes.successIcon}/>
            </Zoom>
        </div>
    )
}

export default SuccessTick;