import { Card, makeStyles, Typography } from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import { useCustomerFormControls } from "../../utils/formControls/customerFormControls.js";
import ActionButton from "../button/actionButton";
import TextField from "../input/textField";
import Switch from "../toggle/switch";
import DrawerModal from "./drawerModal";
import { formatCustomerType, formatDate, formatKycStatus } from "../../utils/formatter.js";
import SuccessTick from "../success/successTick.js";
import CircularLoader from "../loading/circularLoader.js";
import GenericError from "../error/genericError";
import CompleteKycDialog from "../dialog/completeKycDialog";
import ModalConsumer from "../consumer/modalConsumer";
import _ from 'lodash'
import { useDataProvider } from "react-admin";

const useStyles = makeStyles((theme) => ({
    heading: {
        marginBottom: '32px'
    },
    form: {
        marginBottom: '32px'
    },
    formCard: {
        margin: '24px 0px 24px 0px',
        padding: '32px 24px',
    },
    switchContainer: {
        marginTop: '32px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    selectLabel: {
        margin: '24px 0px 6px 0px',
    },
    submitButton: {
        margin: '24px 0px 24px 0px'
    },
    animContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '75vh'
    },
    loader: {
        minWidth: '125px',
        minHeight: '125px'
    },
    successIcon: {
        minWidth: '175px',
        minHeight: '175px',
        fill: theme.text.positive
    },
    staticFieldContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        marginTop: '-16px'
    },
    staticField: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '16px'
    },
    resultContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        height: '85%',
    },
    autoCompleteField: {
        marginTop: '32px !important'
    }
}));

const UpdateCustomerDrawer = ({ onClose, onComplete, data }) => {
    const [loading, setLoading] = useState(false);
    const [submitError, setSubmitError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [submitRequested, setSubmitRequested] = useState(false);
    const dataProvider = useDataProvider();

    const submitCallback = (success) => {
        setLoading(false);
        if (success) {
            setSuccess(true);
            onComplete?.();
        } else {
            setSubmitError(true)
        }
    }
    useEffect(() => {
        if (loading && submitRequested) {
            handleUpdateFormSubmit(submitCallback);
        } else if (!loading && submitRequested) {
        }
    }, [loading, submitRequested]);
    const classes = useStyles();
    const { id: customerId, name, email, phone: phoneNumber, dateCreated, kycRef, kycStatus, customerType, address } = data;
    const formattedCustomerType = formatCustomerType(customerType);
    const formattedDate = formatDate(dateCreated);
    const isVerified = formatKycStatus(kycStatus);
    const {
        getValues,
        handleTextInputValue,
        handleSwitchInputValue,
        handleAddressInputValue,
        handleUpdateFormSubmit,
        updateFormIsValid,
        errors
    } = useCustomerFormControls({ name, email, phoneNumber, date: formattedDate, kycRef, isVerified, customerType, customerId, address });
    const currentValues = getValues();

    const fieldData = [
        {
            fieldName: 'name',
            type: 'text',
            label: 'Name',
            required: false,
            onChange: handleTextInputValue,
            disabled: false,
        },
        {
            fieldName: 'phoneNumber',
            type: 'tel',
            label: 'Phone number',
            onChange: handleTextInputValue,
            disabled: true,
        },
        {
            fieldName: 'email',
            type: 'email',
            label: 'Email',
            onChange: handleTextInputValue,
            disabled: true,
        },
        {
            fieldName: 'kycRef',
            type: 'text',
            label: 'KYC reference',
            onChange: handleTextInputValue,
            disabled: false,
        },
        {
            fieldName: 'customerId',
            type: 'text',
            label: 'Customer ID',
            disabled: true,
        }
    ];

    // address auto correct, could seperate out. didn't want to convulte the above
    const [addressInput, setAddressInput] = useState(currentValues['address']?.formattedAddress)
    const [addressAutoCompleteOptions, setAddressAutoCompleteOptions] = useState([])
    const handleAutoComplete = async (query) => {
        const res = await dataProvider.getList('address', query)

        const autoCompleteOptions = res?.data?.map((item) => {
            return { label: item.address, id: item.id }
        }) ?? []
        setAddressAutoCompleteOptions(autoCompleteOptions)
    }
    const queryDebouncer = useCallback(_.debounce(handleAutoComplete, 500), []);

    return (
        <DrawerModal onClose={onClose}>
            {submitError ? <div className={classes.resultContainer}><GenericError onClick={() => { setSubmitError(false); setLoading(true); setSubmitRequested(true); }} /></div> :
                success ? <div className={classes.resultContainer}><SuccessTick trigger={success} tickSize='175px' /></div> :
                    loading ? <div className={classes.resultContainer}><CircularLoader trigger={loading} loaderSize='125px' /></div> :
                        <>
                            <Typography variant='h2' className={classes.heading}>Customer details</Typography>
                            <Card className={classes.formCard}>
                                <div className={classes.staticFieldContainer}>
                                    <div className={classes.staticField}>
                                        <Typography variant='subtitle2'>Customer type</Typography>
                                        <Typography variant='body2'>{formattedCustomerType}</Typography>
                                    </div>
                                    <div className={classes.staticField}>
                                        <Typography variant='subtitle2'>Created</Typography>
                                        <Typography variant='body2'>{formattedDate}</Typography>
                                    </div>
                                </div>
                                <div className={classes.switchContainer}>
                                    <Typography variant='body2' >Verified</Typography>
                                    <Switch
                                        name='isVerified'
                                        checked={currentValues.isVerified}
                                        onChange={handleSwitchInputValue}
                                        disabled
                                        {...(errors['isVerified'] && { error: true, helperText: errors['isVerified'] })}
                                    />
                                </div>
                            </Card>
                            <Card className={classes.formCard}>
                                {
                                    fieldData.map((field, index) => 
                                        <TextField
                                            disabled={field.disabled}
                                            key={index}
                                            value={currentValues[field.fieldName]}
                                            name={field.fieldName}
                                            type={field.type}
                                            label={field.label}
                                            InputLabelProps={{ shrink: false, required: field.required, disableAnimation: true }}
                                            onChange={field.onChange}
                                            {...(errors[field.fieldName] && { error: true, helperText: errors[field.fieldName] })}
                                        />
                                    )
                                }

                                <div className={classes.autoCompleteField}>
                                    <Autocomplete
                                        freeSolo
                                        options={addressAutoCompleteOptions}
                                        filterOptions={(x) => x}
                                        disableClearable
                                        value={addressInput}
                                        onChange={(e, address) => {
                                            handleAddressInputValue({address: address.label, id: address.id})
                                        }}
                                        renderInput={(params) => {
                                            return (
                                                <TextField
                                                    {...params}
                                                    name={'address'}
                                                    type={'text'}
                                                    label={'Address'}
                                                    InputLabelProps={{ shrink: false, disableAnimation: true }}
                                                    onChange={(e) => {
                                                        const value = e.target.value
                                                        setAddressInput(value)
                                                        queryDebouncer({ address: value });
                                                    }}
                                                    {...(errors['address'] && { error: true, helperText: errors['address'] })}
                                                />
                                            )
                                        }}
                                    />
                                    <Typography variant="caption" style={{ marginTop: '10px'}}>You must select an address from the autocomplete. Start typing to see.</Typography>
                                </div>

                            </Card>
                            <ActionButton
                                className={classes.submitButton}
                                onClick={() => { setLoading(true); setSubmitRequested(true); }}
                                disabled={!updateFormIsValid()}
                                variant='primary'
                                size='md'
                            >
                                Update details
                            </ActionButton>
                            <ModalConsumer>
                                {({ showModal }) => (
                                    <ActionButton
                                        onClick={() => showModal(CompleteKycDialog, {
                                            customerId: customerId,
                                            onComplete: onComplete
                                        })}
                                        className={classes.submitButton}
                                        variant='primary'
                                        disabled={isVerified}
                                        size='md'
                                        style={{ marginLeft: '24px' }}
                                    >
                                        Complete KYC
                                    </ActionButton>
                                )}
                            </ModalConsumer>
                        </>
            }
        </DrawerModal>
    )
}

export default UpdateCustomerDrawer;