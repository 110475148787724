import React from "react";

function CustomersIcon({isSelected}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 -4 24 24"
        >
            <path
                fill={isSelected ? 'rgba(163, 162, 255, 1)' : "#EDEDEE"}
                fillRule="evenodd"
                d="M10.5 9.566h.104l.232.001c1.774.012 5.826.191 5.826 2.938 0 2.729-3.911 2.907-5.798 2.918h-.7c-1.773-.011-5.826-.19-5.826-2.934 0-2.732 4.053-2.91 5.827-2.922h.336zm0 1.378c-2.182 0-4.785.268-4.785 1.545 0 1.248 2.448 1.54 4.586 1.557h.2c2.182 0 4.784-.267 4.784-1.541 0-1.29-2.602-1.56-4.784-1.56zm7.307-1.75c2.225.333 2.693 1.378 2.693 2.197 0 .5-.197 1.412-1.51 1.912a.69.69 0 01-.49-1.288c.622-.237.622-.498.622-.624 0-.402-.512-.684-1.52-.834a.69.69 0 01-.58-.785.693.693 0 01.785-.578zm-13.83.578a.69.69 0 01-.579.785c-1.008.15-1.52.431-1.52.834 0 .126 0 .387.624.624a.689.689 0 11-.49 1.288C.696 12.802.5 11.89.5 11.391c0-.818.468-1.864 2.694-2.197a.693.693 0 01.784.578zM10.502 0a4.202 4.202 0 014.197 4.197A4.202 4.202 0 0110.5 8.394h-.025a4.153 4.153 0 01-2.957-1.236 4.15 4.15 0 01-1.215-2.964A4.2 4.2 0 0110.501 0zm0 1.378a2.822 2.822 0 00-2.819 2.82 2.786 2.786 0 00.814 1.989 2.79 2.79 0 001.981.83l.024.688v-.689a2.822 2.822 0 002.819-2.819 2.823 2.823 0 00-2.82-2.82zM16.186.9a3.317 3.317 0 012.788 3.285 3.346 3.346 0 01-2.866 3.292.69.69 0 01-.19-1.365 1.96 1.96 0 001.678-1.93c0-.957-.687-1.767-1.634-1.923A.69.69 0 0116.186.9zm-10.579.567a.69.69 0 01-.568.792 1.944 1.944 0 00-1.634 1.926 1.958 1.958 0 001.678 1.927.688.688 0 11-.19 1.365 3.345 3.345 0 01-2.866-3.29A3.318 3.318 0 014.815.9c.387-.06.73.194.792.567z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default CustomersIcon;
