import React from "react";

function MoreIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#EDEDEE"
        fillRule="evenodd"
        d="M6.176 11.588a1.588 1.588 0 11-3.176 0 1.588 1.588 0 013.176 0zm7.413 0a1.588 1.588 0 11-3.177 0 1.588 1.588 0 013.177 0zm5.824 1.589a1.588 1.588 0 100-3.177 1.588 1.588 0 000 3.177z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default MoreIcon;
