import { client } from "./graphql/client"
import { formatApolloError } from "./graphql/error";
import { getKycQuery } from "./graphql/kyc/queries";

export const kycDataProvider = {

    getOne: async(resource, params) => {
        const { customerId } = params

        try {
            const result = await client.query({
                query: getKycQuery,
                variables: { customerId: customerId }
            })

            const response = result.data
    
            return {
                data: {
                    id: customerId,
                    result: response
                }
            }
        } catch (e) {
            return Promise.resolve({
                data: {
                    id: -1,
                    error: formatApolloError(e)
                }
            });
        }
    }
}