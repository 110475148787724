import React from "react";

function CloseIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            fill="none"
            viewBox="0 0 14 14"
        >
            <path
                fill="#A3A2FF"
                fillRule="evenodd"
                d="M13.53 1.53A.75.75 0 0012.47.47L7 5.94 1.53.47A.75.75 0 00.47 1.53L5.94 7 .47 12.47a.75.75 0 101.06 1.06L7 8.06l5.47 5.47a.75.75 0 101.06-1.06L8.06 7l5.47-5.47z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default CloseIcon;
