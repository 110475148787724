import { TRANSACTION_CATEGORIES } from "./types";

export const formatCurrency = (amount, currency, hideSymbol=false, showSign=false) => {
    if (amount === null || typeof amount === 'undefined') return '-';
    const locale = currency === 'AUD' ? 'en-AU' : 'en-US';
    const evaluatedCurrency = isStableCoinCurrency(currency) ? getStableCoinCurrencyFormat() : currency
    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: evaluatedCurrency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 4,
        signDisplay: hideSymbol ? 'never' : 'auto' 
    });
    const sign = showSign && amount > 0 ? '+' : '';
    return hideSymbol ? formatter.formatToParts(amount)
        .map(p => p.type != 'literal' && p.type != 'currency' ? p.value : '').join('')
        : `${sign}${formatter.format(amount)}`;
}

// hack for now. not sure what currency format for stable coin
const getStableCoinCurrencyFormat = () => "USD"
const isStableCoinCurrency = (currency) => ["USDC", "USDT", "BUSD"].includes(currency)

export const formatPaymentData = (accountNumber, bsb) => {
    if (bsb !== null && accountNumber !== null) {
        return `${bsb}/${accountNumber}`
    } else {
        return "None"
    }
}

export const formatCustomerType = (customerType) => {
    switch(customerType) {
        case "TRUST":
            return 'Trust';
        case "COMPANY":
            return 'Company';
        case "SMSF":
            return 'Self Managed Super Fund';
        case 'INDIVIDUAL':
        default:
            return 'Individual';
    }
}

export const formatKycStatus = (kycStatus) => {
    switch(kycStatus) {
        case 'VERIFIED':
            return true;
        case 'NOT_VERIFIED':
        default:
            return false;
    }
}

export const formatDate = (date) => {
    if (!date) return '-';
    return new Date(date)?.toLocaleDateString('en-AU')?.replaceAll('/','-');
}

export const formatLongDate = (date) => {
    if (!date) return '-';
    return new Date(date)?.toLocaleDateString('en-AU',{  year: 'numeric', month: 'short', day: 'numeric' });
}

export const formatBsb = (bsb) => {
    if (!bsb) return '';
    return bsb.replace('-', ' ')
}

export const formatTransactionDescription = (category) => {
    if (!category) return '';
    switch (category) {
        case TRANSACTION_CATEGORIES.INTEREST:
            return 'Interest added';
        case TRANSACTION_CATEGORIES.WITHDRAWAL:
            return 'Money withdrawn';
        case TRANSACTION_CATEGORIES.DEPOSIT:
            return 'Money added';
        case TRANSACTION_CATEGORIES.REFUND:
            return 'Deposit refunded';
        case TRANSACTION_CATEGORIES.CANCEL:
            return 'Transaction canceled';
        default:
            return '';
    }
}

export const formatTimestamp = (date) => {
    if (!date) return '-';
    return new Date(date)?.toLocaleString('en-AU',{
        day: 'numeric',
        month: 'short',
        year: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true 
    })?.toLowerCase();
}

export const formatActivityTimestamp = (date) => {
    if (!date) return '-';
    const fullDate = new Date(date)?.toLocaleString('en-AU',{
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false
    })?.toLowerCase()?.replaceAll('/','-');
    const dateSection = fullDate.split(",")[0]
    const timeSection = fullDate.split(",")[1]
    return `${timeSection}  •  ${dateSection}`
}

export const formatShortTimestamp = (date) => {
    if (!date) return '-';
    return new Date(date)?.toLocaleString('en-AU',{
        day: 'numeric',
        month: 'short',
        hour12: true 
    })?.toLowerCase();
}