import { useState } from "react";
import { useCreate } from "react-admin";
import { CUSTOMER_NOTE_TYPES } from "../types";

export const useCreateNoteFormControls = (initValues) => {

    const [values, setValues] = useState({
        note: initValues.note || '',
        customerId: initValues.customerId,
        actorEmail: initValues.actorEmail,
    });

    const [errors, setErrors] = useState({});
    const [create] = useCreate('notes');

    const getValues = () => (values);

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        const { note } = fieldValues;

        temp.note = note ? '' : "This field is required."

        setErrors({
          ...temp
        });
    }

    const handleTextInputValue = (e) => {
        const { name, value } = e.target;
        setValues({
          ...values,
          [name]: value,
          isUpdated: true
        });
        validate({ [name]: value });
    }

    const handleFormSubmit = async (onComplete) => {
      const { note, customerId, actorEmail } = values;
      create({
          payload: {
            customerId,
            actor: actorEmail,
            note,
            noteType: CUSTOMER_NOTE_TYPES.MANUAL
          }
      }, {
        onSuccess: ({data}) => {
          const isSuccessful = data.error === undefined
          setTimeout(() => {
            if (data.error) {
                onComplete(false);
            } else {
                onComplete(isSuccessful);
            }
        }, 1000);
        },
        onFailure: ((e) => console.error(e))
      });
    }

    const formIsValid = (fieldValues = values) => {
      const isValid =
          fieldValues.note &&
          Object.values(errors).every((x) => x === '');
      return isValid;
    }

    return {
        getValues,
        handleTextInputValue,
        handleFormSubmit,
        formIsValid,
        inputErrors: errors
    };
}