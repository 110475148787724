import { CircularProgress, makeStyles, Zoom } from "@material-ui/core";

const CircularLoader = ({trigger, loaderSize, className}) => {
    const useStyles = makeStyles(() => ({
        animContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 'auto'
        },
        loader: {
            minWidth: loaderSize || '64px',
            minHeight: loaderSize || '64px',
        }
    }));
    const classes = useStyles();
    return (
        <div className={`${classes.animContainer} ${className}`}>
            <Zoom in={trigger}>
                <CircularProgress className={classes.loader} color='primary'/>
            </Zoom>
        </div>
    )
}

export default CircularLoader;