import { gql } from "@apollo/client";

export const createPayIdReceivablesMutation = gql`
  mutation createPayId ($input: CreatePayIdRequest!) {
    createPayId: createPayId_Admin(input: $input) {
        payId,
        payIdName
      }
    }
`

export const linkBankAccountMutation = gql`
  mutation linkBankAccount ($input: LinkBankAccountRequest_Admin!) {
      linkBankAccount: linkBankAccount_Admin(input: $input) {
        id,
        accountName,
        accountNumber,
        bsb,
        status,
        dateCreated,
        dateModified
      }
    }
`
