/* eslint-disable no-useless-computed-key */
import React from "react";
import {Sidebar, useLogout} from "react-admin";
import {makeStyles} from "@material-ui/core/styles";
import userAvatar from "../images/userAvatar.png"
import {Typography} from "@material-ui/core";
import MenuButton from "../button/menuButton";
import LogoutIcon from "../icons/logoutIcon";
import {useAuth0} from "@auth0/auth0-react";
import Role from "../../utils/role";

const DesktopSidebar = ({children}) => {
    const useCustomStyles = makeStyles((theme) => ({
        fixed: {
            width: '281px',
            alignItems: 'center',
        },
        divider: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4),
            width: '100%',
        },
        themeSwitch: {
            position: "fixed",
            bottom: "5px",
            left: "auto",
            marginLeft: '4px',
        },
        menuHeader: {
            display: "flex",
            justifyContent: "start",
            flexWrap: "nowrap",
            margin: `${theme.spacing(6)}px 24px ${theme.spacing(5)}px 24px`,
            flexDirection: "row",
            alignItems: "center",
        },
        sidebarToggle: {
            padding: "0px 0px 24px 0px",
        },
        logoIcon: {
            width: "56px",
            height: "56px",
            paddingRight: '18px'
        },
        roleLabel: {
            color: theme.text.primary,
            fontFamily: 'DM Sans',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '22px',
            lineHeight: '28px',
            letterSpacing: '-0.26px',
        },
        userEmailLabel: {
            color: 'rgba(119, 119, 123, 1)',
            fontFamily: 'DM Sans',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '13px',
            lineHeight: '18px',
            letterSpacing: '-0.08px'
        },
        userDetailsColumn: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'start',
        },
        bottomButton: {
            height: '56px',
            width: 'fill-available',
            paddingLeft: '24px',
            paddingRight: '24px',
            position: 'absolute',
            bottom: 32,
            '& > .MuiButton-text': {
                padding: '0px'
            },
        },
    }));
    const styles = useCustomStyles();
    const {isLoading: identityLoading, user} = useAuth0();
    const logout = useLogout();
    const roleType = Role.getDisplayableRole();
    return (
        <Sidebar className={styles.sidebar}>
            <div className={styles.menuHeader}>
                <img src={userAvatar} alt="" className={styles.logoIcon}/>
                <div className={styles.userDetailsColumn}>
                    <Typography variant="h5"
                                className={styles.roleLabel}>{identityLoading ? "....." : roleType}</Typography>
                    <Typography variant="h5"
                                className={styles.userEmailLabel}>{identityLoading ? "....." : user.email}</Typography>
                </div>
            </div>
            {children}
            <div className={styles.bottomButton}>
                <MenuButton icon={<LogoutIcon/>} label="Logout" onClick={() => logout()}/>
            </div>
        </Sidebar>
    );
};

export default DesktopSidebar;
