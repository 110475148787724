import React from "react";

function DepositIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#2F33DA"
        fillRule="evenodd"
        d="M10.001 14.541a.75.75 0 01-.75-.75V1.75a.75.75 0 011.5 0v12.041a.75.75 0 01-.75.75z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#2F33DA"
        strokeWidth="0.8"
        d="M10.001 14.541a.75.75 0 01-.75-.75V1.75a.75.75 0 011.5 0v12.041a.75.75 0 01-.75.75"
      ></path>
      <path
        fill="#2F33DA"
        fillRule="evenodd"
        d="M10 14.541a.746.746 0 01-.53-.221l-2.916-2.927a.751.751 0 011.062-1.059L10 12.729l2.385-2.395a.749.749 0 111.062 1.059l-2.916 2.927a.746.746 0 01-.531.221z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#2F33DA"
        strokeWidth="0.8"
        d="M10 14.541a.746.746 0 01-.53-.221l-2.916-2.927a.751.751 0 011.062-1.059L10 12.729l2.385-2.395a.749.749 0 111.062 1.059l-2.916 2.927a.746.746 0 01-.531.221"
      ></path>
      <mask
        id="mask0_1043_1443"
        style={{ maskType: "alpha" }}
        width="20"
        height="15"
        x="0"
        y="5"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M0 5.732h20v13.744H0V5.732z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask0_1043_1443)">
        <path
          fill="#2F33DA"
          fillRule="evenodd"
          d="M15.575 19.476H4.435A4.44 4.44 0 010 15.041v-4.885a4.43 4.43 0 014.426-4.424h.941a.75.75 0 010 1.5h-.941A2.927 2.927 0 001.5 10.156v4.885a2.938 2.938 0 002.935 2.935h11.14a2.93 2.93 0 002.925-2.925v-4.884a2.938 2.938 0 00-2.934-2.935h-.932a.75.75 0 010-1.5h.932A4.44 4.44 0 0120 10.167v4.884a4.43 4.43 0 01-4.425 4.425z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
}

export default DepositIcon;
