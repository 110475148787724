import {client} from "./graphql/client";
import {formatApolloError} from "./graphql/error";
import {
    getDepositActivityDetailQuery,
    getGlobalActivitiesQuery,
    getOnboardingActivityDetailQuery,
    getPaymentActivityDetailQuery,
    getRefundActivityDetailQuery,
    getSendReceivedActivityDetailQuery,
    getSentActivityDetailQuery,
    getWithdrawalActivityDetailQuery
} from "./graphql/activities/queries";
import {ACTIVITY_TYPES} from "../types";
import {getTransactionByOrderIdQuery} from "./graphql/transaction/queries";

export const DEFAULT_GLOBAL_ACTIVITIES_PAGE_SIZE = 7
export const DEFAULT_GLOBAL_ACTIVITIES_PAGES_TO_FETCH = 2;

export const globalActivitiesDataProvider = {
    getList: async (resource, params) => {
        try {
            const {searchTerm, activityStatus, activityType, pagination} = params;
            const result = await client.query({
                query: getGlobalActivitiesQuery,
                variables: {
                    searchParams: {
                        searchTerm: searchTerm || null,
                        searchTermField: "reference",
                        activityStatus: activityStatus || null,
                        activityType: activityType || null,
                        startDateEpoch: null,
                        endDateEpoch: null
                    },
                    pagination: {
                        first: pagination.first || DEFAULT_GLOBAL_ACTIVITIES_PAGE_SIZE,
                        offset: pagination.offset || DEFAULT_GLOBAL_ACTIVITIES_PAGES_TO_FETCH
                    }
                }
            });
            const activities = result.data["globalActivities_Admin"].items
            return {
                data: activities,
                total: activities.total || 0
            }
        } catch (e) {
            return Promise.reject({
                error: formatApolloError(e),
                data: [],
                total: 0
            });
        }

    },

    // TODO: implement when/if BFF has query to retrieve transaction by id
    getOne: async (resource, params) => {
        const {customerId, activityId, type, orderId} = params;
        let detailQuery
        let dataKey = ""
        switch (type) {
            case ACTIVITY_TYPES.DEPOSIT:
                detailQuery = getDepositActivityDetailQuery
                dataKey = 'getDepositActivityDetail_Admin'
                break
            case ACTIVITY_TYPES.WITHDRAWAL:
                detailQuery = getWithdrawalActivityDetailQuery
                dataKey = 'getWithdrawalActivityDetail_Admin'
                break
            case ACTIVITY_TYPES.PAYMENT:
                detailQuery = getPaymentActivityDetailQuery
                dataKey = 'getPaymentActivityDetail_Admin'
                break
            case ACTIVITY_TYPES.REFUND:
                detailQuery = getRefundActivityDetailQuery
                dataKey = 'getRefundActivityDetail_Admin'
                break
            case ACTIVITY_TYPES.ONBOARDING:
                detailQuery = getOnboardingActivityDetailQuery
                dataKey = 'getOnboardingActivityDetail_Admin'
                break
            case ACTIVITY_TYPES.SENT:
                detailQuery = getSentActivityDetailQuery
                dataKey = 'getSentActivityDetail_Admin'
                break
            case ACTIVITY_TYPES.RECEIVED:
                detailQuery = getSendReceivedActivityDetailQuery
                dataKey = 'getSendReceivedActivityDetail_Admin'
                break
            default:
                detailQuery = null
                dataKey = ""
        }
        try {
            if (detailQuery) {
                const activityDetailResult = await client.query({
                    query: detailQuery,
                    variables: {customerId, activityId}
                });
                const activity = activityDetailResult.data[dataKey]
                const transactionResult = orderId ? await client.query({
                    query: getTransactionByOrderIdQuery,
                    variables: {
                        orderId
                    }
                }) : null
                const transaction = transactionResult ? transactionResult.data["transactionByOrderId_Admin"] || null : null
                return {
                    data: {
                        id: activityId,
                        ...activity,
                        linkedTransaction: transaction
                    }
                }
            } else throw "Cannot determine query for this activity"
        } catch (error) {
            return Promise.reject(error);
        }
    },
}