import {gql} from "@apollo/client";

export const getGlobalActivitiesQuery = gql`
    query GlobalActivities_Admin($searchParams: ActivitySearchInput!, $pagination: PaginationInput!) {
      globalActivities_Admin(searchParams: $searchParams, pagination: $pagination) {
        items {
          id
          userId
          timestamp
          type
          reference
          iconUrl
          feedInfo {
            heading
            annotatedHeading
            subHeading
            annotatedSubHeading
            sideText
            annotatedSideText
            sideSubText
            annotatedSideSubText
          }
        }
      }
    }
`

export const getActivityFeedQuery = gql`
    query GetActivityFeedV2_Admin($customerId: String!, $pagination: PaginationInput!, $searchTerm: String) {
        getActivityFeedV2_Admin(customerId: $customerId, pagination: $pagination, searchTerm: $searchTerm) {
            items {
                id
                userId
                timestamp
                type
                reference
                iconUrl
                feedInfo {
                    heading
                    annotatedHeading
                    subHeading
                    annotatedSubHeading
                    sideText
                    annotatedSideText
                    sideSubText
                    annotatedSideSubText
                }
            }
        }
    }
`

export const getOnboardingActivityDetailQuery = gql`
    query GetActivityFeed_Admin($customerId: String!, $activityId: String!) {
        getOnboardingActivityDetail_Admin(customerId: $customerId, activityId: $activityId) {
            categoryName
            heading
            tagline
            iconUrl
        }
    }
`

export const getDepositActivityDetailQuery = gql`
    query GetDepositActivityDetail_Admin($customerId: String!, $activityId: String!) {
        getDepositActivityDetail_Admin(customerId: $customerId, activityId: $activityId) {
            categoryName
            heading
            tagline
            iconUrl
            status
            transactionDate
            reference
            transferMethodName
            transferMethodValue
            exchangeRateName
            exchangeRateValue
            blockchainNetwork
            blockchainScanLink
        }
    }
`

export const getWithdrawalActivityDetailQuery = gql`
    query GetWithdrawalActivityDetail_Admin($customerId: String!, $activityId: String!) {
        getWithdrawalActivityDetail_Admin(customerId: $customerId, activityId: $activityId) {
            categoryName
            heading
            tagline
            iconUrl
            status
            transactionDate
            reference
            transferMethodName
            transferMethodValue
            exchangeRateName
            exchangeRateValue
            blockchainNetwork
            totalFee
            sentAmount
            blockchainScanLink
        }
    }
`

export const getPaymentActivityDetailQuery = gql`
    query GetPaymentActivityDetail_Admin($customerId: String!, $activityId: String!) {
        getPaymentActivityDetail_Admin(customerId: $customerId, activityId: $activityId) {
            categoryName
            heading
            tagline
            iconUrl
            status
            transactionDate
            reference
            exchangeRateName
            exchangeRateValue
            cardLastFourDigits
            transactionFee
        }
    }
`

export const getRefundActivityDetailQuery = gql`
    query GetRefundActivityDetail_Admin($customerId: String!, $activityId: String!) {
        getRefundActivityDetail_Admin(customerId: $customerId, activityId: $activityId) {
            categoryName
            heading
            tagline
            iconUrl
            status
            transactionDate
            reference
            exchangeRateName
            exchangeRateValue
            cardLastFourDigits
        }
    }
`

export const getSendReceivedActivityDetailQuery = gql`
    query GetSendReceivedActivityDetail_Admin($activityId: String!) {
        getSendReceivedActivityDetail_Admin(activityId: $activityId) {
            categoryName
            heading
            annotatedHeading
            tagline
            annotatedTagline
            iconUrl
            transactionDate
            senderName
            blockchainNetwork
            annotatedBlockchainNetwork
            status
            blockchainScanLink
        }
    }
`

export const getSentActivityDetailQuery = gql`
    query GetSentActivityDetail_Admin($activityId: String!) {
        getSentActivityDetail_Admin(activityId: $activityId) {
            categoryName
            heading
            annotatedHeading
            tagline
            annotatedTagline
            iconUrl
            transactionDate
            recipientName
            blockchainNetwork
            annotatedBlockchainNetwork
            sentAmount
            transactionFee
            status
            blockchainScanLink
            reference
            transferMethodName
            transferMethodValue
            exchangeRateName
            exchangeRateValue
            totalFee
            totalCost
        }
    }
`
