import * as React from "react";
import {useEffect} from "react";
import {getResources, Menu, MenuItemLink} from "react-admin";
import {useSelector} from "react-redux";
import DefaultIcon from "@material-ui/icons/ViewList";
import {useMenuButtonStyles} from "../button/menuButton";
import {Typography} from "@material-ui/core";

const DashboardMenu = (props) => {
    const classes = useMenuButtonStyles();
    const resources = useSelector(getResources);
    const [selectedResource, setSelectedResource] = React.useState(resources[0]);
    const [initComplete, setInitComplete] = React.useState(false);
    useEffect(() => {
        if (initComplete || resources.length === 0) return
        setSelectedResource(resources[0])
        setInitComplete(true)
    }, [resources, initComplete])
    useEffect(() => {
        resources.forEach((resource) => {
            if (window.location.href.includes(`/#/${resource.name}`) && selectedResource?.name !== resource.name) {
                setSelectedResource(resource)
            }
        })
    }, [resources, window.location.href])
    return (
        <Menu {...props}>
            {resources.map((resource) => {
                const isSelected = selectedResource?.name === resource.name;
                return (<MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={<Typography variant="body1"
                                             className={classes.label}>{resource.options.label}</Typography>}
                    leftIcon={resource.icon ? <resource.icon isSelected={isSelected}/> : <DefaultIcon/>}
                    onClick={() => {
                        setSelectedResource(resource)
                        return props.onMenuClick
                    }}
                    classes={classes}
                />)
            })}
        </Menu>
    );
};

export default DashboardMenu;
