const Role = {
    member: 'Member',
    admin: 'Admin',
    none: 'User',

    getRoles: function() {
        const idToken = JSON.parse(sessionStorage.getItem('idToken'))
        if (idToken === null) {
            return Role.none
        }

        const allRoles = idToken[process.env.REACT_APP_ROLE_NAMESPACE]

        // extract all the toles
        return allRoles.map((roleString) => {
            switch (roleString) {
                case Role.member:
                   return Role.member
                case Role.admin:
                    return Role.admin
                default:
                    return Role.none
            }
        })
    },

    // method for showing the primary role. order matters
    getDisplayableRole: function() {
        if (this.hasAdminRole()) {
            return 'Stables Admin'
        } else if (this.hasMemberRole()) {
            return 'Stables User'
        } else {
            return 'User'
        }
    },

    // convenience operators 
    hasAdminRole: function() {
        return Role.getRoles().includes(Role.admin)
    },

    hasMemberRole: function() {
        return Role.getRoles().includes(Role.member)
    }
}


export const AdminRole = (props) => {
    return (
        <>
            {
                (Role.hasAdminRole()) &&
                    <div>{props.children}</div>
            }
        </>
    )
}

export const MemberRole = (props) => {
    return (
        <>
            {
                (Role.hasMemberRole()) &&
                    <div>{props.children}</div>
            }
        </>
    )
}

export const AuthorizationRole = (props) => {
    return (
        <>
        {
            (Role.hasAdminRole() || Role.hasMemberRole()) &&
                <div>{props.children}</div>
        }
        </>
    )
}

export default Role