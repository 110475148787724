import {client} from "./graphql/client";
import {formatApolloError} from "./graphql/error";
import {
    getActivityFeedQuery,
    getDepositActivityDetailQuery,
    getOnboardingActivityDetailQuery,
    getPaymentActivityDetailQuery,
    getRefundActivityDetailQuery,
    getSendReceivedActivityDetailQuery,
    getSentActivityDetailQuery,
    getWithdrawalActivityDetailQuery
} from "./graphql/activities/queries";
import {ACTIVITY_TYPES} from "../types";

export const activitiesDataProvider = {
    getList: async (resource, params) => {
        try {
            const {customerId, searchTerm, pagination, prevActivities, transactions} = params;
            const safeSearchTerm = searchTerm ? searchTerm : ""
            const result = await client.query({
                query: getActivityFeedQuery,
                variables: {
                    customerId: customerId,
                    searchTerm: safeSearchTerm,
                    pagination: pagination
                }
            });
            const activities = result.data["getActivityFeedV2_Admin"].items.concat(prevActivities).reduce((accumulator, current) => {
                let exists = accumulator.find(item => {
                    return item.id === current.id;
                });
                if (!exists) {
                    accumulator = accumulator.concat(current);
                }
                return accumulator;
            }, []);
            const enhancedActivities = activities.map(activity => {
                const transaction = transactions.find(transaction => {
                    return transaction.orderId === activity.reference || transaction.id === activity.reference
                })
                return {
                    ...activity,
                    linkedTransaction: transaction
                }
            })
            return {
                data: enhancedActivities,
                total: enhancedActivities.total
            }
        } catch (e) {
            return Promise.reject({
                error: formatApolloError(e),
                data: [],
                total: 0
            });
        }

    },

    // TODO: implement when/if BFF has query to retrieve transaction by id
    getOne: async (resource, params) => {
        const {customerId, activityId, type} = params;
        let detailQuery
        let dataKey = ""
        switch (type) {
            case ACTIVITY_TYPES.DEPOSIT:
                detailQuery = getDepositActivityDetailQuery
                dataKey = 'getDepositActivityDetail_Admin'
                break
            case ACTIVITY_TYPES.WITHDRAWAL:
                detailQuery = getWithdrawalActivityDetailQuery
                dataKey = 'getWithdrawalActivityDetail_Admin'
                break
            case ACTIVITY_TYPES.PAYMENT:
                detailQuery = getPaymentActivityDetailQuery
                dataKey = 'getPaymentActivityDetail_Admin'
                break
            case ACTIVITY_TYPES.REFUND:
                detailQuery = getRefundActivityDetailQuery
                dataKey = 'getRefundActivityDetail_Admin'
                break
            case ACTIVITY_TYPES.ONBOARDING:
                detailQuery = getOnboardingActivityDetailQuery
                dataKey = 'getOnboardingActivityDetail_Admin'
                break
            case ACTIVITY_TYPES.SENT:
                detailQuery = getSentActivityDetailQuery
                dataKey = 'getSentActivityDetail_Admin'
                break
            case ACTIVITY_TYPES.RECEIVED:
                detailQuery = getSendReceivedActivityDetailQuery
                dataKey = 'getSendReceivedActivityDetail_Admin'
                break
            default:
                detailQuery = null
                dataKey = ""
        }
        try {
            if (detailQuery) {
                const result = await client.query({
                    query: detailQuery,
                    variables: {customerId, activityId}
                });
                return {
                    data: {
                        id: activityId,
                        ...result.data[dataKey]
                    }
                }
            } else throw "Cannot determine query for this activity"
        } catch (error) {
            return Promise.reject(error);
        }
    },
}