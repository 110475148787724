import React from "react";

function WithdrawIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#337FFA"
        fillRule="evenodd"
        d="M9.999 5.459a.75.75 0 01.75.75V18.25a.75.75 0 01-1.5 0V6.209a.75.75 0 01.75-.75"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#337FFA"
        strokeWidth="0.8"
        d="M9.999 5.459a.75.75 0 01.75.75V18.25a.75.75 0 01-1.5 0V6.209a.75.75 0 01.75-.75"
      ></path>
      <path
        fill="#337FFA"
        fillRule="evenodd"
        d="M10 5.459c.198 0 .39.079.53.221l2.916 2.927a.751.751 0 01-1.062 1.059L10 7.271 7.614 9.666a.749.749 0 11-1.062-1.059L9.468 5.68A.746.746 0 0110 5.459"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#337FFA"
        strokeWidth="0.8"
        d="M10 5.459c.198 0 .39.079.53.221l2.916 2.927a.751.751 0 01-1.062 1.059L10 7.271 7.614 9.666a.749.749 0 11-1.062-1.059L9.468 5.68A.746.746 0 0110 5.459"
      ></path>
      <mask
        id="mask0_1449_314"
        style={{ maskType: "alpha" }}
        width="20"
        height="15"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M20 14.268H0V.524h20v13.744z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask0_1449_314)">
        <path
          fill="#337FFA"
          fillRule="evenodd"
          d="M4.425.524h11.14A4.44 4.44 0 0120 4.959v4.885a4.43 4.43 0 01-4.426 4.424h-.941a.75.75 0 010-1.5h.941A2.927 2.927 0 0018.5 9.844V4.959a2.938 2.938 0 00-2.935-2.935H4.425A2.93 2.93 0 001.5 4.949v4.884a2.938 2.938 0 002.934 2.935h.932a.75.75 0 010 1.5h-.932A4.44 4.44 0 010 9.833V4.949A4.43 4.43 0 014.425.524"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
}

export default WithdrawIcon;
