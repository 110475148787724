import {client} from "./graphql/client";
import {formatApolloError} from "../data/graphql/error";
import {getCustomerQuery, listCustomersQuery} from "./graphql/customer/queries";
import {closeCustomerMutation, updateCustomerMutation} from "./graphql/customer/mutations";

export const customerDataProvider = {

    getList: async(resource, params) => {
        try {        
            const { searchTerm, pagination } = params;
            // hacky way of stopping built in listContext from also calling, but without the pagination params
            if (typeof pagination.offset !== 'undefined' && pagination.first !== 'undefined')  {
                const result = await client.query({
                    query: listCustomersQuery,
                    variables: { searchTerm, pagination }
                });
                const customers = result.data.listCustomers
                return {
                    data: customers,
                    total: customers.length
                }
            } else return {
                data: [],
                total: 0
            }
        } catch (e) {
            return Promise.reject({
                error: formatApolloError(e),
                data: [],
                total: 0
            });
        }
        
    },

    getOne: async(resource, params) => {
        try {
            const customerId =  params.id
            const result = await client.query({
                query: getCustomerQuery,
                variables: { customerId }
            });
    
            const customer = result.data.customer;
            return {
                data: customer
            }
        } catch (e) {
            return Promise.reject({
                data: {
                    error: formatApolloError(e)
                }
            });
        }
    },

    delete: async(resource, params) => {
        try {
            const result = await client.mutate({
                mutation: closeCustomerMutation,
                variables: {
                    input: {
                        customerId: params.customerId
                    }
                }
            });
            await client.clearStore(); // forces cache reset for new queries

            return {
                data: result.data
            }
        } catch (e) {
            return Promise.resolve({
                data: {
                    id: -1,
                    error: formatApolloError(e)
                }
            });
        }
    },

    update: async(resource, params) => {
        try {
            const result = await client.mutate({
                mutation: updateCustomerMutation,
                variables: { 
                    input: params
                }
            });
            await client.clearStore(); // forces cache reset for new queries

            return {
                data: result.data.updateCustomer
            }
        } catch (e) {
            return Promise.resolve({
                data: {
                    id: -1,
                    error: formatApolloError(e)
                }
            });
        }
    }
}