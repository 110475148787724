import React from "react";

function LockActionIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
    >
      <circle cx="18" cy="18" r="18" fill="#8482FF" fillOpacity="0.2"></circle>
      <path
        fill="#A3A2FF"
        fillRule="evenodd"
        d="M18.375 8.002a5.306 5.306 0 015.298 5.301v1.577a4.55 4.55 0 013.052 4.29v4.29a4.546 4.546 0 01-4.542 4.542h-7.641A4.546 4.546 0 0110 23.46v-4.29a4.55 4.55 0 013.051-4.29v-1.577a5.278 5.278 0 011.569-3.765c1.006-.997 2.334-1.574 3.755-1.536zm3.808 8.127h-7.641A3.045 3.045 0 0011.5 19.17v4.289a3.045 3.045 0 003.042 3.042h7.641a3.045 3.045 0 003.042-3.042v-4.29a3.045 3.045 0 00-3.042-3.041zm-3.82 3.326a.75.75 0 01.75.75v2.22a.75.75 0 01-1.5 0v-2.22a.75.75 0 01.75-.75zm.009-9.953h-.016c-1.013 0-1.962.39-2.68 1.101a3.768 3.768 0 00-1.124 2.683l-.001 1.342h7.621v-1.325a3.805 3.805 0 00-3.8-3.801z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default LockActionIcon;