import { makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import { IconButton, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import { useEffect, useState } from "react";
import GenericError from "../error/genericError";
import CircularLoader from "../loading/circularLoader";
import { useDataProvider } from "react-admin";
import LinkIcon from "../icons/linkIcon";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor: theme.surface.secondary,
    },
    content: {
        width: '-webkit-fill-available',
        padding: '24px'
    },
    header: {
        paddingBottom: '8px'
    },
    container: {

    },
    fieldDiv: {
        marginTop: '12px',
    },
    iconButton: {
        marginTop: '12px',
        marginRight: '-12px'
    },
    detailsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    fieldHeading: {
        marginBottom: '8px'
    }
}));


const kycStateMappings = {
    "APPLICATION_CREATED": "In Progress - Details Submitted",
    "APPLICATION_COMPLETE": "Complete",
    "APPLICATION_REJECTED": "Rejected",
    "APPLICATION_CANCELLED": "Cancelled",
    "APPLICATION_SUSPENDED": "Suspended"
}

const VerificationDetailsCard = ({ customerData }) => {
    const customerId = customerData.id

    const classes = useStyles();
    const [data, setData] = useState()
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const dataProvider = useDataProvider();

    useEffect(() => {
        if (data == null) {
            setLoading(true)
            dataProvider.getOne('kyc', { customerId })
                .then(({ data }) => {
                    if (data.error) throw data.error;
                    else {
                        setData(data?.result?.getKycApplication);
                        setError(null)
                    }
                }).catch((error) => {
                    setError(error);
                }).finally(() => {
                    setLoading(false)
                })
        }
    }, [data, customerId, dataProvider])

    return (
        <Card elevation={0} className={classes.root}>
            <CardContent className={classes.content}>
                <Typography className={classes.header} variant="h5">Verification</Typography>
                {
                    error ? <div className={classes.container}><GenericError onClick={() => { setData(null); }}></GenericError></div> :
                        loading ? <div className={classes.container}><CircularLoader trigger={loading} loaderSize='20px' /></div> :
                            <div className={classes.container}>
                                {
                                    data == null && customerData?.kycStatus === "VERIFIED" ? <VerificationField title={"Status"} value={"Complete [LEGACY]"} /> :
                                    // not started
                                    data == null ? <VerificationField title={"Status"} value={"Not Started"} /> :
                                        // individual  
                                        data.type === "INDIVIDUAL" ? <VerificationDetailsIndividual data={data} /> :
                                            // company  
                                            data.type === "COMPANY" ? <VerificationDetailsCompany data={data} /> :
                                                <VerificationDetailsOther data={data} />
                                }
                            </div>
                }


            </CardContent>
        </Card >
    )
}

export default VerificationDetailsCard

const VerificationDetailsIndividual = ({ data }) => {
    const classes = useStyles();

    var applicantId = data?.details?.individual?.applicantId
    // TODO: figure out if we should show check result or if this is sufficient?
    // this might be confusing if the user hasn't completed a check yet
    const url = `https://dashboard.onfido.com/applicant/${applicantId}/details`

    function open() {
        const win = window.open(url, '_blank');
        if (win != null) {
            win.focus();
        }
    }

    return (
        <div className={classes.detailsContainer}>

            <VerificationField title={"Status"} value={kycStateMappings[data.status]} />

            {applicantId != undefined && (
                <IconButton
                    className={classes.iconButton}
                    onClick={() => { open() }}
                >
                    <LinkIcon />
                </IconButton>

            )
            }
        </div>
    )
}

const VerificationDetailsCompany = ({ data }) => {
    const classes = useStyles();

    return (
        <div className={classes.detailsContainer}>
             <VerificationField title={"Status"} value={kycStateMappings[data.status]} />
        </div>
    )
}


const VerificationDetailsOther = ({ data }) => {
    const classes = useStyles();

    return (
        <div className={classes.detailsContainer}>
            <VerificationField title={"Status"} value={kycStateMappings[data.status]} />
        </div>
    )
}

const VerificationField = ({ title, value }) => {
    const classes = useStyles();

    return (
        <div className={classes.fieldDiv}>
            <Typography className={classes.fieldHeading} variant="subtitle2">{title}</Typography>
            <Typography variant="body1">{value}</Typography>
        </div>
    )
}