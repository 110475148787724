import {gql} from "@apollo/client";

export const listTransactionsQuery = gql`
    query AllTransactions_Admin($accountId: String!) {
        allTransactions_Admin(accountId: $accountId) {
            id
            timestamp
            amount
            type,
            category,
            reference,
            accountId,
            currency,
            description,
            orderId,
            paymentData {
              accountNumber
              bsb
              payId
            }
        }
    }
`

export const getTransactionByOrderIdQuery = gql`
    query TransactionByOrderId_Admin($orderId: String!) {
      transactionByOrderId_Admin(orderId: $orderId) {
        id
        timestamp
        amount
        type,
        category,
        reference,
        accountId,
        globalCurrency,
        description,
        orderId,
        paymentData {
          accountNumber
          bsb
          payId
        }
      }
    }
`