import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";

import ActionButton from "../button/actionButton";
import { Typography } from "@material-ui/core";
import SuccessTick from "../success/successTick";
import CircularLoader from "../loading/circularLoader";
import GenericError from "../error/genericError";
import TextArea from "../input/textArea";
import { useAuth0 } from "@auth0/auth0-react";
import LockActionIcon from "../icons/lockActionIcon";
import ActionsRowItem from "../row/actionRowItem";
import {useUpdate, useDelete} from "react-admin";
import BackIcon from "../icons/backIcon";
import { useLockCustomerNoteFormControls } from "../../utils/formControls/lockCustomerNoteFormControls";
import CloseActionIcon from "../icons/closeActionIcon";

const useSelectActionStyles = makeStyles((theme) => ({
  root: {
    top: "50%",
    left: "50%",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    width: "416px",
    height: "706px",
    borderRadius: "20px",
    background: theme.background.dialog,
  },
  backdrop: {
    ["@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))"]:
      {
        "-webkit-backdrop-filter": "blur(2em)",
        backdropFilter: "blur(2em)",
        backgroundColor: "rgb(0 0 0 / 33%) !important",
      },
  },
  cardContent: {
    height: "90%",
    margin: "32px !important",
    padding: "0 !important",
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(4),
    right: theme.spacing(3),
  },
  content: {
    height: "104%",
    display: "flex",
    flexDirection: "column",
    marginTop: "42px",
  },
  actionsContainer: {
    marginTop: "40px",
  },
  upperContent: {
    display: "flex",
    flexDirection: "column",
    alignContent: "stretch",
  },
  heading: {
    marginTop: "40px",
  },
  resultContainer: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    height: "100%",
  },
  loaderLabel: {
    position: "absolute",
    top: "60%",
  },
}));

const ACTION_TYPES = {
  SELECT_ACTION: "SELECT_ACTION",
  LOCK_CUSTOMER: "LOCK_CUSTOMER",
  CLOSE_CUSTOMER: "CLOSE_CUSTOMER",
  UNLOCK_CUSTOMER: "UNLOCK_CUSTOMER",
};

export const MoreActionsDialog = ({
  onClose,
  onComplete,
  customerId,
  customerClosed,
  customerLocked,
}) => {
  const classes = useSelectActionStyles();
  const [viewMode, setViewMode] = useState(ACTION_TYPES.SELECT_ACTION);
  return (
    <Modal
      open={true}
      onClose={() => {
        onClose();
      }}
      BackdropProps={{ className: classes.backdrop }}
    >
      <Fade in={true}>
        <Card className={classes.root}>
          <CardContent className={classes.cardContent}>
            {viewMode === ACTION_TYPES.SELECT_ACTION ? (
              <SelectActionContent
                onClose={onClose}
                customerLocked={customerLocked}
                customerClosed={customerClosed}
                onSelect={(action) => setViewMode(action)}
              />
            ) : viewMode === ACTION_TYPES.LOCK_CUSTOMER ? (
              <UpdateCustomerStatusContent
                onBack={() => setViewMode(ACTION_TYPES.SELECT_ACTION)}
                onClose={onClose}
                onComplete={onComplete}
                customerId={customerId}
                heading={"Lock customer"}
                btnLabel={"Lock account"}
                updateStatus={"SUSPENDED"}
                baseNote="Customer account locked"
              />
            ) : viewMode === ACTION_TYPES.UNLOCK_CUSTOMER ? (
              <UpdateCustomerStatusContent
                onBack={() => setViewMode(ACTION_TYPES.SELECT_ACTION)}
                onClose={onClose}
                onComplete={onComplete}
                customerId={customerId}
                heading={"Any notes?"}
                btnLabel={"Unlock account"}
                updateStatus={"ACTIVE"}
                baseNote="Customer account unlocked"
              />
            ) : viewMode === ACTION_TYPES.CLOSE_CUSTOMER ? (
                <UpdateCustomerStatusContent
                    onBack={() => setViewMode(ACTION_TYPES.SELECT_ACTION)}
                    onClose={onClose}
                    onComplete={onComplete}
                    customerId={customerId}
                    heading={"Any notes?"}
                    btnLabel={"Close account"}
                    updateStatus={"CLOSED"}
                    baseNote="Customer account closed"
                />
            ) : (
              <SelectActionContent // default in case of error
                onClose={onClose}
                customerLocked={customerLocked}
                onSelect={(action) => setViewMode(action)}
              />
            )}
          </CardContent>
        </Card>
      </Fade>
    </Modal>
  );
};

const SelectActionContent = ({ onClose, customerLocked,customerClosed, onSelect }) => {
  const classes = useSelectActionStyles();
  return (
    <>
      <ActionButton
        variant="secondary"
        P
        size="lg"
        className={classes.closeButton}
        onClick={() => {
          onClose();
        }}
      >
        Close
      </ActionButton>
      <div className={classes.content}>
        <Typography variant="h3" className={classes.heading}>
          More actions
        </Typography>
        <div className={classes.actionsContainer}>
          {customerLocked ? (
            <ActionsRowItem
              onClick={() => onSelect(ACTION_TYPES.UNLOCK_CUSTOMER)}
              icon={<LockActionIcon />}
              heading="Unlock customer"
              description="Re-enable customer"
            />
          ) : (
            <ActionsRowItem
              onClick={() => onSelect(ACTION_TYPES.LOCK_CUSTOMER)}
              icon={<LockActionIcon />}
              heading="Lock customer"
              description="Customer will be unable to use account."
            />
          )}
          {!customerClosed && (
            <ActionsRowItem
              onClick={() => onSelect(ACTION_TYPES.CLOSE_CUSTOMER)}
              icon={<CloseActionIcon />}
              heading="Close account"
              description="This cannot be undone."
            />
          )}
        </div>
      </div>
    </>
  );
};

const useLockCustomerContentStyles = makeStyles((theme) => ({
  root: {
    top: "50%",
    left: "50%",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    width: "416px",
    height: "706px",
    borderRadius: "20px",
    background: theme.background.dialog,
  },
  navRow: {
    display: "flex",
    justifyContent: "space-between",
    width: "-webkit-fill-available",
  },
  backArrow: {
    cursor: "pointer",
    position: "absolute",
    top: theme.spacing(4),
    left: theme.spacing(4),
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(4),
    right: theme.spacing(3),
  },
  cardContent: {
    height: "90%",
    margin: "32px !important",
    padding: "0 !important",
  },
  success: {
    height: "95%",
    marginLeft: "24px",
  },
  backdrop: {
    ["@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))"]:
      {
        "-webkit-backdrop-filter": "blur(2em)",
        backdropFilter: "blur(2em)",
        backgroundColor: "rgb(255 255 255 / 1%) !important",
      },
  },
  content: {
    height: "104%",
    display: "flex",
    flexDirection: "column",
    marginTop: "24px",
    justifyContent: "space-between",
  },
  upperContent: {
    display: "flex",
    flexDirection: "column",
    alignContent: "stretch",
  },
  conversionContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: "16px",
  },
  input: {
    margin: "0px !important",
  },
  confirmButton: {
    height: "50px !important",
    marginBottom: "40px",
    "& > .MuiButton-label": {
      height: "32px",
      fontSize: "16px",
      lineHeight: "21px",
      letterSpacing: "-0.31px",
      fontStyle: "normal",
      fontWeight: 600,
    },
  },
  resultContainer: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    height: "100%",
  },
  loaderLabel: {
    position: "absolute",
    top: "60%",
  }
}));

export const UpdateCustomerStatusContent = ({
  onBack,
  heading,
  btnLabel,
  updateStatus,
  onClose,
  onComplete,
  baseNote,
  customerId
}) => {
  const classes = useLockCustomerContentStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const [update] = useUpdate("customers");
  const [deleteOne] = useDelete("customers");
  const [submitRequested, setSubmitRequested] = useState(false);
  const { isLoading: identityLoading, user } = useAuth0();
  const actorEmail = user?.email;

  const {
    getValues,
    handleTextInputValue,
    handleFormSubmit,
    formIsValid,
    inputErrors,
  } = useLockCustomerNoteFormControls({
    customerId,
    actorEmail,
    note: baseNote,
  });

  const currentValues = getValues();

  const submitCallback = (success) => {
    setLoading(false);
    if (success) {
      setSuccess(true);
      setError(false);
      onComplete();
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (loading && submitRequested) {
      if (updateStatus === "CLOSED") {
        deleteOne(
            {
              payload: {
                customerId: customerId
              },
            },
            {
              onSuccess: ({data}) => {
                const isSuccessful = data.error === undefined;
                if (isSuccessful) handleFormSubmit(submitCallback);
                else submitCallback(false);
              },
              onFailure: (e) => {
                console.error(e);
                submitCallback(false);
              },
            }
        )
      } else {
        update(
            {
              payload: {
                id: customerId,
                status: updateStatus,
              },
            },
            {
              onSuccess: ({data}) => {
                const isSuccessful = data.error === undefined;
                if (isSuccessful) handleFormSubmit(submitCallback);
                else submitCallback(false);
              },
              onFailure: (e) => {
                console.error(e);
                submitCallback(false);
              },
            }
        );
      }
    }
  }, [submitRequested]);

  return (
    <>
      <div className={classes.navRow}>
        { !(loading || success) && <BackIcon className={classes.backArrow} onClick={() => onBack()}/> }
        <ActionButton
          variant="secondary"
          P
          size="lg"
          className={classes.closeButton}
          onClick={() => {
            onClose();
          }}
        >
          Close
        </ActionButton>
      </div>
      <div className={classes.content}>
        {error ? (
          <div className={classes.resultContainer}>
            <GenericError
              onClick={() => {
                setError(false);
                setLoading(true);
                handleFormSubmit(submitCallback);
              }}
            />
          </div>
        ) : success ? (
          <div className={classes.resultContainer}>
            <SuccessTick trigger={success} tickSize="175px" />
          </div>
        ) : loading ? (
          <div className={classes.resultContainer}>
            <CircularLoader trigger={loading} loaderSize="125px" />
          </div>
        ) : (
          <div className={classes.content}>
            <div className={classes.upperContent}>
              <Typography variant="h3">{heading}</Typography>
              <div className={classes.conversionContainer}>
                <TextArea
                  className={classes.input}
                  name='additionalContext'
                  type="text"
                  value={currentValues.additionalContext}
                  InputLabelProps={{
                    shrink: false,
                    required: true,
                    disableAnimation: true,
                  }}
                  onChange={handleTextInputValue}
                  placeholder="Add note"
                  {...(inputErrors['additionalContext'] && { error: true, helperText: inputErrors['additionalContext'] })}
                />
              </div>
            </div>
            <ActionButton
              variant="primary"
              size="lg"
              className={classes.confirmButton}
              disabled={identityLoading || !formIsValid()}
              onClick={() => {
                setLoading(true);
                setSubmitRequested(true);
              }}
            >
              {btnLabel}
            </ActionButton>
          </div>
        )}
      </div>
    </>
  );
};
