import React, { useState, useEffect, useMemo } from "react";
import { ThemeProvider } from '@material-ui/styles';

import { dashboardTheme } from "./dashboardTheme";

export const DashboardThemeContext = React.createContext({
  toggleColorMode: () => {},
});

export const DashboardThemeProvider = ({ children }) => {
  const prefersDarkMode = true // useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = useState(prefersDarkMode ? 'dark' : 'light');
  const [override, setOverride] = useState(false);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setOverride(true);
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  useEffect(() => {
    override || setMode(prefersDarkMode ? 'dark' : 'light');
  }, [mode, prefersDarkMode, override])

  const theme = useMemo(() => dashboardTheme(mode), [mode]);

  return (
    <DashboardThemeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </DashboardThemeContext.Provider>
  );
};