import { useState } from "react";
import { useUpdate } from "react-admin";
import validator from 'validator';

export const useCustomerFormControls = (initValues) => {

    const showCustomerId = initValues?.customerId !== null && typeof initValues?.customerId !== 'undefined' ? { customerId: initValues.customerId } : { };
    const [values, setValues] = useState({
        customerType: initValues?.customerType || 'INDIVIDUAL',
        date: initValues?.date || '',
        name: initValues?.name || '',
        phoneNumber: initValues?.phoneNumber || '',
        email: initValues?.email || '',
        kycRef: initValues?.kycRef || '',
        isVerified:  initValues?.isVerified !== null && typeof initValues?.isVerified !== 'undefined' ? initValues.isVerified : true,
        isUpdated: false,
        address: initValues?.address ? {
          formattedAddress: initValues?.address?.formattedAddress || '',
          id: initValues?.address?.id,
          structuredAddress: initValues?.address?.structuredAddress != undefined ? {
            addressLine1: initValues?.address?.structuredAddress.addressLine1,
            addressLine2: initValues?.address?.structuredAddress.addressLine2,
            country: initValues?.address?.structuredAddress.country,
            locality: initValues?.address?.structuredAddress.locality,
            postalCode: initValues?.address?.structuredAddress.postalCode,
            state: initValues?.address?.structuredAddress.state
          } : undefined
        } : null,
        ...showCustomerId
    });

    const [errors, setErrors] = useState({});
    const [update] = useUpdate('customers');

    const getValues = () => (values);

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        const { customerType, name, phoneNumber, email, kycRef, address } = fieldValues;

        if (customerType)
          temp.customerType = customerType ? validator.isAlpha(customerType, 'en-AU', {ignore: ' -'}) ? '' : 'Please enter a valid customer type.' : "This field is required."

        if (name)
          temp.name = name ? validator.isAlphanumeric(name, 'en-AU', {ignore: ' -.'}) ? '' : 'Please enter a valid name.' : "This field is required."
              
        if (phoneNumber)
            temp.phoneNumber = phoneNumber ? validator.isMobilePhone(phoneNumber) ? '' : 'Please enter a valid phone number.' : "This field is required."

        if (email)
            temp.email = email ? validator.isEmail(email) ? '' : 'Please enter a valid email.' : "This field is required."

        if (kycRef)
            temp.kycRef = kycRef ? "" : "This field is required."

        setErrors({
          ...temp
        });
    }

    const handleTextInputValue = (e) => {
        const { name, value } = e.target;
        setValues({
          ...values,
          [name]: value,
          isUpdated: true
        });
        validate({ [name]: value });
    }

    const handleSwitchInputValue = (e) => {
        const { name, checked } = e.target;
        setValues({
          ...values,
          [name]: checked,
          isUpdated: true
        });
        validate({ [name]: checked });
    }

    const handleAddressInputValue = (e) => {
      const { address, id } = e
      setValues({
        ...values,
        address: {
          formattedAddress: address,
          id: id,
          // clear manual structuredAddress, when autofilled
          // since we don't currently support manual structured input
          structuredAddress: null
        },
        isUpdated: true
      });
      validate();
    }

    const handleUpdateFormSubmit = async (onComplete) => {
      update({
        payload: {
          id: values.customerId,
          name: values.name,
          phone: values.phoneNumber,
          email: values.email,
          customerType: values.customerType,
          kycRef: values.kycRef,
          kycStatus: values.isVerified ? 'VERIFIED' : 'NOT_VERIFIED',
          address: values.address
        }
      }, {
        onSuccess: ({data}) => {
          const isSuccessful = data.error === undefined;
          setTimeout(() => onComplete(isSuccessful), 1000);
        },
        onFailure: ((e) => console.error(e))
      });
  }

    const updateFormIsValid = (fieldValues = values) => {
      const isValid =
          fieldValues.customerId &&
          fieldValues.customerType &&
          fieldValues.name &&
          fieldValues.phoneNumber &&
          fieldValues.email &&
          fieldValues.kycRef &&
          fieldValues.isUpdated &&
          Object.values(errors).every((x) => x === '');
      return isValid;
  }

    return {
        getValues,
        handleTextInputValue,
        handleSwitchInputValue,
        handleAddressInputValue,
        handleUpdateFormSubmit,
        updateFormIsValid,
        errors
    };
}