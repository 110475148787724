import React from "react";

function RetryIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="13"
            fill="none"
            viewBox="0 0 14 13"
        >
            <path
                fill="#A3A2FF"
                d="M13.456 6.828a.318.318 0 00-.229.068.29.29 0 00-.108.204 4.14 4.14 0 01-.059.424 5.795 5.795 0 01-1.74 3.157 6.396 6.396 0 01-3.35 1.638c-.149.027-.3.047-.451.057-1.873.18-3.731-.444-5.06-1.7C1.127 9.42.468 7.665.661 5.899c.011-.133.03-.27.059-.427A5.811 5.811 0 012.46 2.32 6.413 6.413 0 016.263.622v.001c1.24-.119 2.49.113 3.588.67a6.096 6.096 0 012.58 2.448l-2.794-.566c-.17-.034-.337.067-.373.228-.036.16.071.317.24.351l3.48.704.008.002h.003c.054.009.11.004.162-.013a.168.168 0 00.032-.018.297.297 0 00.06-.032l.03-.031a.274.274 0 00.039-.044.311.311 0 00.02-.046c.008-.015.014-.03.02-.045v-.004l.002-.006.635-3.303c.03-.16-.083-.315-.254-.343a.311.311 0 00-.364.238l-.481 2.503A6.698 6.698 0 0010.072.731 7.216 7.216 0 006.206.034a7.04 7.04 0 00-4.19 1.87A6.39 6.39 0 00.104 5.369c-.03.158-.053.317-.066.477-.214 1.942.51 3.87 1.973 5.252 1.463 1.381 3.506 2.067 5.565 1.867.171-.013.341-.035.51-.066a7.022 7.022 0 003.679-1.803 6.37 6.37 0 001.914-3.47 4.6 4.6 0 00.067-.481c.013-.163-.116-.305-.289-.318z"
            ></path>
        </svg>
    );
}

export default RetryIcon;
