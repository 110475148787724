
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core"
import { TRANSACTION_TYPES, TRANSACTION_STATUS_LABELS } from "../../utils/types";

const LabelChip = ({label, variant, className, ...props}) => {
    const theme = useTheme();
    let labelColor;
    let fontSize;
    let fontWeight;
    let background = '#D6D6DF05'
    let textOpacity = 1.0
    switch (variant?.toString().toUpperCase()) {
        case TRANSACTION_STATUS_LABELS.CANCEL:
        case TRANSACTION_STATUS_LABELS.REFUND:
        case 'INCOMPLETE':
            background = '#BC87003D'
            labelColor = theme.text.infoLabel.failed;
            fontSize = '12px';
            fontWeight = 400;
            break;
        case TRANSACTION_TYPES.PENDING_CREDIT:
        case TRANSACTION_TYPES.PENDING_DEBIT:
        case "PENDING":
            background = '#BC87003D'
            labelColor = theme.text.infoLabel.pending;
            fontSize = '12px';
            fontWeight = 400;
            break;
        case TRANSACTION_TYPES.CREDIT:
        case TRANSACTION_TYPES.DEBIT:
        case "COMPLETED":
            labelColor = theme.text.infoLabel.completed;
            fontSize = '12px';
            textOpacity = 0.2
            fontWeight = 400;
            break;
        case 'secondary':
            labelColor = theme.text.infoLabel.secondary;
            fontSize = '12px';
            fontWeight = 400;
            break;
        case 'primary':
        default:
            labelColor = theme.text.infoLabel.primary;
            fontSize = '12px';
            fontWeight = 400;
            break;
    }
    const useStyles = makeStyles((theme) => ({
        root: {
            background: background,
            borderRadius: '34px',
            color: labelColor,
            '& > span': {
                opacity: textOpacity
            },
            fontFamily: 'DM Sans',
            fontStyle: 'normal',
            lineHeight: '16px',
            fontWeight: fontWeight,
            fontSize: fontSize,
        },
      }));
    const classes = useStyles();
    return (
        <div className={className}><Chip className={classes.root} label={label} {...props}/></div>
    );
};

export default LabelChip;
