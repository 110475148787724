import React from "react";

function NoteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <rect width="40" height="40" fill="#36363B" rx="20"></rect>
      <path
        fill="#FDFDFD"
        fillRule="evenodd"
        d="M20.749 10.17a.75.75 0 00-1.498-.09c-.047.787-.104 1.574-.166 2.362-.966.031-1.93.184-2.838.54a4.062 4.062 0 00-1.335.87c-.39.387-.761.94-.786 1.62-.026.684.068 1.308.433 1.845.358.526.903.858 1.53 1.124.77.325 1.555.615 2.343.883a471.735 471.735 0 00-.331 3.341c-.083.87-.203 1.738-.335 2.615a96.53 96.53 0 00-.646-.019c-1.035-.028-2.011-.054-2.973-.246a.75.75 0 10-.294 1.47c1.093.22 2.277.251 3.368.281l.316.009a52.025 52.025 0 00-.409 3.157.75.75 0 101.494.136c.097-1.07.255-2.14.422-3.227.764.03 1.616.034 2.456-.067 1.06-.127 2.167-.427 3.074-1.104.995-.743 1.97-2.129 1.781-3.638-.131-1.052-.83-1.701-1.53-2.105-.616-.356-1.336-.58-1.866-.744a170.393 170.393 0 01-2.913-.907c.145-1.43.288-2.866.415-4.302.96.072 1.944.232 2.918.391a.75.75 0 10.242-1.48l-.148-.025c-.889-.146-1.873-.307-2.887-.382.061-.77.116-1.54.163-2.308zm-1.791 3.776c-.757.038-1.484.166-2.163.433-.272.107-.587.3-.826.537-.246.244-.339.459-.344.612-.02.525.063.781.175.946.118.174.349.363.874.585.63.267 1.268.507 1.913.732.13-1.283.257-2.564.37-3.845zm.933 5.855a441.124 441.124 0 00-.297 3.006c-.08.854-.197 1.703-.322 2.541.675.023 1.375.017 2.05-.063.928-.112 1.742-.36 2.355-.817.758-.566 1.286-1.48 1.19-2.25-.05-.4-.302-.71-.791-.992-.463-.267-1.021-.441-1.574-.614l-.157-.05c-.389-.122-.781-.243-1.176-.364a214.1 214.1 0 01-1.278-.397z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default NoteIcon;
