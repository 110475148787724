export const capitalizeFirstLetter = (string) => {
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
}

export const interleave = (arr1, arr2) => arr1.reduce((combArr, elem, i) => combArr.concat(elem, arr2[i]), []);

export const initializeArray = (n, defaultValue) => {
  var newArray = new Array(n);
  for (var i = 0; i < n; i++) {
    newArray[i] = defaultValue;
  }
  return newArray;
}

export function randstr(prefix) {
  return Math.random().toString(36).replace('0.',prefix || '');
}