import React from "react";

function PaymentActivityIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <rect width="40" height="40" fill="#141417" rx="20"></rect>
      <path
        fill="#EDEDEE"
        fillRule="evenodd"
        d="M16.672 11.809c-.384.712-.522 1.597-.522 2.191v.3h4.7c-.004-.739-.035-1.637-.329-2.371-.154-.386-.37-.695-.667-.91-.293-.212-.717-.369-1.354-.369-.9 0-1.459.475-1.828 1.159zM14.85 17.5v-1.8h-.692c-.136 1.529-.458 5.357-.458 7.3 0 1.812.282 4.189.429 5.3h9.674l-.022-.13a32.416 32.416 0 01-.43-5.17V15.7h-1.201V17.5a.65.65 0 11-1.3 0V15.7h-4.7v1.8a.65.65 0 11-1.3 0zm0-3.5v.3h-.785c-.67 0-1.233.508-1.293 1.18-.129 1.439-.472 5.46-.472 7.52 0 1.919.295 4.386.442 5.494a1.39 1.39 0 001.38 1.206h11.546c.803 0 1.516-.569 1.646-1.392A31.75 31.75 0 0027.7 23.5c0-2.37-.317-6.25-.456-7.837a1.494 1.494 0 00-1.49-1.363H24.6c-.002-.743-.03-1.865-.418-2.835-.218-.545-.56-1.072-1.096-1.46-.54-.39-1.232-.605-2.086-.605-.403 0-.772.063-1.108.18a3.97 3.97 0 00-1.392-.23c-1.5 0-2.441.858-2.972 1.841-.516.955-.678 2.07-.678 2.809zm6.417-3.39c.195.262.346.547.462.836.392.98.42 2.11.42 2.854h1.25c-.003-.74-.034-1.646-.331-2.39-.157-.392-.378-.71-.685-.931-.26-.188-.615-.332-1.116-.37zm3.857 17.69h.544a.257.257 0 00.263-.21c.15-.951.369-2.668.369-4.59 0-2.299-.31-6.117-.45-7.715a.094.094 0 00-.097-.085H24.65V23a31.122 31.122 0 00.468 5.269l.006.031z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default PaymentActivityIcon;
