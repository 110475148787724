import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Auth0Provider} from "@auth0/auth0-react";
import history from './utils/history'
import { DashboardThemeProvider } from './styles/themes/dashboardThemeProvider';
import "@fontsource/dm-sans";
import ModalProvider from './components/provider/modalProvider';

const onRedirectCallback = (appState) => {
    history.push(
        appState && appState.returnTo ? appState.returnTo : window.location.pathname
    );
};

const providerConfig = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    audience: process.env.REACT_APP_API_HOST,
    redirectUri: window.location.origin,
    onRedirectCallback,
};

ReactDOM.render(
  <React.StrictMode>
      <Auth0Provider {...providerConfig}>
        <DashboardThemeProvider>
            <ModalProvider>
                <App />
            </ModalProvider>
        </DashboardThemeProvider>
      </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
