import { gql } from "@apollo/client";

export const depositMutation = gql`
mutation Deposit($input: YeildInput!) {
  deposit: deposit_Admin(input: $input) {
      amount,
      currency,
      status,
      fee,
      type
    }
  }
`

export const withdrawalMutation = gql`
mutation Withdrawal($input: YeildInput!) {
  withdrawal: withdrawal_Admin(input: $input) {
      amount
      currency
      status
      fee
      type
    }
  }
`