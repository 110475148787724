import { gql } from "@apollo/client";

export const getOrderQuery = gql`
    query Order_Admin($orderId: String!) {
        order_Admin(orderId: $orderId) {
            id
            orderType
            status
            sourceId
            sourceType
            amount
            currency
            destinationId
            destinationType
            createdOn
            modifiedOn
            description
            reference
            accountId
            accountType
            paymentReference
            paymentAdditionalData {
                paymentType
                sourceBankBsb
                sourceBankAccountNumber
                sourceAccountName
                destinationChannelCode
                destinationChannelName
                destinationPaymentMethodCountry
                destinationBankBsb
                destinationBankAccountNumber
                destinationBankAccountName
                payId
                fromWalletId
                sourceWalletAddress
                toWalletId
                destinationWalletAddress
                destinationWalletAsset
                destinationWalletBlockchain
                blockchainNetwork
                blockchainScanLink
                gasFee
                gasFeeLevel
                sendDescription
                destinationAccountId
                recipientName
                recipientId
                cardId
                maskedCardNumber
                cardExpiry
                cardHolderName
                cardLastFourDigits
                cardAcceptorName
                cardPaymentExchangeRate
                cardPaymentExchangeCurrency
                cardPaymentCancelledOriginalAmount
                cardPaymentCapturedOriginalAmount
                cardPaymentRefundOriginalAmount
                cardPaymentAuthOriginalAmount
                cardPaymentAuthCardFeeAmount
                cardPaymentCancelledCardFeeAmount
                cardPaymentCapturedCardFeeAmount
                cardPaymentCapturedUsdAmount
                cardPaymentCancelledUsdAmount
                terminalType
                terminalId
                cardPresent
                cardHolderPresent
                digitalTokenType
                digitalToken
                deviceId
                rampAmount
                rampCurrency
                exchangeRate
                totalFee
                tiiikFee
                bankCardLast4
                bankCardScheme
            }
        }
    }
`

export const findOrderByReferenceQuery = gql`
    query FindOrderByReference_Admin($reference: String!) {
        findOrderByReference_Admin(reference: $reference) {
            id
            orderType
            status
            sourceId
            sourceType
            amount
            currency
            destinationId
            destinationType
            createdOn
            modifiedOn
            description
            reference
            accountId
            accountType
            paymentReference
            paymentAdditionalData {
                paymentType
                sourceBankBsb
                sourceBankAccountNumber
                sourceAccountName
                destinationChannelCode
                destinationChannelName
                destinationPaymentMethodCountry
                destinationBankBsb
                destinationBankAccountNumber
                destinationBankAccountName
                payId
                fromWalletId
                sourceWalletAddress
                toWalletId
                destinationWalletAddress
                destinationWalletAsset
                destinationWalletBlockchain
                blockchainNetwork
                blockchainScanLink
                gasFee
                gasFeeLevel
                sendDescription
                destinationAccountId
                recipientName
                recipientId
                cardId
                maskedCardNumber
                cardExpiry
                cardHolderName
                cardLastFourDigits
                cardAcceptorName
                cardPaymentExchangeRate
                cardPaymentExchangeCurrency
                cardPaymentCancelledOriginalAmount
                cardPaymentCapturedOriginalAmount
                cardPaymentRefundOriginalAmount
                cardPaymentAuthOriginalAmount
                cardPaymentAuthCardFeeAmount
                cardPaymentCancelledCardFeeAmount
                cardPaymentCapturedCardFeeAmount
                cardPaymentCapturedUsdAmount
                cardPaymentCancelledUsdAmount
                terminalType
                terminalId
                cardPresent
                cardHolderPresent
                digitalTokenType
                digitalToken
                deviceId
                rampAmount
                rampCurrency
                exchangeRate
                totalFee
                tiiikFee
                bankCardLast4
                bankCardScheme
            }
        }
    }
`
