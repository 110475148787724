import React from "react";

function TransactionsIcon({isSelected}) {
  return (
      <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 -2 24 24"
      >
        <path
            fill={isSelected ? 'rgba(163, 162, 255, 1)' : "#EDEDEE"}
            fillRule="evenodd"
            d="M13.22.003c3.158 0 5.28 2.163 5.28 5.381v9.238c0 3.246-2.056 5.358-5.239 5.378h-7.48C2.623 20 .5 17.837.5 14.619V5.38C.5 2.134 2.556.023 5.739.004l7.48-.001h.001zm0 1.507H5.744c-2.339.015-3.737 1.461-3.737 3.871v9.238c0 2.426 1.411 3.874 3.773 3.874h7.476c2.339-.014 3.737-1.462 3.737-3.871V5.384c0-2.426-1.41-3.874-3.773-3.874zm-.194 12.027a.754.754 0 010 1.507l-7.005-.003a.754.754 0 010-1.506l7.006.002zm0-4.205a.754.754 0 010 1.507l-7.005-.003a.754.754 0 010-1.507l7.006.003zM8.788 5.134a.754.754 0 010 1.506H6.021a.754.754 0 010-1.506h2.767z"
            clipRule="evenodd"
        ></path>
      </svg>
  );
}

export default TransactionsIcon;
