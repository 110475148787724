import React from "react";

function ActivityIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <rect width="40" height="40" fill="#36363B" rx="20"></rect>
      <path
        fill="#EDEDEE"
        fillRule="evenodd"
        d="M21.72 9.437a.75.75 0 01.405.787 213.226 213.226 0 01-.83 4.84l-.314 1.7a28.15 28.15 0 001.753.03c.761-.002 1.56-.005 2.582.084a.75.75 0 01.558 1.164c-.246.368-.5.757-.761 1.154-.694 1.058-1.429 2.178-2.156 3.159-1.976 2.663-3.963 5.132-6.021 7.69l-.316.393a.75.75 0 01-1.29-.726c.348-.958.7-1.912 1.05-2.866.576-1.566 1.15-3.128 1.708-4.696a62.098 62.098 0 01-3.297-.118.75.75 0 01-.654-1.026c1.17-2.942 3.624-8.255 6.71-11.422a.75.75 0 01.873-.147zM15.93 20.59c1.074.05 2.148.077 3.201.049a.75.75 0 01.729.997c-.5 1.43-1.019 2.858-1.54 4.282a173.382 173.382 0 003.432-4.457c.7-.943 1.397-2.006 2.082-3.05l.071-.11c-.407-.01-.79-.008-1.178-.007h-.057c-.787.003-1.602.004-2.654-.1l.003-.03a1.455 1.455 0 01-.672-.822l.003-.018.001-.009.001-.006.003-.011.033-.178a428.49 428.49 0 00.43-2.325c.116-.635.243-1.343.369-2.066-1.799 2.5-3.294 5.58-4.258 7.86z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default ActivityIcon;
