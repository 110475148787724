import { Card, CardContent, makeStyles, Typography } from "@material-ui/core";
import DrawerModal from "./drawerModal";
import ModalConsumer from "../consumer/modalConsumer";
import NoteRowItem from "../row/notesRowItem";
import EditNoteDialog from "../dialog/editNoteDialog";

const useStyles = makeStyles((theme) => ({
    heading: {
        marginBottom: '28px'
    },
    cardContent: {
        '& > div': {
            '&:not(:last-child)': {
                marginBottom: '24px',
                '& > div': {
                    paddingBottom: '28px',
                },
                '&::after': {
                    content: '""',
                    display: 'inline-block',
                    cursor: 'pointer',
                    width: '87%',
                    height: '100%',
                    borderBottom: '1px solid #D6D6DF',
                    opacity: 0.23,
                    marginLeft: '64px'
                }
            },
        }
    }
}));

const ViewNotesDrawer = ({ data, onClose, onComplete }) => {
    const classes = useStyles();
    const { customerId, email, notes } = data
    return (
        <DrawerModal onClose={onClose}>
            <Typography variant='h2' className={classes.heading}>
                All notes
            </Typography>
            <Card className={classes.notesCard}>
                <CardContent className={classes.cardContent}>
                    {
                        notes.map((note) => (
                            <ModalConsumer>
                                {({ showModal }) => (
                                    <NoteRowItem
                                        note={note}
                                        inDrawer={true}
                                        onClick={() => showModal(EditNoteDialog, { data: { customerId, actorEmail: email, noteData: note }, onComplete: onComplete})}
                                    />
                                )}
                            </ModalConsumer>
                        ))
                    }
                </CardContent>
            </Card>
        </DrawerModal>
    )
}

export default ViewNotesDrawer;