/* eslint-disable no-useless-computed-key */
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Link } from "@material-ui/core";
import NoteIcon from "../icons/noteIcon";
import { formatTimestamp } from "../../utils/formatter";
import { CUSTOMER_NOTE_TYPES } from "../../utils/types";
import ActivityIcon from "../icons/activityIcon";

const useNotesRowItemStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        '& > svg': {
            minWidth: '40px'
        },
        borderRadius: '8px',
        padding: '8px',
        '&:hover': {
            backgroundColor: 'rgb(0 0 0 / 15%)',
            transition: '0.3s',
            cursor: 'pointer'
        }
    },
    detailRow: {
        marginLeft: '16px'
    },
    noteHeading: {
        marginBottom: '4px',
    },
    noteLabelTruncated: {
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        marginBottom: '4px',
    },
    noteLabel: {
        marginBottom: '4px',
        fontFamily: 'DM Sans',
        fontStyle: 'italic',
        fontWeight: '400',
        fontSize: '15px',
        lineHeight: '20px',
        color: '#C6C6C8'
    },
    activityNoteLabel: {
        fontSize: '14px',
        lineHeight: '18px',
        fontWeight: 700
    },
    additionalContextLabel: {
        marginTop: '4px'
    },
    metaLabel: {
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 1,
        marginBottom: '4px',
    }
}));

const NoteRowItem = ({ note, onClick, inDrawer }) => {
    const classes = useNotesRowItemStyles();
    const createDate = new Date(note.createDate).getTime()
    const lastUpdateDate = new Date(note.lastUpdateDate).getTime()
    const isEditedPostfix = lastUpdateDate > createDate ? ` • Edited ${formatTimestamp(note.lastUpdateDate)}` : ""
    const isActivity = note.noteType == CUSTOMER_NOTE_TYPES.ACTIVITY
    return (
        <div
            className={classes.root}
            onClick={onClick}
        >
            <div className={classes.content}>
                { isActivity ? <ActivityIcon/> : <NoteIcon/>}
                <div className={classes.detailRow}>
                    <Typography variant="subtitle2" className={classes.noteHeading}>
                        { isActivity ? "Activity" : "Note"}
                    </Typography>
                    { inDrawer && isActivity
                        ? <div>
                            <Typography variant="body1" className={classes.activityNoteLabel}>{note.note}</Typography>
                            {note.additionalContext && <Typography variant="body1" className={`${classes.noteLabel} ${classes.additionalContextLabel}`}>{note.additionalContext}</Typography>}
                        </div>
                        : <Typography variant="body1" className={inDrawer ? classes.noteLabel : classes.noteLabelTruncated }>{note.note}</Typography>
                    }
                    <Typography variant="subtitle2" className={classes.metaLabel}>{
                        `${note.actor } • ${formatTimestamp(note.createDate)}${isEditedPostfix}`
                    }</Typography>
                </div>
            </div>
        </div>
    )
}

export default NoteRowItem;