import React from "react";

function RefundActivityIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <rect width="40" height="40" fill="#141417" rx="20"></rect>
      <path
        fill="#EDEDEE"
        fillRule="evenodd"
        d="M12.941 10.476a.7.7 0 100 1.4h6.47c2.25 0 4.459.565 6.083 1.648 1.603 1.069 2.63 2.63 2.63 4.711 0 2.479-.819 4.03-1.955 4.977-1.158.966-2.744 1.382-4.404 1.382h-7.988c.267-.185.54-.378.806-.582a36.39 36.39 0 002.583-2.174.7.7 0 00-.95-1.029 34.99 34.99 0 01-2.483 2.09c-.315.241-.643.469-.988.706l-.013.01c-.336.232-.687.474-1.028.734l-.064.041-.02.012c-.06.04-.141.093-.224.153a2.08 2.08 0 00-.27.229 1.105 1.105 0 00-.153.197.824.824 0 00-.12.423c0 .36.237.575.303.632.098.085.204.147.276.185a3.129 3.129 0 00.271.127c.337.18.665.352.992.523.444.233.884.464 1.33.705l.27.147c.905.488 1.792.968 2.656 1.488.075.045.155.09.226.13l.035.02c.084.047.156.088.223.13.14.089.208.151.24.194a.7.7 0 001.12-.84 2.254 2.254 0 00-.614-.538 6.57 6.57 0 00-.281-.166l-.04-.022c-.072-.04-.131-.074-.187-.108-.893-.537-1.81-1.032-2.709-1.518l-.272-.148a76.447 76.447 0 00-.658-.35h7.73c1.87 0 3.813-.467 5.302-1.707 1.51-1.26 2.457-3.237 2.457-6.053 0-2.624-1.326-4.592-3.253-5.876-1.905-1.27-4.402-1.883-6.858-1.883h-6.47zm-1.238 15.872l-.004-.002-.002-.001a.422.422 0 01.006.003z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default RefundActivityIcon;
