import { Drawer, makeStyles } from "@material-ui/core"
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import ActionButton from "../button/actionButton";

const useStyles =  makeStyles((theme) => ({
    drawer: {
        boxShadow: '-1px 0px 0px #000030'
    },
    root: {
        width: '549px',
        height: '100%',
        backgroundColor: theme.background.drawer,
    },
    container: {
        margin: '90px 32px 32px',
        height: '90%'
    },
    closeButton: {
        position: 'absolute',
        top: '32px',
        left: '19px'
    }
}))

const DrawerModal = forwardRef(({children, onClose, ...props}, ref) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [closeRequested, setCloseRequested] = useState(false);
    useImperativeHandle(ref, () => ({
        close: () => {
            setOpen(false);
            setTimeout(() => onClose?.(), 100);
        }
      }));
    useEffect(() => {
        setOpen(true) // renders after mount to allow for animation, for use with ModalConsumer -> showModal()
    }, []);
    useEffect(() => {
        if (closeRequested) {
            setOpen(false);
            setTimeout(() => onClose?.(), 100);
        }
    }, [closeRequested]);
    return (
        <Drawer ref={ref} anchor='right' open={open} className={classes.drawer} onClose={() => setCloseRequested(true)} {...props}>
            <div role='presentation' className={classes.root}>
                <ActionButton className={classes.closeButton} variant="secondary" size="md"
                    onClick={() => setCloseRequested(true)}>
                    Close
                </ActionButton>
                <div className={classes.container}>
                    {children}
                </div>
            </div>
        </Drawer>
    )
});

export default DrawerModal;